import moment from "moment";

export const faturaEmDia = (movimentacao) => {
  //amarelo..
  //   return (parseInt(movimentacao.notaFiscalOuRDD) === 0 && movimentacao.provisao === "N");
  return (
    (null === movimentacao.notaFiscalOuRDD ||
      movimentacao.notaFiscalOuRDD === 0 ||
      movimentacao.emissao === null) &&
    (null === movimentacao.provisao || movimentacao.provisao === "N") &&
    movimentacao.valor > 0
  );
};

export const receberVencimentoEmDia = (movimentacao) => {
  return (
    movimentacao.baixa === null &&
    movimentacao.notaFiscalOuRDD !== null &&
    movimentacao.notaFiscalOuRDD !== 0 &&
    movimentacao.emissao !== null
  );
};

export const atrasado = (movimentacao) => {
  const dataatual = new Date();
  dataatual.setHours(0, 0, 0, 0);
  return (
    movimentacao.baixa == null &&
    moment(movimentacao.prevista, "DD/MM/YYYY").toDate() < dataatual
  );
};
export const recebido = (movimentacao) => {
  //azul
  return (
    parseInt(movimentacao.notaFiscalOuRDD) !== 0 &&
    movimentacao.valor > 0 &&
    movimentacao.baixa != null
  );
};

export const numeroDeDias = (movimentacao) => {
  //cor na cedula da data...
  // let data = movimentacao.modificada;
  const diasData = new Date();
  diasData.setDate(diasData.getDate() + movimentacao.diasMedios);
  diasData.setHours(0, 0, 0, 0);
  return (
    moment(movimentacao.modificada, "DD/MM/YYYY").toDate() < diasData &&
    movimentacao.notaFiscalOuRDD === 0 &&
    movimentacao.valor > 0
  );
};

export const emDiaProvisionada = (movimentacao) => {
  //padrão/cinza
  return movimentacao.baixa == null;
};

export const nBaixadoAtrasado = (movimentacao) => {
  //fonte vermelha e linha padrão.
  const dataatual = new Date();
  dataatual.setHours(0, 0, 0, 0);
  return (
    moment(movimentacao.prevista, "DD/MM/YYYY").toDate() > dataatual &&
    parseInt(movimentacao.baixa) === 0
  );
};

export const dataVencimento = (movimentacao) => {
  //borda da linha vermelha.
  // let data = movimentacao.modificada;
  const dataatual = new Date();
  dataatual.setHours(0, 0, 0, 0);
  return (
    moment(movimentacao.modificada, "DD/MM/YYYY").toDate() < dataatual &&
    movimentacao.baixa == null &&
    movimentacao.valor < 0
  );
};

export const atrasadoNaoPago = (movimentacao) => {
  //celula da baixa vermelha
  const data = movimentacao.baixa;
  return data != null && data > movimentacao.prevista;
};

export const pagoSemAtraso = (movimentacao) => {
  //celula da baixa verde
  const data = movimentacao.baixa;
  return data != null && data < movimentacao.prevista;
};

export const provisao = (movimentacao) => {
  return (
    (movimentacao.notaFiscalOuRDD === 0 || movimentacao.notaFiscalOuRDD) ===
      null &&
    movimentacao.provisao === "S" &&
    movimentacao.baixa === null
  );
};
//parseInt() analisa um argumento string e retorna um inteiro na base especificada

export const despesaBaixada = (movimentacao) => {
  //fundo branco
  return (
    parseInt(movimentacao.notaFiscalOuRDD) !== 0 && movimentacao.baixa !== null
  );
};

export const creditoBaixaAtrasado = (movimentacao) => {
  //letra da data de baixa vermalha.
  if (movimentacao.baixa) {
    return moment(movimentacao.baixa, "DD/mm/YYYY").isAfter(
      movimentacao.prevista
    );
  }
  return false;
};

export const vencimentoDiferenteDeModificada = (
  movimentacaoListaLancamento
) => {
  //celula da baixa verde
  const vencimentoOriginal = movimentacaoListaLancamento.vencimentoOriginal;
  const vencimentoPlanejado = movimentacaoListaLancamento.vencimentoPlanejado;
  return vencimentoOriginal !== vencimentoPlanejado;
};

export const movConciliado = (conciliacoes) => {
  const baixa = conciliacoes.baixa;
  const valorBaixa = conciliacoes.valorBaixa;
  return baixa === null && valorBaixa === null;
};
