const INITIAL_STATE = {
    id: null,
    dataInicio: null,
    dataFim: null,
    mensagem: null,
    status: null
}

export const filtroEmailsEnviados = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_EMAILS_ENVIADOS":
            return { ...action.payload };

        default:
            return state;
    }
};
