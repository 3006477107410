import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addListaParametroSistema, exibirLoading } from "../../actions";
import { getAllParametrosSistema } from "../../api";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import { paginationDefault } from "../../default/pagination";
import { Navbar } from "../../components/Navbar";

export const ParametrosSistema = (props) => {
  const page = props?.location?.state?.page
  const toast = useRef(null);
  const dispatch = useDispatch();

  const listaParametroSistema = useSelector(
    (state) => state.movimentacaoListaLancamento.listaParametroSistema
  );

  let history = useHistory();
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);
  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }
  }, [props, page])
  function functionEditarLinha(rowData) {
    history.push(`/parametro-sistema/${rowData.id}`, { page: lazyParams, parametroSistema: rowData });
  }

  const atualizaListaParametroSistema = useCallback(
    (listaParametroSistema) => {
      dispatch(addListaParametroSistema(listaParametroSistema));
    },
    [dispatch]
  );

  const getListaParametrosSistema = useCallback(() => {
    dispatch(exibirLoading());
    const requestParams = { paginationParams: lazyParams };
    getAllParametrosSistema(requestParams).then((resultado) => {
      atualizaListaParametroSistema(resultado?.content);
      setTotalRecords(resultado.totalElements);
    });
  },[atualizaListaParametroSistema, setTotalRecords, dispatch, lazyParams])

  useEffect(() => {
    getListaParametrosSistema();
  }, [lazyParams, getListaParametrosSistema]);

  const columns = [
    {
      field: "dominio",
      header: "Domínio",
      style: { minWidth: 100, maxWidth: "20vw" },
      type: "customEllipsisLeftWithTooltip"
    },
    {
      field: "descricao",
      header: "Descrição",
      style: { minWidth: 120, maxWidth: "30vw" },
      type: "customEllipsisLeftWithTooltip"
    },
    {
      field: "valor",
      header: "Valor",
      style: { minWidth: 140, maxWidth: "20vw" },
    },
    {
      field: "tipo",
      header: "Tipo",
      style: { minWidth: 70, maxWidth: "10vw" },
    },
    {
      field: "sistema",
      header: "Sistema",
      style: { minWidth: 70, maxWidth: "10vw" },
    },
  ];

  return (
    <>
      <Navbar />
      <Toast ref={toast} />
      <ReactHintComponents />
      <Table
        arrayData={listaParametroSistema}
        loading={recarregando}
        headerTitle="Parâmetros do Sistema"
        columns={columns}
        inputFiltrar={true}
        onClickEdit={(rowData) => functionEditarLinha(rowData)}
        tooltipEdit="Parâmetro do Sistema"
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        totalRecords={totalRecords}
      />
    </>
  );
};
