const INITIAL_STATE = {
  descricao: null,
  agencia: null,
  contaCorrente: null,
  nomeBanco: null,
  ativa: null,
  idBanco: null,
  idEmpresa: null,
  idParceiro: null,
  nomeFantasiaEmpresa: null,
  nomeTratamentoParceiro: null,
};

//verificar se a parte de baixo esta faltando algum objeto.
export const filtroCC = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_CONTA_CORRENTE":
      return { ...action.payload };
    case "SET_NUMERO_BANCO":
      return { ...state, numeroBanco: action.payload };
    case "SET_EMPRESA":
      return { ...state, numeroBanco: action.payload };
    case "SET_PARCEIRO":
      return { ...state, numeroBanco: action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_QTD_REGISTRO":
      return { ...state, qtdRegistros: action.payload };

    default:
      return state;
  }
};
