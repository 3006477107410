import _ from 'lodash';
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { addMovEmpresa, esconderLoading, exibirLoading, setFiltroEmpresa } from "../../actions";
import { deleteEmpresa, getAllFilterEmpresas } from "../../api";
import { FiltroEmpresa } from "../../components/FiltroEmpresa";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from '../../context/ReduxContext';
import { paginationDefault } from '../../default/pagination';
import { formatCnpj } from "../../utils/cpfCnpj";
import { Navbar } from '../../components/Navbar';

export const Empresa = (props) => {
  const page = props?.location?.state?.page
  const dispatch = useDispatch();
  const toast = useRef(null);
  const reduxStateAtual = useSelector(state => state.filtroEmpresa);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [clearInputs, setClearInputs] = useState(false);
  const [visible, setVisible] = useState('');

  async function clearFilter() {
    const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial.filtroEmpresa)
    setVisible(!filterActive)
    if (filterActive === false) {
      setClear(true)
      dispatch(setFiltroEmpresa(reduxStateInicial.filtroEmpresa));
      // setVisible(false)
    } else {
      setClear(false)
    }
  }

  const ListaEmpresa = useSelector((state) => state.grupoEconomico.empresa);
  const id = useSelector((state) => state.filtroEmpresa.id);
  const nomeFantasia = useSelector((state) => state.filtroEmpresa.nomeFantasia);
  const grupoEconomicoNomeFantasia = useSelector(
    (state) => state.filtroEmpresa.grupoEconomicoNomeFantasia
  );
  const razaoSocial = useSelector((state) => state.filtroEmpresa.razaoSocial);
  const cnpj = useSelector((state) => state.filtroEmpresa.cnpj);
  const inscEstadual = useSelector((state) => state.filtroEmpresa.inscEstadual);
  const inscMunicipal = useSelector(
    (state) => state.filtroEmpresa.inscMunicipal
  );
  const emailNotaFiscal = useSelector(
    (state) => state.filtroEmpresa.emailNotaFiscal
  );
  const status = useSelector((state) => state.filtroEmpresa.status);
  const [abrirFiltroEmpresa, setAbrirFiltroEmpresa] = useState(false);

  const history = useHistory();
  const [dialogExcluirEmpresa, setDialogExcluirEmpresa] = useState(false);
  const [excluirEmpresa, setExcluirEmpresa] = useState(false);
  const [descricaoModalExcluir, setDescricaoModalExcluir] = useState("");
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );

  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);

  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }
  }, [props, page])

  const montarFiltro = useCallback(() => {
    const filtroEmpresa = {
      id,
      nomeFantasia,
      razaoSocial,
      cnpj: cnpj ? cnpj
        ?.replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "")
        .replaceAll("_", "") : null,
      inscEstadual,
      inscMunicipal,
      emailNotaFiscal,
      status,
      grupoEconomicoNomeFantasia,
    };

    return filtroEmpresa;
  }, [
    id,
    nomeFantasia,
    razaoSocial,
    cnpj,
    inscEstadual,
    inscMunicipal,
    emailNotaFiscal,
    status,
    grupoEconomicoNomeFantasia,
  ]);

  const carregarEmpresa = useCallback(() => {
    dispatch(exibirLoading());

    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };

    getAllFilterEmpresas(requestParams).then((resultado) => {
      if (resultado.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "O filtro não obteve resultado",
        });
      }
      dispatch(addMovEmpresa(resultado.content));
      setTotalRecords(resultado.totalElements);
      dispatch(esconderLoading());
    });
    setAbrirFiltroEmpresa(false);
  },[dispatch, lazyParams, montarFiltro])

  useEffect(() => {
    const buttonClearFilter = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroEmpresa)
    setVisible(!buttonClearFilter)
    carregarEmpresa();
  }, [lazyParams, montarFiltro, carregarEmpresa, reduxStateAtual, reduxStateInicial]);

  function functionExcluirEmpresa(rowData) {
    setDialogExcluirEmpresa(true);
    setExcluirEmpresa(rowData.id);
    setDescricaoModalExcluir(rowData.nomeFantasia);
  }

  function abrirLinhaComId(rowData) {
    history.push(`/empresa/${rowData.id}`, { page: lazyParams, empresa: rowData });
  }

  const deletarEmpresa = async (rowData) => {
    setCarregandoRequisicao(true);
    try {
      await deleteEmpresa(rowData).then(() => {
        dispatch(setFiltroEmpresa(reduxStateInicial.filtroEmpresa))
        setClearInputs(true);
      });
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: 'Empresa excluída com sucesso!',
        life: 5000,
      });
      setDialogExcluirEmpresa(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Info",
        life: 5000,
        detail: error?.response?.data?.userMessage,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  };

  function statusIcon(rowData) {
    if (rowData.status === "Ativo") {
      return (
        <Button
          className="formattedStyles formattedStyles--check"
          icon="pi pi-check-circle"
          tooltip="Status Ativo"
          data-testid="statusAtivo"
        />
      );
    } else if (rowData.status === "Inativo") {
      return (
        <Button
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times-circle"
          tooltip="Status Inativo"
          data-testid="statusInativo"
        />
      );
    }
  }

  const columns = [
    { field: "id", header: "Código", style: { minWidth: 50, maxWidth: "5vw" } },
    {
      field: "nomeFantasia",
      header: "Nome Fantasia",
      style: { minWidth: 160, maxWidth: "20vw" },
      type: "customEllipsis",
    },
    {
      field: "razaoSocial",
      header: "Razão Social",
      style: { minWidth: 150, maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "grupoEconomico.nomeFantasia",
      header: "Grupo Econômico",
      style: { minWidth: 105, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "cnpj",
      header: "CNPJ",
      style: { minWidth: 105, maxWidth: "10vw" },
      type: "custom",
      customFunc: (e) => formatCnpj(e.cnpj),
    },
    {
      field: "inscEstadual",
      header: "Inscrição Estadual",
      style: { minWidth: 105, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "inscMunicipal",
      header: "Inscrição Municipal",
      style: { minWidth: 105, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "emailNotaFiscal",
      header: "E-mail",
      style: { minWidth: 105, maxWidth: "10vw" },
      type: "customEllipsis",
    },
    {
      field: "status",
      header: "Status",
      style: { minWidth: 90, maxWidth: "8vw" },
      type: "custom",
      customFunc: statusIcon,
    },
  ];

  const modalExcluir = [
    { label: "Código:", value: excluirEmpresa },
    { label: "Nome Fantasia:", value: descricaoModalExcluir },
  ];

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <ModalComponents
          title="Empresa"
          visible={dialogExcluirEmpresa}
          descricao={modalExcluir}
          onHide={() => setDialogExcluirEmpresa(false)}
          onClick="delete"
          datatesteid="buttonCancelar"
          datatesteidconfirm="buttonConfirmar"
          onClickCancelar={() => setDialogExcluirEmpresa(false)}
          onClickConfirmar={() => deletarEmpresa(excluirEmpresa)}
        />
        <Table
          arrayData={ListaEmpresa}
          loading={recarregando}
          columns={columns}
          headerTitle="Empresas"
          onClickAdicionar={() => history.push("/empresa/cadastro", { page: lazyParams })}
          onClickFiltrar={() => setAbrirFiltroEmpresa(true)}
          onClickEdit={(rowData) => abrirLinhaComId(rowData)}
          tooltipEdit="Empresa"
          onClickDelete={(rowData) => functionExcluirEmpresa(rowData)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
      </LoadingOverlayComponents>
      <FiltroEmpresa
        clear={clear}
        clearInputs={clearInputs}
        setClearInputs={setClearInputs}
        setVisible={setVisible}
        setAbrirFiltroEmpresa={setAbrirFiltroEmpresa}
        abrirFiltroEmpresa={abrirFiltroEmpresa}
        setLazyParamsProp={setLazyParams}
        reduxStateAtual={reduxStateAtual}
        reduxStateInicial={reduxStateInicial?.filtroEmpresa}
      />
    </>
  );
};
