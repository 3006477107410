
const INITIAL_STATE = {
  baixaInicio: null,
  baixaFim: null,
  baixado: null,
  cadastroFim: null,
  cadastroInicio: null,
  competenciaFim: null,
  competenciaInicio: null,
  conciliacaoFim: null,
  conciliacaoInicio: null,
  contaCorrente: {
    id: null
  },
  creditoDebitoConciliado: null,
  emissaoFim: null,
  emissaoInicio: null,
  empresa: null,
  faturamentoPrevistoFim: null,
  faturamentoPrevistoInicio: null,
  historico: null,
  idMovimentacao: null,
  natureza: null,
  numeroUnicoLancamento: null,
  parceiro: null,
  rateado: null,
  tipoLancamento: null,
  ultimaAlteracaoFim: null,
  ultimaAlteracaoInicio: null,
  vencidoSemBaixa: null,
  vencimentoModificadoFim: null,
  vencimentoOriginalInicio: null,
  vencimentoOriginalFim: null,
  vencimentoModificadoInicio: null,
};

//verificar se a parte de baixo esta faltando algum objeto.
export const filtroMov = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO-MOV":
      return { ...action.payload };
    case "SET_PARCEIRO_LISTA":
      return { ...state, parceiro: action.payload };
    case "SET_EMPRESA":
      return { ...state, empresa: action.payload };
    case "SET_CREDITO_DEBITO_CONCILIADO":
      return { ...state, creditoDebitoConciliado: action.payload };
    case "SET_BAIXADO":
      return { ...state, baixado: action.payload };
    case "SET_RATEADO":
      return { ...state, rateado: action.payload };
    case "SET_NATUREZA_LISTA":
      return { ...state, natureza: action.payload };
    case "SET_NATUREZA_ANALITICA":
      return { ...state, naturezaAnalitica: action.payload };
    case "SET_CONTA_CORRENTE":
      return { ...state, contaCorrente: action.payload };
    case "SET_HISTORICO":
      return { ...state, historico: action.payload };
    case "SET_VENCIMENTO_ORIGINAL_INICIO":
      return { ...state, vencimentoOriginalInicio: action.payload };
    case "SET_VENCIMENTO_ORIGINAL_FIM":
      return { ...state, vencimentoOriginalFim: action.payload };
    case "SET_VENCIMENTO_MODIFICADO_INICIO":
      return { ...state, vencimentoModificadoInicio: action.payload };
    case "SET_VENCIMENTO_MODIFICADO_FIM":
      return { ...state, vencimentoModificadoFim: action.payload };
    case "SET_BAIXA_INICIO":
      return { ...state, baixaInicio: action.payload };
    case "SET_BAIXA_FIM":
      return { ...state, baixaFim: action.payload };
    case "SET_EMISSAO_INICIO":
      return { ...state, emissaoInicio: action.payload };
    case "SET_EMISSAO_FIM":
      return { ...state, emissaoFim: action.payload };
    case "SET_CADASTRO_INICIO":
      return { ...state, cadastroInicio: action.payload };
    case "SET_CADASTRO_FIM":
      return { ...state, cadastroFim: action.payload };
    case "SET_ULTIMA_ALTERACAO_INICIO":
      return { ...state, ultimaAlteracaoInicio: action.payload };
    case "SET_ULTIMA_ALTERACAO_FIM":
      return { ...state, ultimaAlteracaoFim: action.payload };
    case "SET_CONCILIACAO_INICIO":
      return { ...state, conciliacaoInicio: action.payload };
    case "SET_CONCOLIACAO_FIM":
      return { ...state, conciliacaoFim: action.payload };
    case "SET_FATURAMENTO_PREVISTO_INICIO":
      return { ...state, faturamentoPrevistoInicio: action.payload };
    case "SET_FATURAMENTO_PREVISTO_FIM":
      return { ...state, faturamentoPrevistoFim: action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_QTD_REGISTRO":
      return { ...state, qtdRegistros: action.payload };
    case "FILTRO_VENCIDO_SEM_BAIXA":
      return { ...state, vencidoSemBaixa: action.payload };
    case "SET_COMPETENCIA_INICIO":
      return { ...state, competenciaInicio: action.payload };
    case "SET_COMPETENCIA_FIM":
      return { ...state, competenciaFim: action.payload };
    case "SET_ID_MOV":
      return { ...state, idMov: action.payload };
    case "SET_TIPO_LANCAMENTO":
      return { ...state, tipoLancamento: action.payload };
    case "SET_NUMERO_UNICO_LANCAMENTO":
      return { ...state, numeroUnicoLancamento: action.payload };
    default:
      return state;
  }
};
