import { yupResolver } from "@hookform/resolvers/yup";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { setFiltroParceiro } from "../../actions";
import {
  getAllTiposParceiro,
  getEmpresaResumo,
  putParceiro,
  saveParceiro,
  getAllUf,
  listaMunicipioPorUf
} from "../../api";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired, messageRequiredMinimumCharacters } from "../../default/messages";
import { ButtonComponent } from "../ButtonComponent";
import { FormInputs } from "../FormInputs";
import { Header } from "../Header";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import "./styles.css";

export const EditarParceiro = ({ editParceiro, page }) => {
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  const toast = useRef(null);
  const [tipo, setTipo] = useState([]);
  const [nomeTratamento, setNomeTratamento] = useState(null);
  const [nomeCompleto, setNomeCompleto] = useState(null);
  const [historico, setHistorico] = useState(null);
  const [dataNasc, setDataNasc] = useState(null);
  const [cpfCnpj, setCpfCnpj] = useState(null);
  const [rgInscEstadual, setRgInscEstadual] = useState(null);
  const [ufInscricao, setUfInscricao] = useState(null);
  const [insMunicipal, setInsMunicipal] = useState(null);
  const [ativo, setAtivo] = useState(null);
  const [tipoPessoa, setTipoPessoa] = useState();
  const [sexo, setSexo] = useState(null);
  const [dataPrimeiroFaturamento, setDataPrimeiroFaturamento] = useState(null);
  const [ufPrincipal, setUfPrincipal] = useState(null);
  const [nomeCidadePrincipal, setNomeCidadePrincipal] = useState(null);
  const [empresa, setEmpresa] = useState(null);
  const [diasMedios, setDiasMedios] = useState(null);
  const [allEmpresas, setAllEmpresas] = useState([]);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const [listaTipo, setListaTipo] = useState([]);

  console.log("insMunicipal", insMunicipal)
  async function getAllTypesParceiro() {
    setCarregandoRequisicao(true)
    await getAllTiposParceiro().then((resultado) => {
      setListaTipo(resultado)
      setCarregandoRequisicao(false)
    })
  }

  useEffect(() => {
    getAllTypesParceiro()
  }, [])

  const validationPost = yup.object({
    tipo: yup.array()
      .required(messageRequired)
      .test('hasValues', messageRequired, (value) => {
        if (value.length === 0) {
          return false;
        }

        // Verifica se o array contém pelo menos um número
        return true;
      }),
    nomeTratamento: yup
      .string()
      .trim()
      .min(3, messageRequiredMinimumCharacters("O Nome de Tratamento", 2))
      .required(messageRequired),
    nomeCompleto: yup.string().required(messageRequired).trim(),
    ativo: yup.string().required(messageRequired),
    tipoPessoa: yup.string().required(messageRequired),
    sexo: yup
      .string()
      .when("tipoPessoa", {
        is: "F",
        then: (schema) => schema.required(messageRequired),
      })
      .nullable(""),
    cpfCnpj: yup.string().required(messageRequired),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  useEffect(() => {
    console.log("editar", editParceiro)
    if (
      editParceiro !== undefined &&
      editParceiro !== null &&
      editParceiro !== ""
    ) {
      setTipo(editParceiro?.parceiroTipos.map(parc => parc.id));
      setNomeTratamento(editParceiro?.nomeTratamento);
      setNomeCompleto(editParceiro?.nomeCompleto);
      setHistorico(editParceiro?.historico);
      editParceiro?.dataNasc && setDataNasc(new Date(editParceiro?.dataNasc));
      setCpfCnpj(editParceiro?.cpfCnpj);
      setRgInscEstadual(editParceiro?.rgInscEstadual);
      setUfInscricao(editParceiro?.ufInscricao);
      setInsMunicipal(editParceiro?.insMunicipal);
      setAtivo(editParceiro?.ativo);
      setTipoPessoa(editParceiro?.tipoPessoa);
      setSexo(editParceiro?.sexo);
      editParceiro?.dataPrimeiroFaturamento &&
        setDataPrimeiroFaturamento(
          new Date(editParceiro?.dataPrimeiroFaturamento)
        );

      // setUfPrincipal(editParceiro?.municipio?.uf?.id);
      setNomeCidadePrincipal(editParceiro?.municipio?.id);

      setEmpresa(editParceiro?.empresa);
      setDiasMedios(editParceiro?.diasMedios);

      setValue("tipo", editParceiro.tipo);
      setValue("nomeTratamento", editParceiro.nomeTratamento);
      setValue("nomeCompleto", editParceiro.nomeCompleto);
      setValue("ativo", editParceiro.ativo);
      setValue("tipoPessoa", editParceiro.tipoPessoa);
      setValue("sexo", editParceiro.sexo);
      setValue("cpfCnpj", editParceiro.cpfCnpj);
      setValue("rgInscEstadual", editParceiro.rgInscEstadual);
    }
  }, [editParceiro, setValue]);


  useEffect(() => {
    setValue("tipo", tipo);
    setValue("nomeTratamento", nomeTratamento);
    setValue("nomeCompleto", nomeCompleto);
    setValue("ativo", ativo);
    setValue("tipoPessoa", tipoPessoa);
    setValue("sexo", sexo);
    setValue("cpfCnpj", cpfCnpj);
  }, [
    tipo,
    nomeTratamento,
    nomeCompleto,
    ativo,
    tipoPessoa,
    sexo,
    cpfCnpj,
    setValue,
  ]);


  const [carregandoRequisicao, setCarregandoRequisicao] = useState(true);

  // const listaUf = listaUfDefault

  const history = useHistory();
  const [selectedUf, setSelectedUf] = useState([]);
  const [listaUf, setListaUf] = useState([]);

  useEffect(() => {
    async function fetchUf() {
      const uf = await getAllUf();
      setListaUf(uf);

      if (editParceiro && editParceiro.municipio && editParceiro.municipio.uf) {
        const ufId = String(editParceiro.municipio.uf.id);
        setUfPrincipal(ufId);
      }

      if (editParceiro && editParceiro.ufInscricao) {
        setUfInscricao(editParceiro.ufInscricao);
      }
    }
    fetchUf();
  }, [editParceiro]);

  useEffect(() => {
    if (listaUf.length > 0 && ufPrincipal) {
      // Atualiza o dropdown forçadamente se necessário
      setUfPrincipal((prevUf) => listaUf.find((uf) => String(uf.id) === String(prevUf))?.id || prevUf);
    }
  }, [listaUf, ufPrincipal]);

  const fetchMunicipios = useCallback(async () => {
    const municipios = await listaMunicipioPorUf(ufPrincipal);
    setSelectedUf(municipios);
  }, [ufPrincipal]);

  useEffect(() => {
    if (ufPrincipal !== null) {
      fetchMunicipios();
    }
  }, [ufPrincipal, fetchMunicipios]);


  function validandandoCpfCpnj() {
    if (cpfCnpj) {
      return cpfCnpj
        ?.replaceAll(".", "")
        .replaceAll("-", "")
        .replaceAll("/", "")
        .replaceAll("_", "");
    }
  }


  const bodySendPost = {
    parceiroTipoIds: tipo,
    nomeTratamento,
    nomeCompleto,
    historico,
    dataNasc: dataNasc && new Date(dataNasc),
    cpfCnpj: validandandoCpfCpnj(),
    rgInscEstadual,
    ativo,
    tipoPessoa,
    sexo,
    dataPrimeiroFaturamento: dataPrimeiroFaturamento && new Date(dataPrimeiroFaturamento),
    empresa: handlerEmpresa(empresa),
    diasMedios,
    municipio: nomeCidadePrincipal ? { id: nomeCidadePrincipal } : null,
    ufInscricao,
    insMunicipal

  };

  const bodySendUpdate = {
    parceiroTipoIds: tipo,
    nomeTratamento,
    nomeCompleto,
    historico,
    dataNasc: dataNasc && new Date(dataNasc),
    cpfCnpj: validandandoCpfCpnj(),
    rgInscEstadual,
    ativo,
    tipoPessoa,
    sexo,
    dataPrimeiroFaturamento: dataPrimeiroFaturamento && new Date(dataPrimeiroFaturamento),
    empresa: handlerEmpresa(empresa),
    diasMedios,
    municipio: nomeCidadePrincipal ? { id: nomeCidadePrincipal } : null,
    ufInscricao,
    insMunicipal
  };


  function handlerEmpresa(empresaProps) {
    if (empresaProps?.id) {
      const empresa = {
        id: empresaProps?.id,
        nomeFantasia: empresaProps?.nomeFantasia,
      };
      return empresa;
    } else {
      return empresaProps;
    }
  }

  async function postApi(e) {
    setCarregandoRequisicao(true);
    try {
      if (editParceiro) {
        await putParceiro(editParceiro.id, bodySendUpdate);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Parceiro alterado com sucesso!",
        });
      } else {
        await saveParceiro(bodySendPost);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Parceiro salvo com sucesso!",
        });
      }
      dispatch(setFiltroParceiro(reduxStateInicial.filtroParceiro));
      setTimeout(() => {
        history.push("/parceiros");
      }, 1500);
    } catch (e) {
      const mensagem = e.response?.data?.userMessage;
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${mensagem}`,
        life: 8000,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  function labelCpfCnpj() {
    if (tipoPessoa === "F") {
      return "CPF";
    } else if (tipoPessoa === "J") {
      return "CNPJ";
    } else {
      return "CPF/CNPJ";
    }
  }

  function labelRgInsc() {
    if (tipoPessoa === "F") {
      return "RG";
    } else if (tipoPessoa === "J") {
      return "Inscrição Estadual";
    } else {
      return "RG/Inscrição Estadual";
    }
  }

  useEffect(() => {
    // Se editParceiro.tipoPessoa for igual ao tipoPessoa atual, não faz nada
    if (editParceiro?.tipoPessoa === tipoPessoa || tipoPessoa === undefined) {
      return;
    }

    if (editParceiro?.tipoPessoa !== tipoPessoa) {
      setCpfCnpj('');
      setRgInscEstadual('');
    }
    if (editParceiro === null) {
      setCpfCnpj('');
      setRgInscEstadual('');
    }

    if (tipoPessoa === "F") {
      setInsMunicipal(''); // Limpa o campo Inscrição Municipal
    }

  }, [tipoPessoa, editParceiro]);



  useEffect(() => {
    async function fetchEmpresas() {
      const empresas = await getEmpresaResumo();
      setAllEmpresas(empresas);

      if (editParceiro && editParceiro.empresa) {
        const empresaSelecionada = empresas.find(
          (empresa) => empresa.id === editParceiro.empresa.id
        );
        setEmpresa(empresaSelecionada);
      }
    }
    fetchEmpresas();
  }, [editParceiro]);


  const fields = [
    [
      [
        {
          label: "Tipo ",
          value: tipo,
          setState: setTipo,
          required: true,
          type: "multiSelect",
          options: listaTipo,
          validacao: { ...register("tipo") },
          errorMessage: errors.tipo?.message,
          datatesteid: "tipoID",
          setAlteracoes: setAlteracoes,
          optionLabel: "tipo",
          optionValue: "id"
        },
      ],
      [
        {
          label: "Nome de Tratamento ",
          value: nomeTratamento,
          setState: setNomeTratamento,
          maxLength: 100,
          required: true,
          validacao: { ...register("nomeTratamento") },
          errorMessage: errors.nomeTratamento?.message,
          datatesteid: "nomeTratamentoID",
          setAlteracoes: setAlteracoes,
        },
      ],
      [
        {
          label: "Empresa",
          value: empresa,
          setState: setEmpresa,
          type: "dropdown",
          options: allEmpresas,
          optionLabel: "nomeFantasia",
          filter: true,
          setAlteracoes: setAlteracoes,
          datatesteid: "nomeFantasia",
        },
      ],
      [
        {
          label: "Nome Completo ",
          value: nomeCompleto,
          setState: setNomeCompleto,
          maxLength: 100,
          required: true,
          validacao: { ...register("nomeCompleto") },
          errorMessage: errors.nomeCompleto?.message,
          datatesteid: "nomeCompletoID",
          setAlteracoes: setAlteracoes,
        },
      ],
      [
        {
          label: "Data de Nascimento ",
          value: tipoPessoa === "J" ? "" : dataNasc,
          setState: setDataNasc,
          type: tipoPessoa === "J" ? "" : "date",
          style: { height: 60 },
          datatesteid: "dataNascimentoID",
          setAlteracoes: setAlteracoes,
          disabled: tipoPessoa === "J",
        },
        {
          label: "Data do Primeiro Faturamento ",
          value: dataPrimeiroFaturamento,
          setState: setDataPrimeiroFaturamento,
          type: "date",
          style: { height: 60 },
          datatesteid: "dataPrimeiroFaturamentoID",
          setAlteracoes: setAlteracoes,
        },
      ],
      [
        {
          label: "UF Principal",
          value: ufPrincipal,
          setState: setUfPrincipal,
          type: "dropdown",
          options: listaUf,
          datatesteid: "ufPrincipalID",
          optionLabel: "nome",
          optionValue: "id",
          setAlteracoes: setAlteracoes,
        },
        {
          label: "Cidade Principal",
          value: nomeCidadePrincipal,
          setState: setNomeCidadePrincipal,
          type: "dropdown",
          options: selectedUf,
          optionLabel: "nome",
          optionValue: "id",
          datatesteid: "nomeCidadePrincipal",
          setAlteracoes: setAlteracoes,

        },
      ],
      [
        {
          label: "UF de Inscrição",
          value: ufInscricao,
          setState: setUfInscricao,
          type: "dropdown",
          options: listaUf,
          optionLabel: "nome",
          optionValue: "sigla",
          validacao: { ...register("ufInscricao") },
          datatesteid: "ufInscricao",
          setAlteracoes: setAlteracoes,
          style: { width: '50%' }
        },
      ],
    ],
    [
      [
        {
          label: "Status ",
          value: ativo,
          setState: setAtivo,
          type: "selectButton",
          options: [
            { name: "Ativo", value: "S" },
            { name: "Inativo", value: "N" },
          ],
          optionLabel: "name",
          optionValue: "value",
          required: true,
          validacao: { ...register("ativo") },
          errorMessage: errors.ativo?.message,
          datatesteid: "statusID",
          setAlteracoes: setAlteracoes,
        },
      ],
      [
        {
          label: "Tipo de Pessoa ",
          value: tipoPessoa,
          setState: setTipoPessoa,
          type: "selectButton",
          options: [
            { label: "Física", value: "F" },
            { label: "Juridica", value: "J" },
          ],
          required: true,
          validacao: { ...register("tipoPessoa") },
          errorMessage: errors.tipoPessoa?.message,
          datatesteid: "tipoPessoaID",
          setAlteracoes: setAlteracoes,
        },
      ],
      [
        {
          label: "Sexo ",
          value: tipoPessoa === "J" ? "" : sexo,
          setState: setSexo,
          options: [
            { label: "Masculino", value: "M" },
            { label: "Feminino", value: "F" },
          ],
          type: tipoPessoa === "J" ? "" : "selectButton",
          placeholder: "Escolha um Sexo",
          required: tipoPessoa === "F" ? true : false,
          validacao: { ...register("sexo") },
          errorMessage: errors.sexo?.message,
          datatesteid: "sexoID",
          setAlteracoes: setAlteracoes,
          disabled: tipoPessoa === "J",
        },
      ],
      [
        {
          label: labelCpfCnpj(),
          value: cpfCnpj,
          setState: setCpfCnpj,
          type: "inputMaskCpfCnpj",
          state: tipoPessoa,
          required: true,
          validacao: { ...register("cpfCnpj") },
          errorMessage: errors.cpfCnpj?.message,
          datatesteid: "cpfcpnjID",
          setAlteracoes: setAlteracoes,
        },
        {
          label: labelRgInsc(),
          value: rgInscEstadual,
          setState: setRgInscEstadual,
          maxLength: 20,
          validacao: { ...register("rgInscEstadual") },
          datatesteid: "rginscID",
          setAlteracoes: setAlteracoes,
        },
      ],
      [
        {
          label: "Inscrição Municipal",
          value: insMunicipal,
          setState: setInsMunicipal,
          maxLength: 20,
          validacao: { ...register("insMunicipal") },
          datatesteid: "inscricaoMunicipalID",
          setAlteracoes: setAlteracoes,
          disabled: tipoPessoa === "F"
        },
      ],
      [
        {
          label: "Histórico",
          value: historico,
          setState: setHistorico,
          type: "textArea",
          maxLength: 2000,
          datatesteid: "historicoID",
          setAlteracoes: setAlteracoes,
        },
      ],
    ],
  ];

  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true)
    } else {
      history.push("/parceiros", { page: page })
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  return (
    <>
      <LoadingOverlayComponents
        active={carregandoRequisicao}
        put
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <Header title="Parceiro" onClick={editParceiro ? "edit" : "add"} />
        <div>
          <div className="parceiro__wrapper">
            <div className="parceiro__container">
              <div className="parceiroForm">
                <>
                  <ModalComponents
                    visible={alteracoesFeitas}
                    onHide={() => setAlteracoesFeitas(false)}
                    onClickCancelar={() => setAlteracoesFeitas(false)}
                    onClickConfirmar={() => {
                      history.push("/parceiros", { page: page })
                    }}
                    onClick="warning"
                    descricao={modalWarning}
                  />

                  {fields.map((f) => {
                    return (
                      <div
                        key={`div ${fields.indexOf(f)}`}
                        className="parceiroForm__section"
                      >
                        {f.map((row) => (
                          <div key={row.id} style={{ display: "flex" }}>
                            <FormInputs arrayInputs={row} />
                          </div>
                        ))}
                      </div>
                    );
                  })}
                </>
              </div>

              <div className="parceiroForm__containerButtons">
                <ButtonComponent
                  type="cancel"
                  onClick={() => validateGoBack()}
                />
                <ButtonComponent
                  type="confirm"
                  onClick={handleSubmit(postApi)}
                />
              </div>
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};
