import React from "react";
import { AutoCompletarComponent } from "../AutoCompletar";
import { AutoCompleteComponent } from "../AutoComplete";
import { CheckBoxForm } from "./CheckboxForm";
import { DateInput } from "./DateInput";
import { DropdownComponent } from "./DropdownComponent";
import { Input } from "./Input";
import { InputFile } from "./InputFile";
import { InputMaskCpfCnpj } from "./InputMaskCpfCnpj";
import { InputNumberComponent } from "./InputNumber";
import { InputTextAreaForm } from "./InputTextAreaForm";
import { MultiSelectComponent } from "./MultiSelectComponent";
import Select from "./Select";
import { SelectButtonForm } from "./SelectButton";
import { SetOfDates } from "./SetOfDates";

export const FormInputs = ({ arrayInputs }) => {
  const renderInputs = arrayInputs?.map((i) => {
    switch (i.type) {
      case "select":
        return (
          <Select
            key={i.label}
            label={i.label}
            value={i.value}
            setState={i.setState}
            options={i.options}
            placeholder={i.placeholder}
            optionLabel={i.optionLabel}
            isEmpty={i.isEmpty}
            required={i.required}
            style={i.style}
            validacao={i.validacao}
            errorMessage={i.errorMessage}
            datatesteid={i.datatesteid}
            optionsFilter={i.optionsFilter}
            filter={i.filter}
            attribute={i.attribute}
            onClickDelete={i.onClickDelete}
            onClickFunction={i.onClickFunction}
            setAlteracoes={i.setAlteracoes}
          />
        );
      case "autoComplete":
        return (
          <AutoCompleteComponent
            key={i.label}
            label={i.label}
            value={i.value}
            setValue={i.setValue}
            options={i.options}
            datatesteid={i.datatesteid}
            errorMessage={i.errorMessage}
            genericFunction={i.genericFunction}
            filter={i.filter}
            attribute={i.attribute}
            optionLabel={i.optionLabel}
          />
        );
      case "multiSelect":
        return (
          <MultiSelectComponent
          key={i.label}
          label={i.label}
          value={i.value}
          setState={i.setState}
          options={i.options}
          placeholder={i.placeholder}
          optionLabel={i.optionLabel}
          optionValue={i.optionValue}
          isEmpty={i.isEmpty}
          required={i.required}
          style={i.style}
          validacao={i.validacao}
          errorMessage={i.errorMessage}
          datatesteid={i.datatesteid}
          optionsFilter={i.optionsFilter}
          filter={i.filter}
          attribute={i.attribute}
          onClickDelete={i.onClickDelete}
          onClickFunction={i.onClickFunction}
          setAlteracoes={i.setAlteracoes}
          onFocus={i.onFocus}
          />
        );
      case "autoCompletar":
        return (
          <AutoCompletarComponent
            completeMethod={i.completeMethod}
            key={i.label}
            label={i.label}
            value={i.value}
            setValue={i.setValue}
            options={i.options}
            datatesteid={i.datatesteid}
            errorMessage={i.errorMessage}
            optionLabel={i.optionLabel}
            onClickDelete={i.onClickDelete}
            disabled={i.disabled}
            deleteDisable={i.deleteDisable}
            required={i.required}
          />
        );
      case "inputNumber":
        return (
          <InputNumberComponent
            validacao={i.validacao}
            errorMessage={i.errorMessage}
            style={i.style}
            label={i.label}
            required={i.required}
            setState={i.setState}
            isEmpty={i.isEmpty}
            field={i.field}
            value={i.value}
            prefix={i.prefix}
            minFractionDigits={i.minFractionDigits}
            maxFractionDigits={i.maxFractionDigits}
            maxLength={i.maxLength}
            data-testid={i.datatestid}
          />
        );
      case "dropdown":
        return (
          <DropdownComponent
            key={i.label}
            label={i.label}
            value={i.value}
            setState={i.setState}
            options={i.options}
            placeholder={i.placeholder}
            optionLabel={i.optionLabel}
            isEmpty={i.isEmpty}
            disabled={i.disabled}
            required={i.required}
            style={i.style}
            validacao={i.validacao}
            errorMessage={i.errorMessage}
            datatesteid={i.datatesteid}
            optionsFilter={i.optionsFilter}
            filter={i.filter}
            attribute={i.attribute}
            setAlteracoes={i.setAlteracoes}
            optionValue={i.optionValue}
            onShow={i.onShow}
            loading ={i.loading}
          />
        );
      case "selectButton":
        return (
          <SelectButtonForm
            key={i.label}
            label={i.label}
            value={i.value}
            options={i.options}
            optionLabel={i.optionLabel}
            setState={i.setState}
            isEmpty={i.isEmpty}
            required={i.required}
            style={i.style}
            setValue={i.setValue}
            validacao={i.validacao}
            errorMessage={i.errorMessage}
            datatesteid={i.datatesteid}
            setAlteracoes={i.setAlteracoes}
          />
        );
      case "fieldCustom":
        return i.field;
      case "textArea":
        return (
          <InputTextAreaForm
            key={i.label}
            label={i.label}
            value={i.value}
            setState={i.setState}
            type={i.type}
            isEmpty={i.isEmpty}
            required={i.required}
            style={i.style}
            maxLength={i.maxLength}
            rows={i.rows}
            validacao={i.validacao}
            errorMessage={i.errorMessage}
            datatesteid={i.datatesteid}
            setAlteracoes={i.setAlteracoes}
            field={i.field}
          />
        );
      case "date":
        return (
          <DateInput
            key={i.label}
            label={i.label}
            value={i.value}
            setState={i.setState}
            endValue={i.endValue}
            setEndState={i.setEndState}
            type={i.type}
            isEmpty={i.isEmpty}
            required={i.required}
            style={i.style}
            rangeOfDate={i.rangeOfDate}
            directionCol={i.directionCol}
            datatesteid={i.datatesteid}
            datatesteidrange={i.datatesteidrange}
            setAlteracoes={i.setAlteracoes}
            validacao={i.validacao}
          />
        );

      case "inputMaskCpfCnpj":
        return (
          <InputMaskCpfCnpj
            key={i.label}
            label={i.label}
            value={i.value}
            setState={i.setState}
            isEmpty={i.isEmpty}
            required={i.required}
            state={i.state}
            mask={i.value}
            validacao={i.validacao}
            maxLength={i.maxLength}
            errorMessage={i.errorMessage}
            datatesteid={i.datatesteid}
            setAlteracoes={i.setAlteracoes}
          />
        );
      case "checkbox":
        return (
          <CheckBoxForm
            key={i.label}
            label={i.label}
            value={i.value}
            options={i.options}
            optionLabel={i.optionLabel}
            setState={i.setState}
            isEmpty={i.isEmpty}
            required={i.required}
            style={i.style}
          />
        );
      case "setOfDates":
        return (
          <SetOfDates
            key={i.label}
            label={i.label}
            dates={i.dates}
            style={i.style}
            datatesteid={i.datatesteid}
            datatesteidrange={i.datatesteidrange}
          />
        );
      case "file":
        return (
          <InputFile
          key={i.label}
          label={i.label}
          value={i.value}
          setState={i.setState}
          type={i.type}
          isEmpty={i.isEmpty}
          required={i.required}
          style={i.style}
          maxLength={i.maxLength}
          dataType={i.dataType}
          disabled={i.disabled}
          validacao={i.validacao}
          errorMessage={i.errorMessage}
          datatesteid={i.datatesteid}
          setAlteracoes={i.setAlteracoes}
          />
        );
      default:
        return (
          <Input
            key={i.label}
            label={i.label}
            value={i.value}
            setState={i.setState}
            type={i.type}
            isEmpty={i.isEmpty}
            required={i.required}
            style={i.style}
            maxLength={i.maxLength}
            dataType={i.dataType}
            disabled={i.disabled}
            validacao={i.validacao}
            errorMessage={i.errorMessage}
            datatesteid={i.datatesteid}
            setAlteracoes={i.setAlteracoes}
          />
        );
    }
  });
  return <>{renderInputs}</>;
};
