import { yupResolver } from "@hookform/resolvers/yup";
import { addLocale } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  deleteSaldoContaCorrente,
  getSaldoByContaId,
  postSaldoContaCorrente,
  putSaldoContaCorrente,
} from "../../api";
import { messageRequired } from "../../default/messages";
import { brazilianCalendarParams } from "../../utils";
import { formatValue } from "../../utils/value";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalAdicao } from "../ModalAdicao";
import { ModalComponents } from "../ModalComponents";
import { ReactComponent as Icon } from "./iconBorracha.svg";
import "./styles.css";

export const SaldoConta = ({
  abrirSaldoConta,
  setAbrirSaldoConta,
  contaCorrente,
  loadingComponent,
  setLoadingComponent,
}) => {
  const toast = useRef(null);
  addLocale("pt-br", brazilianCalendarParams);

  const validationPost = yup
    .object({
      saldoConta: yup.string().required(messageRequired),
      dataSaldo: yup.string().required(messageRequired),
    })
    .required("");

  const [dialogConfirmar, setDialogConfirmar] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  const validationEdit = yup
    .object({
      saldoConta: yup.string().required(messageRequired),
      dataSaldo: yup.string().required(messageRequired),
    })
    .required("");

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    setValue: setValueEdit,
    formState: { errors: errorsEdit },
  } = useForm({
    resolver: yupResolver(validationEdit),
  });

  const limparInput = () => {
    setDataSaldo(null);
    setSaldoConta(null);
    setSaldoErp(null);
  };

  async function voltarRateio() {
    limparInput();
    reset();
    setAbrirSaldoConta(false);
  }

  const [saldoConta, setSaldoConta] = useState(null);

  const [saldoErp, setSaldoErp] = useState(null);

  const [dataSaldo, setDataSaldo] = useState();

  const [saldoContaEdit, setSaldoContaEdit] = useState(null);

  const [saldoErpEdit, setSaldoErpEdit] = useState(null);

  const [dataSaldoEdit, setDataSaldoEdit] = useState();

  const [saldoContaList, setSaldoContaList] = useState([]);

  const [globalFilter, setGlobalFilter] = useState("");

  const [deleteModal, setDeleteModal] = useState(false);

  const [editModal, setEditModal] = useState(false);

  const [objDelete, setObjDelete] = useState("");

  const [objPut, setObjPut] = useState("");

  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);

  function handleYearIsNANDateTime(date) {
    return date && new Date(date);
  }

  useEffect(() => {
    setValue("saldoConta", saldoConta);
    setValue("dataSaldo", dataSaldo);
  }, [saldoConta, dataSaldo, setValue]);

  useEffect(() => {
    setValueEdit("saldoConta", saldoContaEdit);
    setValueEdit("dataSaldo", dataSaldoEdit);
  }, [saldoContaEdit, dataSaldoEdit, setValueEdit]);

  const modalExcluir = [
    { label: "Saldo:", value: objDelete.saldoBancario },
    { label: "Valor de Ajuste:", value: objDelete.saldoErp },
    { label: "Data do Saldo:", value: objDelete.referencia },
  ];

  const getSaldo = useCallback(async () => {
    setCarregandoRequisicao(true);
    try {
      const resultado = await getSaldoByContaId(contaCorrente.id);
      setSaldoContaList(resultado);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }, [contaCorrente]);

  useEffect(() => {
    if (abrirSaldoConta) {
      getSaldo();
    }
  }, [abrirSaldoConta, getSaldo]);

  async function putSaldo(rowData) {
    setLoadingComponent(true);
    try {
      await putSaldoContaCorrente(rowData.id, contaPut).then(() => getSaldo());
      await toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Saldo alterado com sucesso!",
      });
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
      });
    } finally {
      setLoadingComponent(false);
      setEditModal(false);
      setDialogConfirmar(false);
    }
  }

  async function postSaldo() {
    setCarregandoRequisicao(true);
    try {
      await postSaldoContaCorrente(contaCorrente.id, contaPost).then(() =>
        getSaldo()
      );
      await toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Saldo salvo com sucesso!",
      });
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
      });
    } finally {
      limparInput();
      setCarregandoRequisicao(false);
    }
  }

  const contaPost = {
    saldoBancario: saldoConta,
    referencia: dataSaldo,
    saldoErp: saldoErp,
  };

  const contaPut = {
    saldoBancario: saldoContaEdit,
    referencia: dataSaldoEdit,
    saldoErp: saldoErpEdit,
  };

  const deleteConta = async (rowData) => {
    setCarregandoRequisicao(true);
    try {
      await deleteSaldoContaCorrente(rowData.id).then(() => getSaldo());
      await toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Saldo excluído com sucesso!",
      });
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
        life: 8000,
      });
    } finally {
      setCarregandoRequisicao(false);
      setDeleteModal(false);
    }
  };

  function options(rowData) {
    return (
      <div>
        <Button
          className="opcoes-lupa table__opcoes--black "
          icon="pi pi-pencil"
          tooltip="Editar"
          onClick={() => {
            preencherEdicao(rowData);
            setEditModal(true);
            setObjPut(rowData);
          }}
          tooltipOptions={{ position: "left" }}
        />
        <Button
          className="table__opcoes--red"
          icon="pi pi-times"
          tooltip="Excluir"
          onClick={() => {
            setDeleteModal(true);
            setObjDelete(rowData);
          }}
          tooltipOptions={{ position: "left" }}
        />
      </div>
    );
  }

  function valorSaldoFormat(valor) {
    return <>{formatValue(valor.saldoBancario)}</>;
  }

  function valorAjusteFormat(valor) {
    return <>{formatValue(valor.saldoErp)}</>;
  }

  async function preencherEdicao(rowData) {
    setDataSaldoEdit(
      new Date(rowData.referencia.split("/").reverse().join("-") + "T00:00:00")
    );
    setSaldoContaEdit(rowData.saldoBancario);
    setSaldoErpEdit(rowData.saldoErp);
  }

  function cancelarEdicao() {
    setEditModal(false);
    setSaldoContaEdit(null);
    setSaldoErpEdit(null);
    setDataSaldoEdit(null);
  }

  const editFields = [
    {
      label: "Saldo da Conta Corrente",
      setState: setSaldoContaEdit,
      field: "Saldo da Conta Corrente",
      value: saldoContaEdit,
      prefix: "R$",
      minFractionDigits: 2,
      maxFractionDigits: 2,
      maxLength: 14,
      datatestid: "saldoErpID",
      type: "inputNumber",
      validacao: { ...registerEdit("saldoConta") },
      isEmpty: !!errorsEdit.saldoConta,
      required: true,
    },
    {
      label: "Valor de Ajuste",
      setState: setSaldoErpEdit,
      field: "Valor de Ajuste",
      value: saldoErpEdit,
      prefix: "R$",
      minFractionDigits: 2,
      maxFractionDigits: 2,
      maxLength: 14,
      datatestid: "saldoErpID",
      type: "inputNumber",
    },
    {
      label: "Data do Saldo",
      setState: setDataSaldoEdit,
      value: dataSaldoEdit,
      datatestid: "dataSaldoID",
      type: "date",
      validacao: { ...registerEdit("dataSaldo") },
      isEmpty: !!errorsEdit.dataSaldo,
      required: true,
    },
  ];

  const closeModal = () => {
    validationEdit.isValid(bodySend).then((response) => {});
  };

  const bodySend = {
    saldoConta: saldoContaEdit,
    dataSaldo: dataSaldoEdit,
  };

  const styleInput = {
    width: "100%",
  };

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="modalSaldoConta"
        header="Saldo Conta"
        modal={true}
        visible={abrirSaldoConta}
        onHide={() => voltarRateio()}
        data-testid="saldoConta_component"
      >
        <form onSubmit={handleSubmit(postSaldo)}>
          <div className="modalSaldoConta__header">
            <Button
              className="modalSaldoConta__header__buttons modalSaldoConta__header__buttons--trash"
              icon={<Icon />}
              tooltip="Limpar campos"
              type="button"
              onClick={() => limparInput()}
              data-testid="limparCamposID"
            />
          </div>
          <div className="modalSaldoConta__fields">
            <div className="modalSaldoConta__fields__flex__section_row">
            <div className="modalSaldoConta__fields__flex__section">
              <label htmlFor="vencimento">
                Data do Saldo<span className="form__asterisk">*</span>
              </label>
              <Calendar
                
                {...register("dataSaldo")}
                mask="99/99/9999"
                name="dataSaldo"
                dateFormat={"dd/mm/yy"}
                showIcon
                value={handleYearIsNANDateTime(dataSaldo)}
                onChange={(e) => {
                  setDataSaldo(e.value);
                }}
                field="dataSaldo"
                locale="pt-br"
                data-testid="dataSaldoID"
              />
              <small className="form__errorMsg">
                {errors.dataSaldo?.message}
              </small>
            </div>
              <div className="modalSaldoConta__fields__flex__section">
                <label>
                  Saldo da Conta Corrente
                  <span className="form__asterisk">*</span>
                </label>
                <InputNumber
                  {...register("saldoConta")}
                  onChange={(e) => {
                    setSaldoConta(e.value);
                  }}
                  field="Saldo Conta Corrente"
                  value={saldoConta}
                  prefix="R$"
                  style={styleInput}
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  maxLength={14}
                  data-testid="saldoContaID"
                />
                <small className="form__errorMsg">
                  {errors.saldoConta?.message}
                </small>
              </div>
              <div className="modalSaldoConta__fields__flex__section">
                <label>
                  Valor de Ajuste<span className="form__asterisk"></span>
                </label>
                <InputNumber
                  onChange={(e) => {
                    setSaldoErp(e.value);
                  }}
                  field="Valor de Ajuste"
                  value={saldoErp}
                  style={styleInput}
                  prefix="R$"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  maxLength={14}
                  data-testid="saldoErpID"
                />
              </div>
            </div>
          </div>
          <InputText
            className="modalRateio__filter"
            type="search"
            style={{ width: "100%" }}
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar"
            data-testid="pesquisarID"
          />
        </form>
        <LoadingOverlayComponents
          active={carregandoRequisicao}
          spinner
          text="Carregando..."
        >
          <div className="modalRateio__table">
            <DataTable
              resizableColumns
              columnResizeMode="expand"
              scrollable
              value={saldoContaList}
              responsive
              className="modalSaldoConta__dataTable"
              globalFilter={globalFilter}
              data-testid="tableID"
              emptyMessage="Nenhum resultado encontrado"
            >
              <Column
                style={{ width: "5vw" }}
                header="Saldo"
                className="centralizar"
                body={valorSaldoFormat}
                field="saldoBancario"
                data-testid="saldoContaID"
              />
              <Column
                style={{ width: "5vw" }}
                header="Valor de Ajuste"
                className="centralizar"
                field="saldoErp"
                body={valorAjusteFormat}
                data-testid="saldoErpID"
              />
              <Column
                style={{ width: "4vw" }}
                header="Data do Saldo"
                className="centralizar"
                field="referencia"
                data-testid="saldoErpID"
              />
              <Column
                style={{ width: "3vw" }}
                header="Opções"
                className="centralizar"
                body={options}
                data-testid="optionsID"
              />
            </DataTable>
          </div>
        </LoadingOverlayComponents>
        <div className="botao-voltar-modal">
          <Button
            className="modalSaldoConta__button--return botao-voltar"
            icon="pi pi-arrow-left"
            label="Voltar"
            data-testid="voltarRateioID"
            onClick={() => voltarRateio()}
          />
          <Button
            className="modalSaldoConta__header__buttons colorir-botao-adicionar"
            label="Adicionar"
            icon="pi pi-plus"
            tooltip="Incluir Saldo da Conta"
            data-testid="incluirRateioID"
            onClick={handleSubmit(postSaldo)}
          />
        </div>
        <ModalComponents
          title="saldo da conta"
          onClick="delete"
          descricao={modalExcluir}
          visible={deleteModal}
          onHide={() => setDeleteModal(false)}
          onClickCancelar={() => setDeleteModal(false)}
          onClickConfirmar={() => deleteConta(objDelete)}
          datatesteidconfirm="excluirSaldoModal"
        />
        <ModalAdicao
          title={"Editar Saldo da Conta"}
          visible={editModal}
          setVisible={setEditModal}
          cancel={() => cancelarEdicao()}
          confirm={() => setDialogConfirmar(true)}
          fields={editFields}
          onHide={() => cancelarEdicao()}
        />
        <ModalComponents
          title="Saldo da Conta"
          visible={dialogConfirmar}
          onHide={() => setDialogConfirmar(false)}
          onClick="edit"
          onClickCancelar={() => setDialogConfirmar(false)}
          handleSubmit={handleSubmitEdit}
          onClickConfirmarForm={() => putSaldo(objPut)}
          validationForm={closeModal}
          datatesteidconfirm="confirmarTestID"
          datatesteid="cancelarTestID"
        />
      </Dialog>
    </>
  );
};
