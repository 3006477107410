import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getContaCorrenteByDescricao,
  getParcelaByDescricao,
  getProjetoByNomeProjeto,
  getCentroDeCustoByAtivos
} from "../../api";
import { RateioTelaEditarAdicLista } from "../../components/RateioTelaEditarAdicLista";
import { RegistrarEditarLista } from "../../components/RegistrarEditarLista";

export const SalvarEditar = (props) => {
  const { idMovimentacao } = useParams();

  const [abrirRateio, setAbrirRateio] = useState(false);
  const [movimentacao, setMovimentacao] = useState("");
  const [listaContasCorrentes, setListaContasCorrentes] = useState([]);
  
  const [listaCentroDeCusto, setListaCentroDeCusto] = useState([]);
  const [listaProjeto, setListaProjeto] = useState([]);
  const [listaParcela, setListaParcela] = useState([]);

  const valueDropContaCorrente = useSelector(
    (state) => state.descricaoContaCorrenteFiltroDrop.descricao
  );
  const valueDropProjeto = useSelector(
    (state) => state.filtroProjetoDrop.nomeProjeto
  );
  const valueDropParcela = useSelector(
    (state) => state.descricaoParcelaContratoDrop.descricao
  );
  const toast = useRef(null);

  async function callFilterEndpoints(setState, endpoint, param, filterBy) {
    if (param !== null) {
      await endpoint(param)
        .then((resultado) => {
          if (resultado.length === 0) {
            toast.current.show({
              severity: "info",
              summary: "Info",
              detail: `${filterBy} não ${
                filterBy === "Natureza" ? "encontrada" : "encontrado"
              }. Tente novamente.`,
            });
          } else {
            setState(resultado);
          }
        })
        .catch((error) => {
          console.log(error);
        })

    }
  }

  useEffect(() => {
    callFilterEndpoints(
      setListaContasCorrentes,
      getContaCorrenteByDescricao,
      valueDropContaCorrente,
      "Conta Corrente"
    );
  }, [valueDropContaCorrente]);


  useEffect(() => {
    async function fetchCentroDeCusto() {
      try {
        const centros = await getCentroDeCustoByAtivos();
        setListaCentroDeCusto(centros);
      } catch (error) {
        console.error("Erro ao buscar lista de Centro de Custo:", error);
      }
    }

    fetchCentroDeCusto();
  }, []);


  useEffect(() => {
    callFilterEndpoints(
      setListaProjeto,
      getProjetoByNomeProjeto,
      valueDropProjeto,
      "Projeto"
    );
  }, [valueDropProjeto]);

  useEffect(() => {
    callFilterEndpoints(
      setListaParcela,
      getParcelaByDescricao,
      valueDropParcela,
      "Parcela"
    );
  }, [valueDropParcela]);
  

  return (
    <>
      <Toast ref={toast} />
      <RegistrarEditarLista
        idMovimentacao={idMovimentacao}
        setAbrirRateio={setAbrirRateio}
        setMovimentacao={setMovimentacao}
        getListaContasCorrentes={listaContasCorrentes}
        getCentroDeCusto={listaCentroDeCusto}
        getListaProjetos={listaProjeto}
        getListaParcelas={listaParcela}
        page={props?.location?.state?.page}
      />

      <RateioTelaEditarAdicLista
        listaProjetos={listaProjeto}
        setAbrirRateio={setAbrirRateio}
        abrirRateio={abrirRateio}
        movimentacao={movimentacao}
      />
    </>
  );
};
