import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroCC } from "../../actions";
import {
  getAllEmpresas,
  getAllParceiros,
} from "../../api";
import { paginationDefault } from "../../default/pagination";
import { Filtro } from "../Filtro";

export const FiltroContaCorrente = ({
  isFiltroVisible,
  setIsFiltroVisible,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [descricao, setDescricao] = useState(
    useSelector((state) => state.filtroCC.descricao)
  );
  const [agencia, setAgencia] = useState(
    useSelector((state) => state.filtroCC.agencia)
  );
  const [contaCorrente, setContaCorrente] = useState(
    useSelector((state) => state.filtroCC.contaCorrente)
  );
  const [nomeBanco, setNomeBanco] = useState(
    useSelector((state) => state.filtroCC.nomeBanco)
  );
  const [idBanco, setIdBanco] = useState(
    useSelector((state) => state.filtroCC.idBanco)
  );
  const [ativa, setAtiva] = useState(
    useSelector((state) => state.filtroCC.ativa)
  );

  const [idEmpresa, setIdEmpresa] = useState(
    useSelector((state) => state.filtroCC.idEmpresa) || null
  );
  const [idParceiro, setIdParceiro] = useState(
    useSelector((state) => state.filtroCC.idParceiro) || null  
  );

  const [listaEmpresa, setListaEmpresa] = useState([]);
  const [listaParceiro, setListaParceiro] = useState([]);


  useEffect(() => {
    const fetchEmpresas = async () => {
      const response = await getAllEmpresas();
      setListaEmpresa(response.content);
    };

    fetchEmpresas();
  }, []);

  useEffect(() => {
    const fetchListaParceiro = async () => {
      const response = await getAllParceiros();
      setListaParceiro(response);

    };

    fetchListaParceiro();
  }, []);



  const filterFields = [
    {
      name: "ativa",
      label: "Status:",
      value: ativa,
      setState: setAtiva,
      type: "selectButton",
      options: [
        { name: "Ambos", value: null },
        { name: "Ativa", value: "S" },
        { name: "Inativa", value: "N" },
      ],
      optionLabel: "name",
      optionValue: "value",
      datatesteid: "statusTest",
    },
    {
      name: "descricao",
      label: "Descrição:",
      value: descricao,
      setState: setDescricao,
      datatesteid: "descricaoTest",
    },
    {
      label: "Empresa:",
      value: idEmpresa,
      setState: setIdEmpresa,
      type: "dropdown",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      optionValue: "id",
      filter: true,
      filterBy: "nomeFantasia",
      datatesteid: "nomeFantasia"
    },
    {
      label: "Parceiro:",
      value: idParceiro,
      setState: setIdParceiro,
      type: "dropdown",
      options: listaParceiro,
      optionLabel: "nomeTratamento",
      optionValue: "id",
      filter: true,
      filterBy: "nomeTratamento",
      datatesteid: "nomeTratamento"


    },
    {
      name: "nomeBanco",
      label: "Banco:",
      value: nomeBanco,
      setState: setNomeBanco,
      datatesteid: "nomeBancoTest",
    },
    {
      name: "idBanco",
      label: "Código do Banco:",
      value: idBanco,
      setState: setIdBanco,
      dataType: "pint",
      datatesteid: "numeroBancoTest",
    },
    {
      name: "agencia",
      label: "Agência:",
      value: agencia,
      setState: setAgencia,
      datatesteid: "agenciaTest",
    },
    {
      name: "contaCorrente",
      label: "Nº Conta Corrente:",
      value: contaCorrente,
      setState: setContaCorrente,
      datatesteid: "contaCorrenteNumero",
      maxLength: 40,
    },
  ];


  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault);
    dispatch(
      setFiltroCC({
        agencia: agencia !== "" ? agencia : null,
        ativa,
        contaCorrente: contaCorrente !== "" ? contaCorrente : null,
        descricao: descricao ? descricao : null,
        nomeBanco,
        idEmpresa: idEmpresa !== "" ? idEmpresa : null,
        idParceiro: idParceiro !== "" ? idParceiro : null,
        idBanco: idBanco !== "" ? idBanco : null,
      })
    );
    setVisible(() => true);
  };
  
  const limpar = useCallback(() => {
    setDescricao("");
    setAgencia("");
    setContaCorrente("");
    setNomeBanco("");
    setIdBanco("");
    setIdEmpresa("");
    setIdParceiro("");
    setAtiva(null);
    setVisible(() => false);
    setClearInputs(false);
  }, [setClearInputs, setVisible]);

  useEffect(() => {
    if (clearInputs === true) {
      limpar();
    }
  }, [clearInputs, limpar]);

  useEffect(() => {
    if (clear === true) {
      limpar();
    }
  }, [clear, limpar]);

  return (
    <>
      <Toast ref={toast} />
      <Filtro
        filterFields={filterFields}
        setIsFiltroVisible={setIsFiltroVisible}
        isFiltroVisible={isFiltroVisible}
        clearFilter={limpar}
        handleConfirmFilter={filtrar}
      />
    </>
  );
};
