import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCentroCusto,
  getAllCentroDeCusto,
  getEmpresaById,
  getAllEmpresas,
  saveCentroCusto,
  updateCentroCusto,
} from "../../api";

import { yupResolver } from "@hookform/resolvers/yup";
import _ from "lodash";
import { Button } from "primereact/button";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  addListaCentroCusto,
  esconderLoading,
  exibirLoading,
  setFilterCentroDeCusto,
} from "../../actions";
import { FiltroCentroDeCusto } from "../../components/FiltroCentroDeCusto";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalAdicao } from "../../components/ModalAdicao";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired } from "../../default/messages";
import { paginationDefault } from "../../default/pagination";
import { Navbar } from "../../components/Navbar";

export const CentroDeCusto = () => {
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [dialogCadastroCusto, setDialogCadastroCusto] = useState(false);
  const [descricao, setDescricao] = useState(null);
  const [status, setStatus] = useState(null);
  const [id, setId] = useState(null);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState("");
  const [filtroCentroDeCusto, setFiltroCentroDeCusto] = useState(false);
  const [empresa, setEmpresa] = useState(null);
  const [listaEmpresa, setListaEmpresa] = useState([]);

  const [descricaoNull, setDescricaoNull] = useState(false);
  const [statusNull, setStatusNull] = useState(false);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(paginationDefault);

  const [excluircentroCusto, setExcluircentroCusto] = useState("");
  const [visualizarDescModalExclusao, setVisualizarDescModalExclusao] =
    useState("");
  const [dialogExcluir, setDialogExcluir] = useState(false);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const reduxStateAtual = useSelector((state) => state.filtroCentroDeCusto);
  const reduxStateInicial = useContext(ReduxContext);
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const objectFilterCentroDeCusto = useSelector(
    (state) => state.filtroCentroDeCusto
  );
  const [clearInputs, setClearInputs] = useState(false);

  const montarFiltro = useCallback(() => {
    const filtroCentroDeCusto = objectFilterCentroDeCusto;
    return filtroCentroDeCusto;
  }, [objectFilterCentroDeCusto]);

  const validationPost = yup.object({
    status: yup.string().required(messageRequired),
    empresa: yup.object().shape({
      id: yup.string().required(messageRequired),
      nomeFantasia: yup.string().required(messageRequired),
    }).required(messageRequired),
    descricao: yup.string().required(messageRequired),
  }).required();

  async function clearFilter() {
    const filterActive = _.isEqual(
      reduxStateAtual,
      reduxStateInicial.filtroCentroDeCusto
    );
    setVisible(!filterActive);
    if (filterActive === false) {
      setClear(true);
      dispatch(setFilterCentroDeCusto(reduxStateInicial.filtroCentroDeCusto));
      // setVisible(false)
    } else {
      setClear(false);
    }
  }

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  const optionsStatus = [
    { name: "Ativo", value: "ATIVA" },
    { name: "Inativo", value: "INATIVA" },
  ];


  useEffect(() => {
    const fetchEmpresas = async () => {

      const response = await getAllEmpresas();
      setListaEmpresa(response.content);

      if (empresa) {
        const empresaSelecionada = response.content.find(
          (emp) => emp.id === empresa
        );
        if (empresaSelecionada) {
          setEmpresa(empresaSelecionada);
        }
      }

    };

    fetchEmpresas();
  }, [empresa]);


  const fields = [
    {
      label: "Status",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: optionsStatus,
      optionLabel: "name",
      optionValue: "value",
      isEmpty: statusNull,
      required: true,
      validacao: { ...register("status") },
      errorMessage: errors.status?.message,
      setAlteracoes: setAlteracoes,
    },
    {
      label: "Descrição",
      value: descricao,
      setState: setDescricao,
      type: "textArea",
      rows: 5,
      isEmpty: descricaoNull,
      required: true,
      validacao: { ...register("descricao") },
      errorMessage: errors.descricao?.message,
      setAlteracoes: setAlteracoes,
      maxLength: 500,
    },
    {
      label: "Empresa",
      value: empresa,
      setState: setEmpresa,
      type: "dropdown",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      filter: true,
      filterBy: "nomeFantasia",
      datatesteid: "nomeFantasia",
      required: true,
      validacao: { ...register("empresa") },
      errorMessage: errors.empresa?.message,
    },
  ];

  const addEditfields = [
    {
      label: "Status",
      value: status,
      setState: setStatus,
      type: "selectButton",
      options: optionsStatus,
      optionLabel: "name",
      optionValue: "value",
      isEmpty: statusNull,
      required: true,
      validacao: { ...register("status") },
      errorMessage: errors.status?.message,
      setAlteracoes: setAlteracoes,
    },
    {
      label: "Descrição",
      value: descricao,
      setState: setDescricao,
      type: "textArea",
      rows: 5,
      isEmpty: descricaoNull,
      required: true,
      validacao: { ...register("descricao") },
      errorMessage: errors.descricao?.message,
      setAlteracoes: setAlteracoes,
      maxLength: 500,
    },
    {
      label: "Empresa",
      value: empresa,
      setState: setEmpresa,
      type: "dropdown",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      filter: true,
      filterBy: "nomeFantasia",
      datatesteid: "nomeFantasia",
      required: true,
      validacao: { ...register("empresa") },
      errorMessage: errors.empresa?.message,
    },
  ];

  function resetarInputCentroDeCusto() {
    reset();
    setAlteracoes(false);
    setDialogCadastroCusto(false);
    setDescricao(null);
    setEmpresa(null);
    setStatus(null);
    setId(null);
    setDescricaoNull(null);
    setStatusNull(null);
  }
  function cancelarCentroCusto() {
    setAlteracoesFeitas(false);
    if (alteracoes === true) {
      setAlteracoesFeitas(true);
      return null;
    }
    reset();
    setDialogCadastroCusto(false);
    setDescricao(null);
    setEmpresa(null);
    setStatus(null);
    setId(null);
    setDescricaoNull(null);
    setStatusNull(null);
  }

  async function editarLinhacentroCusto(listaCentroDeCusto) {
    const idEmpresa = listaCentroDeCusto?.idEmpresa;
    try {
      setCarregandoRequisicao(true);
      if (idEmpresa) {
        await getEmpresaById(idEmpresa).then((e) => setEmpresa(e));
      }
    } catch (error) {
    } finally {
      setCarregandoRequisicao(false);
    }
    setDialogCadastroCusto(true);
    setId(listaCentroDeCusto.id);
    setDescricao(listaCentroDeCusto.descricao);
    setStatus(listaCentroDeCusto.status);
  }

  function functionExcluirCentroCusto(rowData) {
    setDialogExcluir(true);
    setExcluircentroCusto(rowData.id);
    setVisualizarDescModalExclusao(rowData.descricao);
  }

  function isValid() {
    setDescricaoNull(!Boolean(descricao));
    setStatusNull(!Boolean(status));
    setStatusNull(!Boolean(status));

    return descricao && status;
  }

  const centroDeCusto = {
    descricao: descricao,
    status: status,
    idEmpresa: empresa?.id,
  };

  async function salvarEditarCusto() {
    if (isValid()) {
      setCarregandoRequisicao(true);
      try {
        if (id == null) {
          await saveCentroCusto(centroDeCusto).then((resultado) => {
            toast.current.show({
              severity: "success",
              summary: "Sucesso!",
              detail: "Centro de Custos salvo com sucesso!",
            });
            listaCentroDeCusto.push(resultado);
            resetarInputCentroDeCusto();
          });
        } else {
          if (descricao !== null && status !== null && id !== null) {
            await updateCentroCusto(id, centroDeCusto).then((resultado) => {
              toast.current.show({
                severity: "success",
                summary: "Sucesso!",
                detail: "Centro de Custos alterado com sucesso!",
              });
              listaCentroDeCusto.push(resultado);
              resetarInputCentroDeCusto();
            });
          }
        }
        setDialogCadastroCusto(false);
      } catch (e) {
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: `${e?.response?.data?.userMessage}`,
        });
      } finally {
        setCarregandoRequisicao(false);
      }
    }
  }

  const deleteCentroCustoFunction = async (rowData) => {
    setCarregandoRequisicao(true);
    try {
      await deleteCentroCusto(rowData).then(() => {
        dispatch(setFilterCentroDeCusto(reduxStateInicial.filtroCentroDeCusto));
        setClearInputs(true);
      });
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Centro de Custos excluído com sucesso!",
      });
      setDialogExcluir(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Info",
        life: 8000,
        detail: "Não foi possível deletar, possui movimentação vinculada",
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  };

  const listaCentroDeCusto = useSelector(
    (state) => state.movimentacoes.centroDeCusto
  );
  const atualizaListaRateio = useCallback(
    //Rateio
    (centroDeCusto) => {
      dispatch(addListaCentroCusto(centroDeCusto));
    },
    [dispatch]
  );

  const carregarLista = useCallback(() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };
    getAllCentroDeCusto(requestParams).then((resultado) => {
      atualizaListaRateio(resultado.content);
      setTotalRecords(resultado.totalElements);
      dispatch(esconderLoading());
    });
  }, [atualizaListaRateio, dispatch, lazyParams, montarFiltro]);

  useEffect(() => {
    const buttonClearFilter = _.isEqual(
      reduxStateAtual,
      reduxStateInicial?.filtroCentroDeCusto
    );
    setVisible(!buttonClearFilter);
    carregarLista();
  }, [
    lazyParams,
    montarFiltro,
    carregarLista,
    reduxStateAtual,
    reduxStateInicial,
  ]);

  const formattedStatus = (rowData, field) => {
    if (rowData[field] === "ATIVA") {
      return (
        <Button
          tooltip="Ativo"
          className="formattedStyles formattedStyles--check"
          icon="pi pi-check"
        />
      );
    } else if (rowData[field] === "INATIVA") {
      return (
        <Button
          tooltip="Inativo"
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times"
        />
      );
    }
  };


  useEffect(() => {
    setValue("status", status);
    setValue("descricao", descricao);
    setValue("empresa", empresa);
  }, [status, descricao, empresa, setValue]);

  const columns = [
    {
      field: "id",
      header: "Código",
      style: { maxWidth: "9vw" },
    },
    {
      field: "descricao",
      header: "Descrição",
      type: "customEllipsisLeftWithTooltip",
      style: { maxWidth: "66vw" },
    },
    {
      field: "status",
      header: "Status",
      type: "custom",
      style: { maxWidth: "9vw" },
      customFunc: formattedStatus,
    },
  ];

  const modalExcluir = [
    { label: "Código:", value: excluircentroCusto },
    { label: "Descrição:", value: visualizarDescModalExclusao },
  ];

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];
 
  return (
    <>
      <Navbar />

      <LoadingOverlayComponents
        active={carregandoRequisicao}
        spinner
        text="Carregando..."
      >
        <Toast ref={toast} />
        <ReactHintComponents />
        <ModalAdicao
          title={id ? "Editar Centro de Custo" : "Adicionar Centro de Custo"}
          visible={dialogCadastroCusto}
          setVisible={setDialogCadastroCusto}
          cancel={cancelarCentroCusto}
          confirm={handleSubmit(salvarEditarCusto)}  
          fields={id ? addEditfields : fields}
          onHide={() =>
            alteracoes === true
              ? setAlteracoesFeitas(true)
              : cancelarCentroCusto()
          }
        />

        <ModalComponents
          title="Centro de Custo"
          visible={dialogExcluir}
          onHide={() => setDialogExcluir(false)}
          descricao={modalExcluir}
          onClickConfirmar={() => deleteCentroCustoFunction(excluircentroCusto)}
          onClickCancelar={() => setDialogExcluir(false)}
          onClick="delete"
        />
        <ModalComponents
          visible={alteracoesFeitas}
          onHide={() => setAlteracoesFeitas(false)}
          onClickCancelar={() => setAlteracoesFeitas(false)}
          onClickConfirmar={() => {
            setAlteracoesFeitas(false);
            setAlteracoes(false);
            setDialogCadastroCusto(false);
            setDescricao(null);
            setStatus(null);
            setId(null);
            setDescricaoNull(null);
            setStatusNull(null);
          }}
          onClick="warning"
          descricao={modalWarning}
        />
        <Table
          arrayData={listaCentroDeCusto}
          loading={recarregando}
          columns={columns}
          headerTitle={"Centro de Custo"}
          onClickAdicionar={() => setDialogCadastroCusto(true)}
          onClickFiltrar={() => setFiltroCentroDeCusto(true)}
          onClickEdit={(rowData) => editarLinhacentroCusto(rowData)}
          tooltipEdit="Centro de Custo"
          onClickDelete={(rowData) => functionExcluirCentroCusto(rowData)}
          lazyParams={lazyParams}
          setLazyParams={setLazyParams}
          totalRecords={totalRecords}
          onClickClear={() => clearFilter()}
          visible={visible}
        />
        <FiltroCentroDeCusto
          clear={clear}
          clearInputs={clearInputs}
          setClearInputs={setClearInputs}
          setVisible={setVisible}
          setFiltroVisible={setFiltroCentroDeCusto}
          filtroCentroDeCusto={filtroCentroDeCusto}
          setLazyParamsProp={setLazyParams}
        />
      </LoadingOverlayComponents>
    </>
  );
};
