import React, { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroConci } from "../../actions";

import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";
import { useEffect } from "react";
import { getContaCorrenteByDescricao } from "../../api";
import { paginationDefault } from "../../default/pagination";
import {
  brazilianCalendarParams,
  convertToISO8601,
  convertToLocalOffset,
} from "../../utils";
import { Filtro } from "../Filtro";

export const FiltroConciliacao = ({
  setVisibleRight,
  visibleRight,
  clear,
  setLazyParamsProp,
  clearInputs,
  setClearInputs,
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [ultimaAlteracaoInicio, setUltimaAlteracaoInicio] = useState(
    useSelector((state) => state.filtroConci.ultimaAlteracaoInicio)
  );
  const [ultimaAlteracaoFim, setUltimaAlteracaoFim] = useState(
    useSelector((state) => state.filtroConci.ultimaAlteracaoFim)
  );
  const [vencimentoModificadoInicio, setVencimentoModificadoInicio] = useState(
    useSelector((state) => state.filtroConci.vencimentoModificadoInicio)
  );
  const [vencimentoModificadoFim, setVencimentoModificadoFim] = useState(
    new Date(new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10) + 'T00:00:00-03:00')
  );
  const [historico, setHistorico] = useState(
    useSelector((state) => state.filtroConci.historico)
  );
  const [tipoLancamento, setTipoLancamento] = useState("TODOS");
  const [conciliacaoInicio, setConciliacaoInicio] = useState(
    useSelector((state) => state.filtroConci.conciliacaoInicio)
  );
  const [conciliacaoFim, setConciliacaoFim] = useState(
    useSelector((state) => state.filtroConci.conciliacaoFim)
  );

  const [desconciliadoInicio, setDesconciliadoInicio] = useState(
    useSelector((state) => state.filtroConci.desconciliadoInicio)
  );

  const [desconciliadoFim, setDesconciliadoFim] = useState(
    useSelector((state) => state.filtroConci.desconciliadoFim)
  );

  const [tipoConciliacao, setTipoConciliacao] = useState("PENDENTE");

  const [contaCorrente, setContaCorrente] = useState();
  const [listaContaCorrente, setListaContaCorrente] = useState();

  const [cadastroInicio, setCadastroInicio] = useState(
    useSelector((state) => state.filtroConci.cadastroInicio)
  );
  const [cadastroFim, setCadastroFim] = useState(
    useSelector((state) => state.filtroConci.cadastroFim)
  );
  const [idMovPagarReceber, setIdMovPagarReceber] = useState(
    useSelector((state) => state.filtroConci.idMovPagarReceber)
  );

  const [tipoBaixa, setTipoBaixa] = useState(
    useSelector((state) => state.filtroConci.tipoBaixa)
  );
  const [valorLiquido, setValorLiquido] = useState(
    useSelector((state) => state.filtroConci.valorLiquido)
  );
  const [dataBaixaInicio, setDataBaixaInicio] = useState(
    useSelector((state) => state.filtroConci.dataBaixaInicio)
  );
  const [dataBaixaFim, setDataBaixaFim] = useState(
    useSelector((state) => state.filtroConci.dataBaixaFim)
  );

  addLocale("pt-br", brazilianCalendarParams);

  const selectTipoLancamento = [
    { name: "Ambos", value: "TODOS" },
    { name: "Despesa", value: "DESPESA" },
    { name: "Receita", value: "RECEITA" },
  ];

  const selectTipoConciliacao = [
    { name: "Todos", value: "TODOS" },
    { name: "Pendente", value: "PENDENTE" },
    { name: "Conciliado", value: "CONCILIADO" },
    { name: "Desconciliado", value: "DESCONCILIADO" },
  ];

  const selectTipoBaixa = [
    { name: "Todos", value: null },
    { name: "Manual", value: "MANUAL" },
    { name: "Automático", value: "AUTOMATICO" },
    { name: "Por Arquivo", value: "PORARQUIVO" },
  ];

  useEffect(() => {
    fetchAllCurrentAccount();
  }, []);

  async function fetchAllCurrentAccount() {
    try {
      const data = await getContaCorrenteByDescricao(null);

      setListaContaCorrente(data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${error?.response?.data?.userMessage}`
      });
    }
  }

  const filterFields = [
    {
      label: "Conta Corrente:",
      value: contaCorrente,
      setState: setContaCorrente,
      type: "dropdown",
      options: listaContaCorrente,
      optionLabel: "descricao",
      optionValue: "descricao",
      filter: true,
      filterBy: "descricao",
      datatesteid: "contaCorrenteID"
    },
    {
      label: "Data Baixa:",
      rangeOfDate: true,
      value: dataBaixaInicio,
      setState: setDataBaixaInicio,
      endValue: dataBaixaFim,
      setEndState: setDataBaixaFim,
      type: "date",
      directionCol: true,
      datatesteid: "dataBaixaID",
      datatesteidrange: "dataBaixaRangeID",
    },
    {
      name: "id",
      label: "Código:",
      value: idMovPagarReceber,
      setState: setIdMovPagarReceber,
      dataType: "pint",
      datatesteid: "idID",
    },
    {
      name: "historico",
      label: "Histórico:",
      value: historico,
      setState: setHistorico,
      rows: 5,
      datatesteid: "historicoID",
      maxLength: 100,
    },
    {
      name: "valorLiquido",
      label: "Valor Líquido:",
      value: valorLiquido,
      setState: setValorLiquido,
      datatesteid: "valorLiquidoID",
    },
    {
      name: "tipoConciliacao",
      label: "Tipo de Conciliação:",
      value: tipoConciliacao,
      setState: setTipoConciliacao,
      type: "selectButton",
      options: selectTipoConciliacao,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      name: "tipoBaixa",
      label: "Tipo de Baixa:",
      value: tipoBaixa,
      setState: setTipoBaixa,
      type: "selectButton",
      options: selectTipoBaixa,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      name: "tipoLancamento",
      label: "Tipo de Lançamento:",
      value: tipoLancamento,
      setState: setTipoLancamento,
      type: "selectButton",
      options: selectTipoLancamento,
      optionLabel: "name",
      optionValue: "value",
    },
    {
      label: "Conciliação:",
      rangeOfDate: true,
      value: conciliacaoInicio,
      setState: setConciliacaoInicio,
      endValue: conciliacaoFim,
      setEndState: setConciliacaoFim,
      type: "date",
      directionCol: true,
      datatesteid: "conciliacaoID",
      datatesteidrange: "conciliacaoRangeID",
    },
    {
      label: "Vencimento:",
      rangeOfDate: true,
      value: vencimentoModificadoInicio,
      setState: setVencimentoModificadoInicio,
      endValue: vencimentoModificadoFim,
      setEndState: setVencimentoModificadoFim,
      type: "date",
      directionCol: true,
      datatesteid: "conciliacaoID",
      datatesteidrange: "conciliacaoRangeID",
    },
    {
      label: "Desconciliação:",
      rangeOfDate: true,
      value: desconciliadoInicio,
      setState: setDesconciliadoInicio,
      endValue: desconciliadoFim,
      setEndState: setDesconciliadoFim,
      type: "date",
      directionCol: true,
      datatesteid: "desconciliacaoID",
      datatesteidrange: "desconciliacaoRangeID",
    },
    {
      label: "Ult. Alteração:",
      rangeOfDate: true,
      value: ultimaAlteracaoInicio,
      setState: setUltimaAlteracaoInicio,
      endValue: ultimaAlteracaoFim,
      setEndState: setUltimaAlteracaoFim,
      type: "date",
      directionCol: true,
      datatesteid: "utlAlteracaoID",
      datatesteidrange: "utlAlteracaoRangeID",
    },
    {
      label: "Lançamento Entre:",
      rangeOfDate: true,
      value: cadastroInicio,
      setState: setCadastroInicio,
      endValue: cadastroFim,
      setEndState: setCadastroFim,
      type: "date",
      directionCol: true,
      datatesteid: "lancamentoEntreID",
      datatesteidrange: "lancamentoEntreRangeID",
    },
  ];

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault)
    dispatch(
      setFiltroConci({
        conciliacaoInicio: convertToISO8601(conciliacaoInicio),
        conciliacaoFim: convertToISO8601(conciliacaoFim),
        ultimaAlteracaoInicio: convertToLocalOffset(ultimaAlteracaoInicio),
        ultimaAlteracaoFim: convertToLocalOffset(ultimaAlteracaoFim),
        desconciliadoInicio: convertToISO8601(desconciliadoInicio),
        desconciliadoFim: convertToISO8601(desconciliadoFim),
        cadastroInicio: convertToLocalOffset(cadastroInicio),
        cadastroFim: convertToLocalOffset(cadastroFim),
        dataBaixaInicio: convertToLocalOffset(dataBaixaInicio),
        dataBaixaFim: convertToLocalOffset(dataBaixaFim),
        idMovimentacao: idMovPagarReceber !== "" ? idMovPagarReceber : null,
        tipoBaixa,
        valorLiquido: valorLiquido !== "" ? valorLiquido : null,
        historico: historico !== "" ? historico : null,
        tipoLancamento,
        contaCorrente: {
          descricao: contaCorrente?.descricao
            ? contaCorrente?.descricao
            : contaCorrente,
        },
        tipoConciliacao,
        vencimentoModificadoInicio: convertToISO8601(
          vencimentoModificadoInicio
        ),
        vencimentoModificadoFim: convertToISO8601(vencimentoModificadoFim),
      })
    );
  };

  console.log("dataVencimentoInicio: ", vencimentoModificadoInicio);
  console.log("dataVencimentoFim: ", vencimentoModificadoFim);

  const limpar = useCallback(() => {
    setUltimaAlteracaoInicio(null);
    setUltimaAlteracaoFim(null);
    setConciliacaoInicio(null);
    setConciliacaoFim(null);
    setCadastroInicio(null);
    setCadastroFim(null);
    setDesconciliadoInicio(null);
    setDesconciliadoFim(null);
    setTipoLancamento("TODOS");
    setHistorico("");
    setTipoConciliacao("TODOS");
    setContaCorrente(null);
    setIdMovPagarReceber("");
    setValorLiquido("");
    setTipoBaixa(null);
    setDataBaixaInicio(null);
    setDataBaixaFim(null);
    setVencimentoModificadoInicio(null);
    setVencimentoModificadoFim(null);
    setClearInputs(false)
  }, [setClearInputs]);


  useEffect(() => {
    if (clear === true) {
      limpar();
    }
  }, [clear, limpar]);

  useEffect(() => {
    if (clearInputs === true) {
      limpar()
    }
  }, [clearInputs, limpar])

  return (
    <>
      <Toast ref={toast} />
      <Filtro
        filterFields={filterFields}
        setIsFiltroVisible={setVisibleRight}
        isFiltroVisible={visibleRight}
        clearFilter={limpar}
        handleConfirmFilter={filtrar}
      />
    </>
  );
};
