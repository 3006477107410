import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { Tree } from "primereact/tree";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { setReloadNatureza } from "../../actions";
import {
  getAllFatherNature,
  getAllNatureza
} from "../../api/index.js";
import { Header } from "../../components/Header";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import Dados from "./Dados";
import Metas from "./Metas";
import "./styles.css";
import { Navbar } from "../../components/Navbar/index.js";

export const Natureza = () => {
  const [idAtual, setIdAtual] = useState();
  const { idNatureza } = useParams();
  const [listaNaturezas, setListaNaturezas] = useState([]);
  const [natureza, setNatureza] = useState(defaultNatureza);
  const [showModalAlteracoes, setShowModalAlteracoes] = useState(false);
  const [data, setData] = useState([]);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const [expandedKeys] = useState({});
  const history = useHistory();
  const noChildren = !listaNaturezas.find((each) => each.idPai === idNatureza);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(true);
  const [listaNaturezaHierarquica, setListaNaturezaHierarquica] = useState([]);
  const [naturezaPai, setNaturezaPai] = useState();
  const updatedNatureza = useSelector((state) => state.updateNatureza?.reload);

  const toast = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchAllFatherNature();
  }, []);

  async function fetchAllFatherNature() {
    setCarregandoRequisicao(true);
    try {
      const data = await getAllFatherNature();
      
      const transformedData = data.map(item => ({
        paiId: item.id,    
        descricao: item.descricao
      }));
  
      setListaNaturezaHierarquica(transformedData);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${error?.response?.data?.userMessage}`
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  function handleClick(id) {
    if (idNatureza === id) return;
    setIdAtual(id);
    if (alteracoesFeitas) {
      setShowModalAlteracoes(true);
    } else {
      history.push(`/natureza/${id}`);
    }
  }

  function createNatureza() {
    setIdAtual(null);
    setNaturezaPai(null);
    if (alteracoesFeitas) {
      setShowModalAlteracoes(true);
    } else {
      history.push(`/naturezas`);
    }
  }

  useEffect(() => {
    getListNatureza();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedNatureza]);

  async function getListNatureza() {
    setCarregandoRequisicao(true);
    try {
      await getAllNatureza().then((resultado) => {
        const listaSemPai = resultado.filter((e) => !e.naturezaPai?.paiId);

        const newArray = retornaFilho(resultado, listaSemPai);

        setData(newArray);
        setListaNaturezas(resultado);
      });
    } finally {
      setCarregandoRequisicao(false);
      dispatch(
        setReloadNatureza({
          reload: false
        })
      );
    }
  }

  function retornaFilho(totalList, childrenList) {
    const array = childrenList.map((each) => {
      const children = totalList.filter((e) => e?.naturezaPai?.paiId === each.id);
      return {
        key: each.id,
        label: each.descricao,
        icon: children.length ? "pi pi-folder" : "pi pi-external-link",
        children: children.length ? retornaFilho(totalList, children) : []
      };
    });

    return array;
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  return (
    <>
      <Navbar />
      <LoadingOverlayComponents active={carregandoRequisicao} spinner text="Carregando...">
        <div>
          <Toast ref={toast} />
          <ModalComponents
            visible={showModalAlteracoes}
            onHide={() => setShowModalAlteracoes(false)}
            onClickCancelar={() => setShowModalAlteracoes(false)}
            onClickConfirmar={() => {
              setAlteracoesFeitas(false);
              setShowModalAlteracoes(false);
              if (!idAtual) {
                history.push(`/naturezas`);
              } else {
                history.push(`/natureza/${idAtual}`);
              }
            }}
            onClick="warning"
            descricao={modalWarning}
          />
          {/* <div className="header-natureza">
          <label>Natureza</label>
        </div> */}
          <Header title="Natureza" />
          <div className="containerDados">
            <div className="containerDados__tree">
              <div className="naturezaContainer__icon">
                <Button
                  className="naturezaContainer__icon--create"
                  icon="pi pi-plus"
                  onClick={createNatureza}
                  tooltip="Criar natureza"
                  tooltipOptions={{ position: "left" }}
                />
              </div>
              <Tree
                className="containerDados__tree--treeComponents"
                value={data}
                expandedKeys={expandedKeys}
                onSelect={(e) => {
                  handleClick(e.node.key);
                }}
                selectionMode="single"
              />
            </div>
            <div className="containerDados__section--dados">
              <div className="containerDados__section--dados--align"></div>
              <TabView>
                <TabPanel header="Dados da Natureza">
                  <Dados
                    naturezaPai={naturezaPai}
                    setNaturezaPai={setNaturezaPai}
                    noChildren={noChildren}
                    alteracoesFeitas={alteracoesFeitas}
                    getListNatureza={getListNatureza}
                    setAlteracoesFeitas={setAlteracoesFeitas}
                    natureza={natureza}
                    setNatureza={setNatureza}
                    getNaturezaPai={listaNaturezaHierarquica}
                  />
                </TabPanel>
                {idNatureza ? (
                  <TabPanel header="Metas por Ano">
                    <Metas
                      metaObject={{ id: null, ano: "", meta: null }}
                      setAlteracoesFeitas={setAlteracoesFeitas}
                      setCarregandoRequisicao={setCarregandoRequisicao}
                      carregandoRequisicao={carregandoRequisicao}
                    />
                  </TabPanel>
                ) : (
                  <TabPanel header="Metas por Ano" disabled></TabPanel>
                )}
              </TabView>
            </div>
          </div>
        </div>
      </LoadingOverlayComponents>
    </>
  );
};

export const defaultNatureza = {
  // id: 0,
  descricao: null,
  grau: 0,
  naturezaPai: null,
  codigoExterno: "",
  ativa: null,
  analitica: null,
  tipoDeLancamento: null,
  dataCadastro: new Date().toISOString(),
  dataUltimaAtualizacao: new Date().toISOString(),
  metas: [
    {
      ano: new Date().getFullYear(),
      percentual: null
    }
  ]
};
