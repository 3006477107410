import _ from "lodash";
import { Toast } from "primereact/toast";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  AddListaParcela,
  esconderLoading,
  exibirLoading,
  setFiltroParcela,
} from "../../actions";
import { deleteParcela, getAllFilterParcela, deletarArquivosParcela } from "../../api";
import { DetalhesListaPorLinha } from "../../components/DetalhesListaPorLinha";
import { FiltroParcela } from "../../components/FiltroParcela";
import { LoadingOverlayComponents } from "../../components/LoadingOverlayComponents";
import { ModalComponents } from "../../components/ModalComponents";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from "../../context/ReduxContext";
import { paginationDefault } from "../../default/pagination";
import { formatISO8601ToUser, transformDateToStartOfDay } from "../../utils";
import { formatValue } from "../../utils/value";
import { deletarArquivosMovimentacao } from "../../api";

export const Parcelas = (props) => {
  
  const pageRedux = useSelector(
    (state) => state.filterPage
  )
  const page = pageRedux?.page || props?.location?.state?.page;
  const dispatch = useDispatch();
  const toast = useRef(null);
  const movimentacaoParcelas = useSelector(
    (state) => state.grupoEconomico.listaParcelas
  );
  const id = useSelector((state) => state.filtroParcela.id);
  const idContrato = props ? props?.location?.state?.idContrato : null;
  const descricaoParcela = useSelector(
    (state) => state.filtroParcela.descricaoParcela
  );
  const numeroParcela = useSelector(
    (state) => state.filtroParcela.numeroParcela
  );
  const valorParcela = useSelector((state) => state.filtroParcela.valorParcela);
  const dataEmissao = useSelector((state) => state.filtroParcela.dataEmissao);
  const dataVencimentoPrevisto = useSelector(
    (state) => state.filtroParcela.dataVencimentoPrevisto
  );
  const dataBaixa = useSelector((state) => state.filtroParcela.dataBaixa);
  const valorBaixa = useSelector((state) => state.filtroParcela.valorBaixa);
  const statusParcela = useSelector(
    (state) => state.filtroParcela.statusParcela
  );
  const dataCriacao = useSelector((state) => state.filtroParcela.dataCriacao);
  const dataAlteracao = useSelector(
    (state) => state.filtroParcela.dataAlteracao
  );

  const [abrirFiltroParcela, setAbrirFiltroParcela] = useState(false);
  const history = useHistory();
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [dialogExcluirParcela, setDialogExcluirParcela] = useState(false);
  const [dialogDescricao, serDialogDescricao] = useState(null);
  const [dialogId, setDialogId] = useState(null);
  const [movimentacaoId, setMovimentacaoId] = useState(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [optionSelected, setOptionSelected] = useState();
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);
  const [clearInputs, setClearInputs] = useState(false);

  useEffect(() => {
    if (page) {
      setLazyParams(page);
    }
  }, [props, page]);

  const reduxStateAtual = useSelector((state) => state.filtroParcela);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState("");
  

  async function clearFilter() {
    // (_.omit(reduxStateAtual,['status']), reduxStateInicial.filtroContrato))
    const filterActive = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroParcela);
    setVisible(!filterActive)
    if (filterActive === false) {
      setClear(true);
      dispatch(setFiltroParcela(reduxStateInicial.filtroParcela));
      // setVisible(false);
    } else {
      setClear(false);
    }
  }
 
  function functionExcluirParcela(rowData) {
    setOptionSelected(rowData);
    if (rowData.statusParcela === "CONCILIADO") {
      toast.current.show({
        severity: "info",
        summary: "Info",
        life: 5000,
        detail:
          "A parcela já foi conciliada na movimentação. Para qualquer alteração, desconciliar o lançamento ",
      });
    } else {
      setDialogExcluirParcela(true);
      setDialogId(rowData.id);
      setMovimentacaoId(rowData?.idMovimentacao)
      serDialogDescricao(rowData.descricaoParcela);
    }
  }

  function editarParcelaComId(rowData) {
    const propsParcela = {
      editParcela: rowData,
      idContrato: idContrato,
    };
    history.push(`/contrato/${rowData.contrato?.id}/parcela/${rowData.id}`, {
      page: lazyParams,
      parcela: propsParcela,
    });
  }

  const montarFiltro = useCallback(() => {
    const filtroParcela = {
      id,
      idContrato,
      descricaoParcela,
      numeroParcela,
      valorParcela,
      dataEmissao: dataEmissao && transformDateToStartOfDay(dataEmissao),
      dataVencimentoPrevisto: dataVencimentoPrevisto && transformDateToStartOfDay(dataVencimentoPrevisto),
      dataBaixa,
      valorBaixa,
      statusParcela,
      dataCriacao,
      dataAlteracao,
    };
    return filtroParcela;
  }, [
    id,
    idContrato,
    descricaoParcela,
    numeroParcela,
    valorParcela,
    dataEmissao,
    dataVencimentoPrevisto,
    dataBaixa,
    valorBaixa,
    statusParcela,
    dataCriacao,
    dataAlteracao,
  ]);

  const filtroListaParcela = useCallback(async() => {
    dispatch(exibirLoading());
    const requestParams = {
      params: montarFiltro(),
      paginationParams: lazyParams,
    };
    await getAllFilterParcela(requestParams)
      .then((resultado) => {
        if (resultado.length === 0) {
          toast.current.show({
            severity: "info",
            summary: "Info",
            detail: "O filtro não obteve resultado",
          });
        }
        dispatch(AddListaParcela(resultado.content));
        setTotalRecords(resultado.totalElements);
      })
      .catch(() => {
        dispatch(AddListaParcela([]));
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail:
            "Houve um erro inesperado, não foi possível filtrar a parcela.",
        });
      });
    dispatch(esconderLoading());
    setAbrirFiltroParcela(false);
  }, [dispatch, lazyParams, montarFiltro]);

  useEffect(() => {
    const buttonClearFilter = _.isEqual(reduxStateAtual, reduxStateInicial?.filtroParcela);
    setVisible(!buttonClearFilter)
    if (props?.location?.state) {
      filtroListaParcela();
    }
  }, [lazyParams, montarFiltro, filtroListaParcela, props, reduxStateAtual, reduxStateInicial]);

  const deleteParcelaFunction = async (rowData, movimentacaoId) => {
    setCarregandoRequisicao(true);
    try {
      await deleteParcela(rowData).then(() => {
        dispatch(setFiltroParcela(reduxStateInicial.filtroParcela))
        setClearInputs(true)
      });
      await deletarArquivosParcelaMovimentacao(rowData, movimentacaoId)
      setDialogExcluirParcela(false);
      setTimeout(() => {
        window.location.reload();
      }, 2500);
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Parcela excluída com sucesso!",
        life: 5000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: error?.response?.data?.detail,
      });
    }
    setCarregandoRequisicao(false);
  };

  const deletarArquivosParcelaMovimentacao = async (id, movimentacaoId) => {
    await deletarArquivosParcela(id);
    if(movimentacaoId !== undefined && movimentacaoId !== null) {
      await deletarArquivosMovimentacao(movimentacaoId);
    }
  }

  const columns = [
    {
      field: "id",
      header: "Código",
      style: { maxWidth: "6vw" }
    },
    {
      field: "contrato.nomeContrato",
      header: "Contrato",
      style: { maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "descricaoParcela",
      header: "Descrição da Parcela",
      style: { maxWidth: "15vw" },
      type: "customEllipsis",
    },
    {
      field: "numeroParcela",
      header: "Parcela",
      style: { maxWidth: "6vw" },
    },
    {
      field: "valorParcela",
      header: "Valor",
      style: { maxWidth: "9vw" },
      type: "customRight",
      customFunc: (e) => formatValue(e?.valorParcela ? e?.valorParcela : 0),
    },
    {
      field: "dataEmissao",
      header: "Emissão",
      style: { maxWidth: "7.5vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataEmissao),
    },
    {
      field: "dataVencimentoPrevisto",
      header: "Vencimento",
      style: { maxWidth: "7.5vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataVencimentoPrevisto),
    },
    {
      field: "dataBaixa",
      header: "Baixa",
      style: { maxWidth: "7.5vw" },
      type: "custom",
      customFunc: (e) => formatISO8601ToUser(e?.dataBaixa),
    },
    {
      field: "valorBaixa",
      header: "Valor da Baixa",
      style: { maxWidth: "9vw" },
      type: "customRight",
      customFunc: (e) => formatValue(e?.valorBaixa ? e?.valorBaixa : 0),
    },
    {
      field: "statusParcela",
      header: "Status",
      style: { maxWidth: "7vw" },
    },
  ];

  const modalExcluir = [
    { label: "Código:", value: dialogId },
    { label: "Descrição:", value: dialogDescricao },
  ];

  function titleModal() {
    if (
      optionSelected?.statusParcela === "CONCILIADO" ||
      optionSelected?.statusParcela === "PROVISIONADO"
    ) {
      return "Ao excluir a parcela, a movimentação vinculada a ela também será excluída.  Deseja confirmar a exclusão da parcela e da movimentação?";
    } else {
      return "Deseja confirmar a exclusão da parcela?";
    }
  }

  function validateContract() {
    if (movimentacaoParcelas[0]?.contrato?.status !== "FECHADO") {
      history.push(
        `/contrato/${props?.location?.state?.idContrato}/parcela/cadastro`,
        {
          page: lazyParams,
          idContrato: props?.location?.state?.idContrato,
        }
      );
    }
    toast.current.show({
      severity: "info",
      summary: "Info",
      life: 5000,
      detail:
        "Não é posssível inserir nova parcela porque o contrato está fechado",
    });
  }
  const [exibirDetalhesLinha, setExibirDetalhesLinha] = useState(false);
  const [conteudoPorLinha, setConteudoPorLinha] = useState(null);
 

  const selecionarDetalhesPorLinha = (rowData) => {
    if (
      rowData.statusParcela !== "EM ABERTO" ||
      rowData.statusParcela !== "ABERTO"
    ) {
      setExibirDetalhesLinha(true);
      setConteudoPorLinha(rowData);
    }
  };

  function functionProvParcela(rowData) {
    if (rowData?.idMovimentacao) {
      history.push(
        `/contrato/${idContrato}/parcela/${rowData?.id}/movimentacao/${rowData?.idMovimentacao}`,
        { page: lazyParams, data: rowData }
      );

    } else {
      history.push(
        `/contrato/${idContrato}/parcela/${rowData?.id}/movimentacao/cadastro`,
        { page: lazyParams, data: rowData }
      );
    }
  }

  function handleNavigateToFiles(rowData){
    history.push(`/parcela/${rowData.id}/anexos`, { 
      id: rowData.id, type: "parcela" 
    })

  }

  return (
    <>
      {props?.location?.state && (
        <>
          <LoadingOverlayComponents
            active={carregandoRequisicao}
            spinner
            text="Carregando..."
          >
            <Toast ref={toast} />
            <DetalhesListaPorLinha
              setExibirDetalhesLinha={setExibirDetalhesLinha}
              exibirDetalhesLinha={exibirDetalhesLinha}
              conteudoPorLinha={conteudoPorLinha}
            />
            <ReactHintComponents />
            <ModalComponents
              title={titleModal()}
              visible={dialogExcluirParcela}
              onHide={() => setDialogExcluirParcela(false)}
              descricao={modalExcluir}
              onClick="custom"
              datatesteid="buttonCancelar"
              datatesteidconfirm="buttonConfirmar"
              onClickConfirmar={() => deleteParcelaFunction(dialogId, movimentacaoId)}
              onClickCancelar={() => setDialogExcluirParcela(false)}
            />
            <Table
              arrayData={movimentacaoParcelas}
              loading={recarregando}
              headerTitle="Parcelas"
              columns={columns}
              onClickFiltrar={() => setAbrirFiltroParcela(true)}
              onClickAdicionar={() => validateContract()}
              onClickEdit={(rowData) => editarParcelaComId(rowData)}
              tooltipEdit="Parcela"
              tooltipDetails="Parcela sem movimentação vinculada"
              messageButton="Movimentação"
              onClickPortions={(rowData) => handleNavigateToFiles(rowData)}
              onClickVoltarCustom
              path={"/contratos"}
              data={{ type: "contrato" }}
              page={props?.location?.state?.pageContract}
              onClickDetails={(e) => selecionarDetalhesPorLinha(e)}
              validateStatus={true}
              onClickDelete={(rowData) => functionExcluirParcela(rowData)}
              onClickProvParcela={(rowData) => functionProvParcela(rowData)}
              editMov={(rowData) => !!rowData?.idMovimentacao}
              lazyParams={lazyParams}
              setLazyParams={setLazyParams}
              totalRecords={totalRecords}
              onClickClear={() => clearFilter()}
              visible={visible}
            />
            <FiltroParcela
              clear={clear}
              clearInputs={clearInputs}
              setClearInputs={setClearInputs}
              setVisible={setVisible}
              setAbrirFiltroParcela={setAbrirFiltroParcela}
              abrirFiltroParcela={abrirFiltroParcela}
              setLazyParamsProp={setLazyParams}
            />
          </LoadingOverlayComponents>
        </>
      )}
    </>
  );
};
