export const setRateado = (rateado) => ({
    type: "SET_RATEADO",
    payload: rateado,
});

export const addListaRateio = (listaRateio) => ({
    type: "ADD_LISTA_RATEIO",
    payload: listaRateio,
});

export const deletarRateio = (deletarRateio) => ({
    type: "DELETAR_RATEIO",
    payload: deletarRateio,
});
