const INITIAL_STATE = {
  id: null,
  nome: null,
  descricao: null,
  produtoPai: {
    descricao: null,
  },
  status: null,
};

//verificar se a parte de baixo esta faltando algum objeto.
export const filtroProd = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_PRODUTO_SERVICO":
      return { ...action.payload };
    case "SET_ID":
      return { ...state, id: action.payload };
    case "SET_NOME":
      return { ...state, nome: action.payload };
    case "SET_DESCRICAO":
      return { ...state, descricao: action.payload };
    case "SET_STATUS":
      return { ...state, status: action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_QTD_REGISTRO":
      return { ...state, qtdRegistros: action.payload };
    default:
      return state;
  }
};
