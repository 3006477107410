import { yupResolver } from "@hookform/resolvers/yup";
import { addLocale } from "primereact/api";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import * as yup from "yup";
import {
  setFiltroConci,
  setFiltroDescricaoParcelaContratoDrop,
  setFiltroMov,
  setPage
} from "../../actions";
import {
  deleteMovimentacao,
  getByIdContrato,
  getByIdMovimentacao,
  getContaCorrenteByIdEmpresa,
  saveMovimentacao,
  updateMovimentacao,
  updateParcela,
  verificaFinalDeSemanaFeriado,
  getEmpresaResumo,
  getAllParceiros,
  getListaNaturezaHierarquica
} from "../../api";
import ReduxContext from "../../context/ReduxContext";
import { messageRequired, messageRequiredMaximumCharacters } from "../../default/messages";
import { brazilianCalendarParams, convertToISO8601, dateFormattedForInput } from "../../utils";
import { AutoCompleteComponent } from "../AutoComplete";
import { ButtonComponent } from "../ButtonComponent";
import { CurrencyInput } from "../FormInputs/CurrencyInput";
import { Header } from "../Header";
import { InfoIcon } from "../InfoIcon";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import "./styles.css";

export const RegistrarEditarLista = ({
  idMovimentacao,
  setAbrirRateio,
  setMovimentacao,
  getCentroDeCusto,
  getListaParcelas,
  page
}) => {
  const location = useLocation();
  const linkData = location?.state?.data ? location?.state?.data : null;

  let history = useHistory();
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  const toast = useRef(null);
  addLocale("pt-br", brazilianCalendarParams);
  const [numeroParcelas, setNumeroParcelas] = useState(1);
  const [repetirLancamento, setRepetirLancamento] = useState(false);
  const [diaUtil, setDiaUtil] = useState(false);
  const [dataBaixa, setDataBaixa] = useState();
  const [dataCompetencia, setDataCompetencia] = useState();
  const [dataContrato, setDataContrato] = useState();
  const [dataEmissao, setDataEmissao] = useState();
  const [dataPrevistaEmissao, setDataPrevistaEmissao] = useState();
  const [dataVencimento, setDataVencimento] = useState();
  const [dataVencimentoPlanejamento, setDataVencimentoPlanejamento] = useState();
  const [historico, setHistorico] = useState(null);
  const [idContaCorrente, setIdContaCorrente] = useState(null);
  const [idEmpresa, setIdEmpresa] = useState(null);

  const [idNatureza, setIdNatureza] = useState(null);
  const [idParceiro, setIdParceiro] = useState(null);
  const [idParcela, setIdParcela] = useState(null);
  const [numeroCheque, setNumeroCheque] = useState(null);
  const [numeroNota, setNumeroNota] = useState(null);
  const [centroCusto, setCentroCusto] = useState(null);
  const [codProjetoErpOrigem, setCodProjetoErpOrigem] = useState(null);
  const [provisao, setProvisao] = useState("N");

  const [serieNota, setSerieNota] = useState("");
  const [tipoLancamento, setTipoLancamento] = useState(null);

  const [dialogDelete, setDialogDelete] = useState(false);
  const [formaPagamento, setFormaPagamento] = useState(null);

  const [carregandoRequisicao, setCarregandoRequisicao] = useState(true);

  const [valorBruto, setValorBruto] = useState();
  const [desconto, setDesconto] = useState();
  const [totalLiquido, setTotalLiquido] = useState();
  const [juros, setJuros] = useState();
  const [multa, setMulta] = useState();
  const [valorBaixa, setValorBaixa] = useState();
  const [replanejado, setReplanejado] = useState();
  const [rateado, setRateado] = useState();
  const [salvarEditarLista, setSalvarEditarLista] = useState(null);
  const [contrato, setContrato] = useState(null);
  const [listaContasCorrentes, setListaContasCorrentes] = useState([]);

  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaParceiros, setListaParceiros] = useState([]);
  const [listaNatureza, setListaNatureza] = useState([]);


  useEffect(() => {
    async function fetchEmpresas() {
      const empresas = await getEmpresaResumo();
      setListaEmpresas(empresas);  // Armazena a lista de empresas

      if (salvarEditarLista && salvarEditarLista.empresa) {
        const empresaSelecionada = empresas.find(empresa => empresa.id === salvarEditarLista.empresa.id);
        if (empresaSelecionada) {
          setIdEmpresa(empresaSelecionada);  // Define a empresa com base na movimentação
        }
      } else if (contrato && contrato.empresa) {
        const empresaSelecionada = empresas.find(empresa => empresa.id === contrato.empresa.id);
        if (empresaSelecionada) {
          setIdEmpresa(empresaSelecionada);  // Define a empresa com base no contrato
        }
      }
    }

    fetchEmpresas();
  }, [salvarEditarLista, contrato]);



  useEffect(() => {
    async function fetchParceiros() {

      const parceiros = await getAllParceiros();
      setListaParceiros(parceiros);
      if (salvarEditarLista && salvarEditarLista.parceiro) {
        const parceiroSelecionado = parceiros.find(
          (parceiro) => parceiro.id === salvarEditarLista.parceiro.id
        );
        setIdParceiro(parceiroSelecionado);
      }

    }

    fetchParceiros();
  }, [salvarEditarLista]);

  useEffect(() => {

    async function fetchNatureza() {
      setCarregandoRequisicao(true);

      try {
        const naturezas = await getListaNaturezaHierarquica();
        setListaNatureza(naturezas);

        const selectedNatureza = salvarEditarLista?.natureza
          ? naturezas.find(nat => nat.idNatureza === salvarEditarLista.natureza.id)
          : contrato?.natureza
            ? naturezas.find(nat => nat.idNatureza === contrato.natureza.id)
            : null;

        if (selectedNatureza) {
          setIdNatureza(selectedNatureza.idNatureza);
        }
      } catch (error) {

        console.error("Erro ao buscar naturezas:", error);

      } finally {
        setCarregandoRequisicao(false);
      }
    }

    fetchNatureza();

  }, [salvarEditarLista, contrato]);


  async function verificaData(data) {
    const dataFormatada = data.toLocaleDateString("pt-BR");
    const response = await verificaFinalDeSemanaFeriado(dataFormatada);
    if (response) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: `A data ${dataFormatada} não é um dia útil.`,
        life: 5000
      });
    }
  }


  const encontrarOpcaoPreSelecionada = useCallback(() => {
    // Só predefine uma conta corrente se não houver uma já selecionada
    if (!idContaCorrente || !idContaCorrente.id) {

      const opcaoPreSelecionada = listaContasCorrentes?.find((conta) =>
        conta?.principal?.includes("S")
      );
      if (opcaoPreSelecionada) {
        setIdContaCorrente(opcaoPreSelecionada);
      }
    }
  }, [listaContasCorrentes, idContaCorrente, setIdContaCorrente]);

  useEffect(() => {
    // Se já existir uma conta corrente selecionada, exibe no Dropdown
    if (idContaCorrente && listaContasCorrentes.length > 0) {
      const contaExistente = listaContasCorrentes.find(conta => conta.id === idContaCorrente.id);
      if (contaExistente) {
        setIdContaCorrente(contaExistente);
      }
    }
  }, [idContaCorrente, listaContasCorrentes]);


  const getContaByEmpresa = useCallback(async () => {
    try {
      if (idEmpresa?.id) {
        const resultado = await getContaCorrenteByIdEmpresa(idEmpresa.id);
        setListaContasCorrentes(resultado);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`
      });
    }
  }, [idEmpresa]);


  const getContratoById = useCallback(async () => {
    try {
      if (linkData) {
        await getByIdContrato(linkData?.contrato?.id).then((resultado) => {
          setContrato(resultado);
        });
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`
      });
    }
  }, [linkData, setContrato]);


  const retornaValorAoEditarMovimentacao = useCallback(async () => {
    try {
      if (idMovimentacao !== undefined) {
        await getByIdMovimentacao(idMovimentacao).then((resultado) => {
          setSalvarEditarLista(resultado);
        });
      } else if (linkData?.idMovimentacao) {
        await getByIdMovimentacao(linkData?.idMovimentacao).then((resultado) => {
          setSalvarEditarLista(resultado);
        });
      } else {
        setSalvarEditarLista(undefined);
      }
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        life: 5000,
        detail: `${error.response?.data?.userMessage}`
      });
    }
  }, [linkData, idMovimentacao]);


  useEffect(() => {
    if (salvarEditarLista === undefined) {
      setCarregandoRequisicao(false);
    }
  }, [salvarEditarLista]);



  useEffect(() => {
    getContratoById();
    retornaValorAoEditarMovimentacao();
  }, [retornaValorAoEditarMovimentacao, getContratoById]);


  const empresaId = idEmpresa?.id;

  useEffect(() => {
    getContaByEmpresa();
  }, [getContaByEmpresa, empresaId]);

  useEffect(() => {
    encontrarOpcaoPreSelecionada();
  }, [listaContasCorrentes, encontrarOpcaoPreSelecionada]);

  const validationPost = yup
    .object({
      tipoLancamento: yup.string().required(messageRequired),
      idEmpresa: yup.string().required(messageRequired).typeError(""),
      idParceiro: yup.string().required(messageRequired).typeError(""),
      formaPagamento: yup.string().nullable().required(messageRequired).typeError(messageRequired),
      dataVencimento: yup.string().required(messageRequired),
      dataVencimentoPlanejamento: yup.string().required(messageRequired),
      dataCompetencia: yup.string().required(messageRequired),
      dataNegociacao: yup.string().required(messageRequired),
      historico: yup.string().required(messageRequired).trim(),
      numParcelas: yup.string().required(messageRequired),
      valorBruto: yup
        .string()
        .max(12, messageRequiredMaximumCharacters("O Valor Bruto", 14))
        .required(messageRequired),
      idContaCorrente: yup.string().required(messageRequired),
      idNatureza: yup.string().required(messageRequired).typeError(""),
      valorBaixa:
        dataBaixa !== undefined &&
          dataBaixa !== null &&
          (valorBaixa === undefined || valorBaixa === null)
          ? yup.number().required(messageRequired)
          : null,
      dataBaixa:
        valorBaixa !== undefined &&
          valorBaixa !== null &&
          (dataBaixa === undefined || dataBaixa === null)
          ? yup.number().required(messageRequired)
          : null
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationPost)
  });

  function abrirLinhaComId() {
    setAbrirRateio(true);
    setMovimentacao(salvarEditarLista ? salvarEditarLista : null);
  }

  const optionsFormaPagamento = [
    { name: "CHEQUE", value: "CHEQUE" },
    { name: "DINHEIRO", value: "DINHEIRO" },
    { name: "TRANSFERÊNCIA", value: "TRANSFERÊNCIA" },
    { name: "PIX", value: "PIX" },
    { name: "DOC", value: "DOC" },
    { name: "TED", value: "TED" },
    { name: "CHEQUE AO PORTADOR", value: "CHEQUE AO PORTADOR" },
    { name: "CARTÃO DE CRÉDITO", value: "CARTÃO DE CRÉDITO" },
    { name: "CARTÃO DE DÉBITO", value: "CARTÃO DE DÉBITO" },
    { name: "CARTEIRA OU EM MÃOS", value: "CARTEIRA OU EM MÃOS" },
    { name: "BOLETO", value: "BOLETO" }
  ];

  const selectTipoLancamento = [
    { name: "Receita", value: "RECEITA" },
    { name: "Despesa", value: "DESPESA" }
  ];

  const selectRepetirLançamento = [
    { name: "Sim", value: true },
    { name: "Não", value: false }
  ];

  const selectDiaUtilComportamento = [
    { name: "Dia útil anterior", value: true },
    { name: "Dia útil posterior", value: false }
  ];

  const selectProvisao = [
    { name: "Sim", value: "S" },
    { name: "Não", value: "N" }
  ];

  useEffect(() => {
    function vlrLiqDinamicoBruto() {
      const valorBrutoValido = valorBruto !== null && valorBruto !== undefined ? valorBruto : 0;
      const jurosValido = juros !== null && juros !== undefined ? juros : 0;
      const multaValida = multa !== null && multa !== undefined ? multa : 0;
      const descontoValido = desconto !== null && desconto !== undefined ? desconto : 0;

      const somaValores = valorBrutoValido + jurosValido + multaValida;
      setTotalLiquido(somaValores - descontoValido);
    }

    vlrLiqDinamicoBruto();
  }, [valorBruto, juros, multa, desconto]);

  useEffect(() => {
    if (isNaN(totalLiquido) && isNaN(juros) && isNaN(multa)) {
      return;
    }
  }, [juros, multa, totalLiquido]);

  useEffect(() => {
    if (linkData && contrato) {
      setIdParceiro(contrato?.parceiro);
      setIdNatureza(contrato?.natureza);
      if (contrato?.tipoLancamento === "D") {
        setTipoLancamento("DESPESA");
      } else if (contrato?.tipoLancamento === "C") {
        setTipoLancamento("RECEITA");
      } else {
        setTipoLancamento(contrato?.tipoLancamento);
      }
      setIdEmpresa(contrato?.empresa);
      setIdContaCorrente(contrato?.contaCorrente);
      setValorBruto(linkData?.valorParcela);
      setValorBaixa(linkData?.valorBaixa);

      setDataVencimento(
        dateFormattedForInput(linkData?.dataVencimentoPrevisto)
      );
      const vencimentoPlanejado = linkData?.vencimentoPlanejado
        ? dateFormattedForInput(linkData.vencimentoPlanejado)
        : linkData?.dataVencimentoPrevisto
          ? dateFormattedForInput(linkData.dataVencimentoPrevisto)
          : null;

      setDataVencimentoPlanejamento(vencimentoPlanejado);


      setHistorico(linkData?.descricaoParcela);
      setDataPrevistaEmissao(dateFormattedForInput(linkData?.dataEmissao));
      setDataBaixa(dateFormattedForInput(linkData?.dataBaixa));
      setDataContrato(dateFormattedForInput(contrato?.dataInicio));
      setDataCompetencia(dateFormattedForInput(contrato?.dataInicio));


      setValue("dataVencimentoPlanejamento", vencimentoPlanejado);
      setValue("tipoLancamento", contrato?.tipoLancamento);
      setValue("dataVencimento", linkData?.dataVencimentoPrevisto);
      setValue("valorBruto", linkData?.valorParcela);
      setValue("historico", linkData?.descricaoParcela);
      setValue("dataNegociacao", contrato?.dataInicio);
      setValue("dataCompetencia", contrato?.dataInicio);
    }
  }, [linkData, contrato, setValue]);

  const parcelaObjPut = {
    dataBaixa: dataBaixa ? convertToISO8601(dataBaixa) : null,
    dataEmissao: dataPrevistaEmissao ? convertToISO8601(dataPrevistaEmissao) : null,
    dataVencimentoPrevisto: linkData?.dataVencimentoPrevisto,
    descricaoParcela: linkData?.descricaoParcela,
    idContrato: contrato?.id,
    numeroParcela: linkData?.numeroParcela,
    statusParcela: "PROVISIONADO",
    valorBaixa: linkData?.valorBaixa,
    valorParcela: linkData?.valorParcela
  };

  useEffect(() => {
    if (salvarEditarLista) {
      salvarEditarLista?.baixa && setDataBaixa(dateFormattedForInput(salvarEditarLista.baixa));
      setDataCompetencia(dateFormattedForInput(salvarEditarLista?.competencia));
      setDataContrato(dateFormattedForInput(salvarEditarLista?.negociacao));
      salvarEditarLista?.emissao &&
        setDataEmissao(dateFormattedForInput(salvarEditarLista.emissao));
      salvarEditarLista?.prazo &&
        setDataPrevistaEmissao(dateFormattedForInput(salvarEditarLista.prazo));
      setDataVencimento(
        dateFormattedForInput(salvarEditarLista?.vencimentoOriginal)
      );
      setDataVencimentoPlanejamento(
        salvarEditarLista.vencimentoPlanejado
          ? dateFormattedForInput(salvarEditarLista.vencimentoPlanejado)
          : dateFormattedForInput(salvarEditarLista?.vencimentoOriginal)
      );
      setDesconto(salvarEditarLista.desconto);
      setHistorico(salvarEditarLista.historico);
      setIdContaCorrente(salvarEditarLista?.contaCorrente);
      setIdEmpresa(salvarEditarLista?.empresa);
      setIdNatureza(salvarEditarLista.natureza);
      setIdParcela(salvarEditarLista?.parcelaContrato);
      setIdParceiro(salvarEditarLista?.parceiro);
      setJuros(salvarEditarLista.juros);
      setMulta(salvarEditarLista.multa);
      setSerieNota(salvarEditarLista.serieNota);
      setTipoLancamento(salvarEditarLista.tipoLancamento);
      setTotalLiquido(salvarEditarLista.valorLiquido);
      setValorBaixa(salvarEditarLista.valorBaixa);
      setValorBruto(salvarEditarLista.desdobramento);
      setFormaPagamento(salvarEditarLista.formaPagamento);
      setNumeroNota(salvarEditarLista.notaFiscalOuRDD);
      setCentroCusto(salvarEditarLista.centroCusto);
      setRepetirLancamento(salvarEditarLista.repetirLancamento);
      setCodProjetoErpOrigem(salvarEditarLista.codProjetoErpOrigem);
      setNumeroParcelas(salvarEditarLista.numeroParcelas ?? 1);
      setReplanejado(salvarEditarLista.replanejado);
      setRateado(salvarEditarLista.rateado);
      setProvisao(salvarEditarLista?.provisao ?? "N");

      setValue("dataVencimentoPlanejamento", salvarEditarLista.vencimentoPlanejado);
      setValue("tipoLancamento", salvarEditarLista.tipoLancamento);
      setValue("dataVencimento", salvarEditarLista.vencimentoOriginal);
      setValue("valorBruto", salvarEditarLista.desdobramento);
      setValue("dataCompetencia", salvarEditarLista.competencia);
      setValue("dataNegociacao", salvarEditarLista.negociacao);
      setValue("historico", salvarEditarLista.historico);
      setValue("idNatureza", salvarEditarLista.natureza);
    }
  }, [salvarEditarLista, setValue]);

  useEffect(() => {
    setValue("numParcelas", numeroParcelas);
    setValue("valorBruto", valorBruto);
  }, [numeroParcelas, valorBruto, setValue]);

  useEffect(() => {
    setValue("idEmpresa", idEmpresa?.id);
    setValue("idContaCorrente", idContaCorrente?.id);
    setValue("idParceiro", idParceiro?.id);
    setValue("formaPagamento", formaPagamento);
    setValue("idNatureza", idNatureza);
    setValue("valorBruto", valorBruto);
    setValue("idParcela", idParcela?.id);
  }, [
    idEmpresa,
    idContaCorrente,
    idParceiro,
    formaPagamento,
    idNatureza,
    valorBruto,
    idParcela,
    setValue
  ]);

  const contas = {
    frequencia: parseInt(numeroParcelas),
    tipoLancamento: tipoLancamento,
    desdobramento: valorBruto,
    desconto: desconto === null || desconto === undefined ? 0 : desconto,
    valorLiquido: totalLiquido,
    juros: juros === null || juros === undefined ? 0 : juros,
    valorBaixa: valorBaixa,
    notaFiscalOuRDD: numeroNota,
    serieNota: serieNota,
    empresa: {
      id: idEmpresa?.id
    },
    parceiro: {
      id: idParceiro?.id
    },
    natureza: {
      id: idNatureza,
    },
    contaCorrente: {
      id: idContaCorrente?.id ?? null
    },
    centroCusto: {
      id: centroCusto?.id ?? null
    },
    vencimentoPlanejado: dataVencimentoPlanejamento
      ? convertToISO8601(dataVencimentoPlanejamento)
      : convertToISO8601(dataVencimento),
    // vencimentoPlanejado: convertToISO8601(dataVencimentoPlanejamento),
    vencimentoOriginal: convertToISO8601(dataVencimento),
    prazo: dataPrevistaEmissao ? convertToISO8601(dataPrevistaEmissao) : null,
    emissao: dataEmissao ? convertToISO8601(dataEmissao) : null,
    baixa: dataBaixa ? convertToISO8601(dataBaixa) : null,
    competencia: convertToISO8601(dataCompetencia),
    negociacao: convertToISO8601(dataContrato),
    parcelaContrato: {
      id: linkData?.id
    },
    formaPagamento: formaPagamento,
    historico: historico,
    repetirLancamento: repetirLancamento,
    numeroParcelas: linkData ? 1 : parseInt(numeroParcelas),
    codProjetoErpOrigem: codProjetoErpOrigem,
    replanejado: replanejado,
    multa: multa === null || multa === undefined ? 0 : multa,
    rateado: rateado,
    provisao: provisao,
    idUsuarioConciliado: 1,
    idUsuarioDesconciliado: 1,
    diaUtil: diaUtil,
    numeroCheque: numeroCheque
  };

  async function salvar() {
    setCarregandoRequisicao(true);
    try {
      if (salvarEditarLista) {
        await updateMovimentacao(salvarEditarLista?.id, contas);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Movimentação alterada com sucesso!"
        });
        updateRedux();
        setTimeout(() => {
          history.goBack();
        }, 1500);
      } else if (linkData?.idMovimentacao) {
        await updateMovimentacao(linkData?.idMovimentacao, contas);
        await updateParcela(parcelaObjPut, linkData?.id);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Movimentação alterada com sucesso e Parcela alterada com sucesso!"
        });
        updateRedux();
        setTimeout(() => {
          history.goBack();
        }, 1500);
      } else if (linkData) {
        await saveMovimentacao(contas);
        await updateParcela(parcelaObjPut, linkData?.id);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Movimentação criada com sucesso e Parcela alterada com sucesso!"
        });
        updateRedux();
        setTimeout(() => {
          history.goBack();
        }, 1500);
      } else {
        await saveMovimentacao(contas);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: `${contas.repetirLancamento === true
            ? "Movimentações criadas com sucesso!"
            : "Movimentação salva com sucesso!"
            }`,
        });
        updateRedux();
        setTimeout(() => {
          history.push("/movimentacoes");
        }, 1500);
      }
    } catch (e) {
      const mensagem = e?.response?.data?.userMessage;
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: `${mensagem}`
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  function titleMov() {
    if (linkData?.statusParcela === "EM ABERTO" || linkData?.statusParcela === "ABERTO") {
      return (
        <span className="unreconciled">
          {" "}
          [PROVISIONAR]
          <Button
            className="hintConciliation"
            icon="pi pi-question-circle"
            tooltip="Provisionar parcela"
          />
        </span>
      );
    }
    if (salvarEditarLista) {
      if (salvarEditarLista?.dataConciliado !== null) {
        return (
          <span className="reconciled">
            {" "}
            [CONCILIADO]
            <Button
              className="hintConciliation"
              icon="pi pi-question-circle"
              tooltip="Movimentação Conciliada"
            />
          </span>
        );
      } else if (salvarEditarLista?.parcelaContrato?.contrato?.id !== undefined) {
        return (
          <span className="unreconciled">
            {" "}
            [CONTRATO]
            <Button
              className="hintConciliation"
              icon="pi pi-question-circle"
              tooltip="Movimentação originada de um Contrato"
            />
          </span>
        );
      }
    }
  }

  async function handleDelete() {
    setCarregandoRequisicao(true);
    try {
      await deleteMovimentacao(idMovimentacao);
      setDialogDelete(false);
      dispatch(setFiltroMov(reduxStateInicial.filtroMov));
      history.push("/movimentacoes");
    } catch (err) {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Falha ao deletar movimentação"
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  const deleteButton = (
    <div className="naturezaContainer__icon">
      <Button
        className="naturezaContainer__icon--trash"
        icon="pi pi-trash"
        tooltip="Excluir Movimentação Financeira"
        tooltipOptions={{ position: "left" }}
        data-testid="buttonExcluirID"
        onClick={() => setDialogDelete(true)}
      />
    </div>
  );

  function handleSelectChange({ onChangeValue, setState }) {
    onChangeValue !== null && setState(onChangeValue);
  }

  function handleRepetirLancamentoChange(onChangeValue) {
    onChangeValue !== null && setRepetirLancamento(onChangeValue);
    onChangeValue === false && setNumeroParcelas(1);
  }

  const handleMessage = () => {
    if (valorBaixa !== totalLiquido) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: "Valor da Baixa está divergente do Total Líquido"
      });
    }
  };

  useEffect(() => {
    dispatch(
      setPage({
        page: undefined
      })
    );
  }, [dispatch]);

  async function updateRedux() {
    dispatch(setFiltroMov(reduxStateInicial.filtroMov));
    dispatch(setFiltroConci(reduxStateInicial.filtroConci));
  }

  async function setPageInRedux() {
    dispatch(
      setPage({
        page: page
      })
    );
    updateRedux();
    history.goBack();
  }

  useEffect(() => {
    if (dataBaixa) {
      setDataVencimentoPlanejamento(dataBaixa);
      setValue("dataVencimentoPlanejamento", dataBaixa);
    }
  }, [dataBaixa, setValue]);

  async function teste1() {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: "Quando Data de Baixa for cadastrada a Data de Vencimento Prorrogado irá recebê la",
      life: 5000
    });
  }

  return (
    <LoadingOverlayComponents active={carregandoRequisicao} spinner text="Carregando...">
      <div>
        <Toast ref={toast} />
        <Header
          title="Movimentação Financeira"
          onClick={salvarEditarLista || linkData?.idMovimentacao ? "edit" : "add"}
          titleItem={(salvarEditarLista || linkData) && titleMov()}
          rightButton={salvarEditarLista?.dataConciliado === null && idMovimentacao && deleteButton}
        />
        <ModalComponents
          onClick="delete"
          visible={dialogDelete}
          onHide={() => setDialogDelete(false)}
          title="essa movimentação"
          editOrRegister={salvarEditarLista}
          onClickCancelar={() => setDialogDelete(false)}
          onClickConfirmar={handleDelete}
        />
        <div className="registrarEditarLista">
          <form
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <div className="registrarEditarLista__flex">
              <div className="registrarEditarLista__flex__left">
                <div className="registrarEditarLista__flex__left--select">
                  <div
                    style={
                      salvarEditarLista?.parcelaContrato || linkData
                        ? {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%"
                        }
                        : null
                    }
                  >
                    <label>
                      Tipo de Lançamento
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    {(salvarEditarLista?.parcelaContrato || linkData) && (
                      <InfoIcon tooltipText="As alterações devem ser feitas através do contrato" />
                    )}
                  </div>
                  <SelectButton
                    {...register("tipoLancamento")}
                    style={{ width: "100%" }}
                    className="filter-select"
                    options={selectTipoLancamento}
                    value={tipoLancamento}
                    onChange={(e) => {
                      setTipoLancamento(e.value);
                      setValue("tipoLancamento", e.value);
                    }}
                    disabled={salvarEditarLista?.parcelaContrato || linkData}
                    optionLabel="name"
                    optionValue="value"
                    data-testid="tipoLancamentoTest"
                  />
                  <small className="form__errorMsg">{errors.tipoLancamento?.message}</small>
                </div>
                <div className="registrarEditarLista__flex__left--section">
                  <div className="registrarEditarLista__flex__left--section--align">
                    <div
                      style={
                        linkData || salvarEditarLista?.parcelaContrato
                          ? {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%"
                          }
                          : null
                      }
                    >
                      <label htmlFor="empresa">
                        Empresa <abbr className="form__asterisk">*</abbr>
                      </label>
                      {(linkData || salvarEditarLista?.parcelaContrato) && (
                        <InfoIcon tooltipText="As alterações devem ser feitas através do contrato" />
                      )}
                    </div>
                    <Dropdown
                      value={idEmpresa}
                      onChange={(e) => setIdEmpresa(e.value)}
                      options={listaEmpresas}
                      filter={true}
                      optionLabel="nomeFantasia"
                      data-testid="empresaTest"
                      className={errors.idEmpresa ? 'p-invalid' : ''}
                      showClear
                      field="nomeFantasia"
                      filterBy="nomeFantasia"
                      disabled={salvarEditarLista?.parcelaContrato || linkData}
                    />

                  </div>
                  <div className="registrarEditarLista__flex__left--section--align">
                    <div style={{ width: "100%" }}>
                      <div
                        style={
                          salvarEditarLista?.parcelaContrato || linkData
                            ? {
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%"
                            }
                            : null
                        }
                      >
                        <label>
                          Conta Corrente <abbr className="form__asterisk">*</abbr>
                        </label>
                        {(salvarEditarLista?.parcelaContrato || linkData) && (
                          <InfoIcon tooltipText="As alterações devem ser feitas através do contrato" />
                        )}
                      </div>
                      <Dropdown
                        {...register("formaPagamento")}
                        style={{ width: "100%" }}
                        optionLabel="descricao"
                        value={idContaCorrente}
                        options={listaContasCorrentes}
                        disabled={!idEmpresa?.id || salvarEditarLista?.parcelaContrato || linkData}
                        onChange={(e) => {
                          setIdContaCorrente(e.value);
                        }}
                        field="descricao"
                        filter
                        showClear
                        filterBy="descricao"
                        panelStyle={{ width: "0px" }}
                        data-testid="contaCorrenteTest"
                      />
                      <small className="form__errorMsg">{errors.idContaCorrente?.message}</small>
                    </div>
                  </div>
                </div>
                <div className="registrarEditarLista__flex__left--section">
                  <div className="registrarEditarLista__flex__left--section--align">
                    <div
                      style={
                        salvarEditarLista?.parcelaContrato || linkData
                          ? {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%"
                          }
                          : null
                      }
                    >
                      <label>
                        Parceiro <abbr className="form__asterisk">*</abbr>
                      </label>
                      {(salvarEditarLista?.parcelaContrato || linkData) && (
                        <InfoIcon tooltipText="As alterações devem ser feitas através do contrato" />
                      )}
                    </div>
                    <Dropdown
                      value={idParceiro}
                      onChange={(e) => setIdParceiro(e.value)}
                      options={listaParceiros}
                      filter={true}
                      optionLabel="nomeTratamento"
                      data-testid="parceiroTest"
                      className={errors.idParceiro ? 'p-invalid' : ''}
                      showClear
                      disabled={salvarEditarLista?.parcelaContrato || linkData}
                    />
                  </div>

                  <div className="registrarEditarLista__flex__left--section--align">
                    <label htmlFor="formaPagamento">
                      Forma de Pagamento
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <Dropdown
                      {...register("formaPagamento")}
                      className="registrarEditarLista__inputs"
                      optionLabel="name"
                      value={formaPagamento}
                      options={optionsFormaPagamento}
                      onChange={(e) => {
                        setFormaPagamento(e.value);
                      }}
                      field="formaPagamento"
                      filter
                      showClear
                      filterBy="name"
                      panelStyle={{ width: "0px" }}
                      data-testid="formaPagamentoTest"
                    />
                    <small className="form__errorMsg">{errors.formaPagamento?.message}</small>
                  </div>
                </div>
                <div className="registrarEditarLista__flex__left--section">
                  <div className="registrarEditarLista__flex__left--section--align">
                    <label>Número do Cheque</label>
                    <InputText
                      disabled={formaPagamento !== "CHEQUE"}
                      value={numeroCheque}
                      onChange={(e) => {
                        setNumeroCheque(e.target.value);
                      }}
                      maxLength={20}
                    />
                  </div>
                </div>
                <div className="registrarEditarLista__flex__left--section">
                  <div className="registrarEditarLista__flex__left--section--align">
                    {idMovimentacao && (
                      <div>
                        <label>Parcela</label>
                        <AutoCompleteComponent
                          disabled
                          value={idParcela}
                          setValue={setIdParcela}
                          options={getListaParcelas}
                          filter={setFiltroDescricaoParcelaContratoDrop}
                          attribute="descricao"
                          optionLabel="descricaoParcela"
                          errorMessage={errors.idParcela?.message}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <hr className="lineSectionLeft"></hr>
                <div className="registrarEditarLista__flex__left--section--bottom">
                  <div className="registrarEditarLista__flex__left--section--bottom--align">
                    <label htmlFor="dataDeCompetencia">
                      Competência
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <Calendar
                      {...register("dataCompetencia")}
                      view="month"
                      dateFormat="mm/yy"
                      mask="99/99/9999"
                      name="dataDeCompetencia"
                      showIcon
                      className="registrarEditarLista__inputs"
                      value={dataCompetencia}
                      onChange={(e) => {
                        setDataCompetencia(e.value);
                        setValue("dataCompetencia", e.value);

                      }}
                      field="dataDeCompetencia"
                      locale="pt-br"
                      data-testid="dataCompetenciaTest"
                    />
                    <small className="form__errorMsg">{errors.dataCompetencia?.message}</small>
                  </div>
                  <div className="registrarEditarLista__flex__left--section--bottom--align">
                    <label htmlFor="vencimento">
                      Negociação
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <Calendar
                      {...register("dataNegociacao")}
                      mask="99/99/9999"
                      name="negociacao"
                      dateFormat={"dd/mm/yy"}
                      showIcon
                      className="registrarEditarLista__inputs"
                      value={dataContrato}
                      onChange={(e) => {
                        setDataContrato(e.value);
                        setValue("dataNegociacao", e.value);

                      }}
                      field="dataContrato"
                      locale="pt-br"
                      data-testid="dataNegociacaoTest"
                    />
                    <small className="form__errorMsg">{errors.dataNegociacao?.message}</small>
                  </div>
                </div>
                <div className="registrarEditarLista__flex__left--section--bottom">
                  <div className="registrarEditarLista__flex__left--section--bottom--align">
                    <div
                      style={
                        salvarEditarLista?.parcelaContrato || linkData
                          ? {
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%"
                          }
                          : null
                      }
                    >
                      <label htmlFor="previstaEmissao">
                        Prevista de Vencimento
                        <abbr className="form__asterisk">*</abbr>
                      </label>
                      {(linkData || salvarEditarLista?.parcelaContrato) && (
                        <InfoIcon tooltipText="As alterações devem ser feitas através de parcela" />
                      )}
                    </div>

                    <Calendar
                      {...register("dataVencimento")}
                      className="registrarEditarLista__inputs"
                      mask="99/99/9999"
                      name="vencimento"
                      dateFormat={"dd/mm/yy"}
                      disabled={linkData || salvarEditarLista?.parcelaContrato}
                      showIcon
                      value={dataVencimento}
                      onChange={async (e) => {
                        const newValue = e.value;
                        setDataVencimento(newValue);
                        setValue("dataVencimento", newValue);

                        // Apenas chama verificaData se houver um valor válido
                        if (newValue) {
                          await verificaData(newValue);
                        }

                      }}
                      field="dataVencimento"
                      locale="pt-br"
                      data-testid="dataVencimentoPrevistaTest"
                    />
                    <small className="form__errorMsg">{errors.dataVencimento?.message}</small>
                  </div>
                  <div className="registrarEditarLista__flex__left--section--bottom--align">
                    <label htmlFor="vencimento">
                      Vencimento Modificado
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <Calendar
                      {...register("dataVencimentoPlanejamento")}
                      className="registrarEditarLista__inputs"
                      mask="99/99/9999"
                      name="vencimentoModificado"
                      dateFormat={"dd/mm/yy"}
                      showIcon
                      value={dataVencimentoPlanejamento}
                      onChange={async (e) => {
                        const newValue = e.value;
                        setDataVencimentoPlanejamento(newValue);
                        setValue("dataVencimentoPlanejamento", newValue);


                        if (newValue) {
                          await verificaData(newValue);
                        }

                      }}
                      field="dataVencimentoPlanejamento"
                      locale="pt-br"
                      data-testid="dataVencimentoProrrogadaTest"
                      disabled={dataBaixa}
                    />
                    <small className="form__errorMsg">
                      {errors.dataVencimentoPlanejamento?.message}
                    </small>

                  </div >
                </div >
                <div className="registrarEditarLista__flex__left--section--bottom">
                  <div className="registrarEditarLista__flex__left--section--bottom--align">
                    <label htmlFor="previstaEmissao">
                      Prevista de Emissão
                    </label>
                    <Calendar
                      {...register("dataPrevistaEmissao")}
                      mask="99/99/9999"
                      name="previstaEmissao"
                      dateFormat={"dd/mm/yy"}
                      showIcon
                      className="registrarEditarLista__inputs"
                      value={dataPrevistaEmissao}
                      onChange={async (e) => {
                        setDataPrevistaEmissao(e.value);

                        await verificaData(e.value)
                      }}
                      field="dataPrevistaEmissao"
                      locale="pt-br"
                      data-testid="dataPrevistaEmissaoTest"
                    />
                  </div>
                  <div className="registrarEditarLista__flex__left--section--bottom--align">
                    <label htmlFor="emissao">Emissão </label>
                    <Calendar
                      mask="99/99/9999"
                      name="emissao"
                      dateFormat={"dd/mm/yy"}
                      showIcon
                      className="registrarEditarLista__inputs"
                      value={dataEmissao}
                      onChange={(e) => {
                        setDataEmissao(e.value);
                      }}
                      field="dataEmissao"
                      locale="pt-br"
                      data-testid="dataEmissaoTest"
                    />

                  </div>
                </div>
              </div >
              <div className="registrarEditarLista__flex__right">
                <div className="registrarEditarLista__flex__right__wrapper">
                  <div
                    className="registrarEditarLista__flex__right__wrapper--firstSection"
                    style={
                      linkData
                        ? {
                          marginBottom: "2vh"
                        }
                        : {}
                    }
                  >
                    {" "}
                    {(linkData || salvarEditarLista?.parcelaContrato) && (
                      <div className="iconMonetario">
                        <InfoIcon tooltipText="As alterações devem ser feitas através de parcela" />
                      </div>
                    )}
                    <div className="registrarEditarLista__flex__right__wrapper--firstSection--fields">
                      <label className="registrarEditarLista__flex__right--valorBruto">
                        Valor Bruto <small className="form__asterisk">*</small>
                      </label>
                      <div className="registrarEditarLista__flex__right--valorBruto--layoutMessage">
                        <CurrencyInput
                          className="registrarEditarLista__flex__right--valorBruto--input"
                          field="valorBruto"
                          placeholder={valorBruto}
                          value={valorBruto}
                          disabled={linkData || salvarEditarLista?.parcelaContrato}
                          setValue={setValorBruto}
                          minFractionDigits={2}
                          maxFractionDigits={2}
                          maxLength={20}
                        />
                        <div className="registrarEditarLista__flex__right--valorBruto--positionMessage">
                          <small className="form__errorMsg">{errors.valorBruto?.message}</small>
                        </div>
                      </div>
                    </div>
                    <div className="registrarEditarLista__flex__right__wrapper--firstSection--fields">
                      <label className="registrarEditarLista__flex__right__wrapper--firstSection--fields--label">
                        {" "}
                        (-)Desconto
                      </label>
                      <CurrencyInput
                        className="registrarEditarLista__flex__right--desconto--input"
                        field="desconto"
                        value={desconto}
                        setValue={setDesconto}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        maxLength={20}
                      />
                    </div>
                    <div className="registrarEditarLista__flex__right__wrapper--lastSection--juros">
                      <label className="registrarEditarLista__flex__right__wrapper--firstSection--fields--label">
                        (+)Juros
                      </label>
                      <CurrencyInput
                        className="registrarEditarLista__flex__right--juros--input"
                        field="juros"
                        value={juros}
                        setValue={setJuros}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        maxLength={20}
                      />
                    </div>
                    <div className="registrarEditarLista__flex__right__wrapper--lastSection--multa">
                      <label className="registrarEditarLista__flex__right__wrapper--firstSection--fields--label">
                        (+)Multa
                      </label>
                      <CurrencyInput
                        className="registrarEditarLista__flex__right--multa--input"
                        field="multa"
                        value={multa}
                        setValue={setMulta}
                        minFractionDigits={2}
                        maxFractionDigits={2}
                        maxLength={20}
                      />
                    </div>
                    <div className="registrarEditarLista__flex__right__wrapper--firstSection--fields">
                      <label className="registrarEditarLista__flex__right__wrapper--firstSection--fields--label">
                        (=)Total Líquido
                      </label>
                      <CurrencyInput
                        className="registrarEditarLista__flex__right--totalLiquido--input"
                        name="totalLiquido"
                        onChange={(e) => {
                          setTotalLiquido(e.value);
                        }}
                        disabled={true}
                        field="valorLiquido"
                        id="valorLiquido"
                        value={totalLiquido}
                        prefix="R$"
                        maxLength={14}
                        data-testid="totalLiquidoTest"
                      />
                    </div>
                  </div>

                </div>

                <div className="registrarEditarLista__flex__right__dataBaixa">
                  <label htmlFor="vencimento">
                    Baixa
                    {valorBaixa && <small className="form__asterisk">*</small>}
                  </label>
                  <Calendar
                    {...register("dataBaixa")}
                    mask="99/99/9999"
                    name="baixa"
                    dateFormat={"dd/mm/yy"}
                    showIcon
                    value={dataBaixa}
                    onChange={(e) => {
                      setDataBaixa(e.value);
                    }}
                    field="dataBaixa"
                    locale="pt-br"
                    data-testid="dataBaixaTest"
                    onFocus={teste1}
                  />
                  <small className="form__errorMsg">{errors.dataBaixa?.message}</small>
                  <label className="registrarEditarLista__flex__right__valorBaixa">
                    Valor da Baixa
                    {dataBaixa && <small className="form__asterisk">*</small>}
                  </label>
                  <InputNumber
                    {...register("valorBaixa")}
                    name="valorBaixa"
                    onChange={(e) => {
                      setValorBaixa(e.value);
                    }}
                    field="valorBaixa"
                    id="valorBaixa"
                    value={valorBaixa}
                    prefix="R$"
                    minFractionDigits={2}
                    maxFractionDigits={2}
                    maxLength={14}
                    onBlur={handleMessage}
                    data-testid="valorBaixaTest"
                  />
                  <small className="form__errorMsg">{errors.valorBaixa?.message}</small>
                </div>
                <hr className="registrarEditarLista__flex__right__row"></hr>
                <div className="registrarEditarLista__flex__right--section">
                  <div className="registrarEditarLista__flex__right--section--align">
                    <label>Número do Documento</label>
                    <InputText
                      name="numeroNota"
                      onChange={(e) => {
                        setNumeroNota(e.target.value);
                      }}
                      field="numeroNota"
                      id="numeroNota"
                      value={numeroNota}
                      keyfilter="pint"
                      maxLength={20}
                      data-testid="numeroNotaTest"
                    />
                  </div>
                  <div className="registrarEditarLista__flex__right--section--align">
                    <label>Série do Documento</label>
                    <InputText
                      name="valorMinimo"
                      required=""
                      onChange={(e) => {
                        setSerieNota(e.target.value);
                      }}
                      field="serieNota"
                      id="serieNota"
                      value={serieNota}
                      maxLength={50}
                      keyfilter="pint"
                      data-testid="serieDocumentoTest"
                    />
                  </div>
                </div>
                <div className="registrarEditarLista__flex__right--dropdown">
                  <div
                    style={
                      salvarEditarLista?.parcelaContrato || linkData
                        ? {
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                          width: "100%"
                        }
                        : null
                    }
                  >
                    <label htmlFor="naturezaLancamento">
                      Natureza do Lançamento
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    {(salvarEditarLista?.parcelaContrato || linkData) && (
                      <InfoIcon
                        tooltipText="As alterações devem ser feitas através do contrato"
                        left
                      />
                    )}
                  </div>
                  <Dropdown
                    value={idNatureza}
                    onChange={(e) => setIdNatureza(e.value)}
                    options={listaNatureza}
                    filter={true}
                    optionLabel="naturezaHierarquica"
                    optionValue="idNatureza"
                    data-testid="naturezaTest"
                    showClear
                    disabled={salvarEditarLista?.parcelaContrato || linkData}
                    filterBy="naturezaHierarquica"
                  />
                  <small className="form__errorMsg">
                    {errors.idNatureza?.message}
                  </small>

                  <small className="form__errorMsg"></small>
                </div>
                <div className="registrarEditarLista__flex__right--dropdown">
                  <label htmlFor="empresa">Centro de Custo</label>

                  <Dropdown
                    value={centroCusto}
                    onChange={(e) => setCentroCusto(e.value)}
                    options={getCentroDeCusto}
                    filter={true}
                    optionLabel="descricao"

                    data-testid="centroDeCustoTest"
                    className={errors.centroCusto ? 'p-invalid' : ''}
                    showClear
                  />
                </div >
                <div className="registrarEditarLista__flex__right--textArea">
                  <div>
                    <label>
                      Histórico<abbr className="form__asterisk">*</abbr>
                    </label>
                  </div>
                  <InputTextarea
                    {...register("historico")}
                    rows={4}
                    cols={102}
                    value={historico}
                    onChange={(e) => {
                      setHistorico(e.target.value);
                    }}
                    field="historico"
                    id="historico"
                    maxLength="2000"
                  />
                  <small className="form__errorMsg">{errors.historico?.message}</small>
                </div>
                {
                  !idMovimentacao && !linkData && (
                    <div className="registrarEditarLista__flex__right--lastSection">
                      <div className="registrarEditarLista__flex__right--select--align ">
                        <label>
                          Repetir Lançamento
                          <abbr className="form__asterisk">*</abbr>
                        </label>
                        <SelectButton
                          className="filter-select"
                          options={selectRepetirLançamento}
                          value={repetirLancamento}
                          onChange={(e) => {

                            handleRepetirLancamentoChange(e.value);
                          }}
                          optionLabel="name"
                          optionValue="value"
                          data-testid="repetirLancamentoTest"
                        />
                      </div>

                      <div className="registrarEditarLista__flex__right--select--align ">
                        <label>
                          Quando o vencimento coincidir com dia não útil, lançar no:
                          <abbr className="form__asterisk">*</abbr>
                        </label>
                        <SelectButton
                          className="filter-select"
                          options={selectDiaUtilComportamento}
                          value={diaUtil}
                          onChange={(e) =>
                            handleSelectChange({
                              onChangeValue: e.value,
                              setState: setDiaUtil
                            })
                          }
                          optionLabel="name"
                          optionValue="value"
                          data-testid="selectDiaUtilTest"
                        />
                      </div>
                    </div>
                  )
                }
                <div className="registrarEditarLista__flex__right--lastSection">
                  {!idMovimentacao && !linkData && (
                    <div className="registrarEditarLista__flex__right--lastSection--numParcela">
                      <label>
                        Número de Parcelas
                        <abbr className="form__asterisk">*</abbr>
                      </label>
                      <InputText
                        {...register("numParcelas")}
                        name="numeroParcela"
                        type="number"
                        value={numeroParcelas}
                        onChange={(e) => {
                          setNumeroParcelas(e.target.value);
                          setValue("numParcelas", e.target.value);
                        }}
                        disabled={repetirLancamento !== true}
                        data-testid="numeroparcelasTest"
                      />
                      <small className="form__errorMsg">{errors.numParcelas?.message}</small>
                    </div>
                  )}
                </div>
                <div className="registrarEditarLista__flex__right--lastSection">
                  {!idMovimentacao && !linkData && (
                    <div className="registrarEditarLista__flex__right--lastSection--codProjeto">
                      <label>
                        Provisão
                        <abbr className="form__asterisk">*</abbr>
                      </label>
                      <SelectButton
                        className="filter-select"
                        options={selectProvisao}
                        value={provisao}
                        onChange={(e) =>
                          handleSelectChange({
                            onChangeValue: e.value,
                            setState: setProvisao
                          })
                        }
                        optionLabel="name"
                        optionValue="value"
                        data-testid="selectProvisao"
                      />
                    </div>
                  )}
                </div>
              </div >
            </div >
            <hr></hr>
            <div className="registrarEditarLista__buttons">
              {salvarEditarLista ? (
                <ButtonComponent
                  type="rateio"
                  onClick={() => abrirLinhaComId()}
                  datatesteid="buttonRateio"
                />
              ) : null}
              <ButtonComponent
                type="cancel"
                onClick={() => setPageInRedux()}
                datatesteid="buttonCancel"
              />
              <ButtonComponent
                type="confirm"
                onClick={handleSubmit(salvar)}
                datatesteid="buttonConfirm"
              />
            </div>
          </form >
        </div >
      </div >
    </LoadingOverlayComponents >
  );
};
