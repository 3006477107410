import { useMemo } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import * as yup from "yup";
import { defaultNatureza } from ".";
import { setReloadNatureza } from "../../actions";
import {
  deleteNatureza,
  getNaturezaById,
  saveNatureza,
  updateNatureza,
  updateNaturezaPai
} from "../../api";
import { ButtonComponent } from "../../components/ButtonComponent";
import { Input } from "../../components/Input";
import { ModalComponents } from "../../components/ModalComponents";
import { messageRequired, messageRequiredMinimumCharacters } from "../../default/messages";
import { DropdownComponent } from "../../components/FormInputs/DropdownComponent";

export default function Dados({
  naturezaPai,
  setNaturezaPai,
  setAlteracoesFeitas,
  natureza,
  setNatureza,
  getListNatureza,
  noChildren,
  getNaturezaPai
}) {
  const dispatch = useDispatch();
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [listaPaiFiltrada, setListaPaiFiltrada] = useState(getNaturezaPai);
  const { idNatureza } = useParams();
  const history = useHistory();
  const toast = useRef(null);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [ano, setAno] = useState();
  const [percentual, setPercentual] = useState();
  const [errorNaturezaPai, setErrorNaturezaPai] = useState();



  useEffect(() => {
    const naturezasFiltradas = idNatureza
      ? getNaturezaPai?.filter((each) => each.id !== idNatureza)
      : getNaturezaPai;
    setListaPaiFiltrada(naturezasFiltradas);

    const obterAnoAtual = () => {
      const data = new Date();
      const anoAtual = data.getFullYear();
      setAno(anoAtual);
    };

    obterAnoAtual();
  }, [getNaturezaPai, idNatureza]);

  const tiposNatureza = useMemo(
    () => [
      { name: "Natureza Principal", withFather: false },
      { name: "Natureza Filha", withFather: true }
    ],
    []
  );

  const [tipoNatureza, setTipoNatureza] = useState();

  const handleAlterarTipoNatureza = (event) => {
    const { value } = event;
    setTipoNatureza(value);
    if (value === tiposNatureza[0]) {
      setNaturezaPai(null);
      return;
    }

    setTipoNatureza(value);
  };

  const optionsAnalitica = [
    { name: "Sim", value: "S" },
    { name: "Não", value: "N" }
  ];

  const optionsAtiva = [
    { name: "Ativa", value: "S" },
    { name: "Inativa", value: "N" }
  ];

  const optionsTipoLancamento = [
    { name: "Crédito", value: "C" },
    { name: "Débito", value: "D" },
    { name: "Ambos", value: "A" }
  ];

  const validationPost = yup
    .object({
      descricao: yup
        .string()
        .required(messageRequired)
        .trim()
        .min(3, messageRequiredMinimumCharacters("A descrição", 2)),
      analitica: yup.string().required(messageRequired),
      ativa: yup.string().required(messageRequired),
      tipoLancamento: yup.string().required(messageRequired),
      codigoExterno: yup
        .number()
        .typeError("Deve ser um número")
        .transform((value, originalValue) => {
          const regex = /^[0-9]*$/;
          if (!regex.test(String(originalValue))) {
            return NaN;
          }
          return value;
        })
        .required(messageRequired)
        .integer("Informe um código válido")
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationPost)
  });

  useEffect(() => {
    setValue("descricao", natureza.descricao);
    setValue("ativa", natureza.ativa);
    setValue("analitica", natureza.analitica);
    setValue("tipoLancamento", natureza.tipoDeLancamento);
    setValue("codigoExterno", natureza.codigoExterno);
    if (natureza.metas) {
      const currentYear = new Date().getFullYear();
      const currentYearMeta = natureza.metas.find((meta) => meta.ano === currentYear);
      if (currentYearMeta) {
        setValue("ano", currentYearMeta.ano);
        setValue("percentual", currentYearMeta.percentual);
      } else {
        setValue("percentual", "");
      }
    } else {
      setValue("ano", "");
      setValue("percentual", "");
    }
  }, [natureza, setValue]);

  useEffect(() => {
    if (idNatureza) {
      setNaturezaPai(natureza?.naturezaPai || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idNatureza, natureza]);

  function handleKeyPress(event) {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    if (!/^\d+$/.test(keyValue)) {
      event.preventDefault();
    }
  }

  const getNatureza = useCallback(async () => {
    const definirNatureza = (natureza) => {
      setNatureza(natureza);
      const tipo = natureza?.naturezaPai ? tiposNatureza[1] : tiposNatureza[0];
      setTipoNatureza(tipo);
    };

    if (!idNatureza) {
      definirNatureza(defaultNatureza);
      return;
    }

    try {
      const resultado = await getNaturezaById(idNatureza);
      definirNatureza(resultado);
    } catch (error) {
      montarNotificacao({
        severity: "error",
        summary: "Erro",
        detail: error.response?.data?.userMessage,
        life: 5000
      });
    }
  }, [idNatureza, setNatureza, tiposNatureza]);

  useEffect(() => {
    const naturezaComPai = tipoNatureza?.withFather && naturezaPai?.paiId;
    if (naturezaComPai || !tipoNatureza?.withFather) {
      setErrorNaturezaPai(null);
    }
  }, [naturezaPai, tipoNatureza]);

  const montarNotificacao = ({ severity, summary, detail, life }) => {
    return toast.current.show({ severity, summary, detail, life });
  };

  const editNatureza = {
    descricao: natureza.descricao,
    codigoExterno: natureza.codigoExterno,
    tipoDeLancamento: natureza.tipoDeLancamento,
    analitica: natureza.analitica,
    ativa: natureza.ativa,
    grau: natureza.grau,
    naturezaPai: naturezaPai
      ? {
          descricao: naturezaPai?.descricao ? naturezaPai?.descricao : null,
          paiId: naturezaPai?.paiId ? naturezaPai?.paiId : null
        }
      : null,
    ano: ano,
    percentual: percentual ? percentual : natureza.metas?.percentual
  };

  useEffect(() => {
    getNatureza();
  }, [idNatureza, getNatureza]);

  function updateModel(e) {
    setAlteracoesFeitas(true);
    setNatureza({ ...natureza, [e.target.name]: e.target.value });
  }

  if (!natureza) {
    return <h6>Sem dados</h6>;
  }

  async function handleDelete(e) {
    setCarregandoRequisicao(true);
    try {
      await deleteNatureza(idNatureza).then(async () => {
        setShowModalDelete(false);
        montarNotificacao({
          severity: "success",
          summary: "Info",
          detail: "Natureza excluída!",
          life: 5000
        });
        getListNatureza();
        setAlteracoesFeitas(false);
        setNaturezaPai(null);
        history.push("/naturezas");
      });
      await updatedNatureza();
    } catch (error) {
      montarNotificacao({
        severity: "error",
        summary: "Erro",
        detail: error.response?.data?.userMessage,
        life: 5000
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  async function handlePost() {
    const postNatureza = {
      descricao: natureza.descricao,
      codigoExterno: natureza.codigoExterno,
      // id: natureza.codigoExterno,
      tipoDeLancamento: natureza.tipoDeLancamento,
      analitica: natureza.analitica,
      ativa: natureza.ativa,
      grau: natureza.grau,
      naturezaPai: naturezaPai
        ? {
            descricao: naturezaPai?.descricao ? naturezaPai?.descricao : null,
            paiId: naturezaPai?.paiId ? naturezaPai?.paiId : null
          }
        : null,
      percentual: percentual ? percentual : natureza.metas?.percentual,
      ...(percentual ? { ano: ano } : {})
    };
    setCarregandoRequisicao(true);
    try {
      await saveNatureza(postNatureza).then((response) => {
        montarNotificacao({
          severity: "success",
          summary: "Info",
          detail: "Natureza salva!",
          life: 5000
        });
        getListNatureza();
        setAlteracoesFeitas(false);
        history.push(`/natureza/${response.id}`);
      });
      await updatedNatureza();
    } catch (error) {
      const mensagem = error?.response?.data?.userMessage;
      montarNotificacao({
        severity: "error",
        summary: "Erro",
        detail: `Natureza parcialmente salva.\n${mensagem}`,
        life: 6500
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  async function updatePai() {
    if (naturezaPai?.id > 0) {
      try {
        await updateNaturezaPai(parseInt(idNatureza), naturezaPai.id);
      } catch (err) {
        const mensagem = err?.response?.data?.userMessage;
        getListNatureza();
        montarNotificacao({
          severity: "error",
          summary: "Erro",
          detail: `Natureza parcialmente salva.\n${mensagem}`,
          life: 6500
        });
      }
    }
  }

  const updatedNatureza = () => {
    dispatch(
      setReloadNatureza({
        reload: true
      })
    );
  };

  async function handlePut() {
    setCarregandoRequisicao(true);
    try {
      await updateNatureza(editNatureza, idNatureza).then((response) => {
        updatePai();
        getListNatureza();
        montarNotificacao({
          severity: "success",
          summary: "Info",
          detail: "Natureza atualizada!",
          life: 5000
        });
        setAlteracoesFeitas(false);
      });
      await updatedNatureza();
    } catch (error) {
      const mensagem = error?.response?.data?.userMessage;
      montarNotificacao({
        severity: "error",
        summary: "Erro",
        detail: `Natureza parcialmente salva.\n${mensagem}`,
        life: 6500
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  function handleSubmitForm() {
    if (tipoNatureza?.withFather && !naturezaPai?.paiId) {
      setErrorNaturezaPai(messageRequired);
      return;
    }
    if (idNatureza) {
      handlePut();
    } else {
      handlePost();
    }
  }

  const modalExcluir = [
    { label: "Código:", value: natureza.id },
    { label: "Descrição:", value: natureza.descricao }
  ];
  return (
    <LoadingOverlay
      active={carregandoRequisicao}
      spinner
      text="Carregando..."
      styles={{
        overlay: (base) => ({
          ...base,
          width: "105vw",
          height: "100vh",
          top: "-19.65vh",
          marginLeft: "-75%",
          zIndex: "99999"
        })
      }}
    >
      <div className="naturezaContainer">
        <Toast ref={toast} />
        <ModalComponents
          title="Natureza"
          onClick="delete"
          visible={showModalDelete}
          descricao={modalExcluir}
          onHide={() => setShowModalDelete(false)}
          onClickCancelar={() => setShowModalDelete(false)}
          onClickConfirmar={() => handleDelete()}
          datatesteidconfirm="buttonConfirmModal"
          datatesteid="buttonCancelModal"
        />
        <div className="naturezaContainer__icon">
          <Button
            className="naturezaContainer__icon--trash"
            icon="pi pi-trash"
            tooltip="Excluir natureza"
            tooltipOptions={{ position: "left" }}
            data-testid="buttonExcluirID"
            onClick={(e) => {
              if (noChildren) {
                setShowModalDelete(true);
              } else if (idNatureza === undefined || idNatureza === null) {
                montarNotificacao({
                  severity: "error",
                  summary: "Info",
                  detail: "Selecione alguma natureza.",
                  life: 5000
                });
              } else {
                montarNotificacao({
                  severity: "error",
                  summary: "Info",
                  detail: "Não é possível excluir natureza que possuem filhos.",
                  life: 5000
                });
              }
            }}
          />
        </div>
        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div className="naturezaContainer__column">
            <Input
              register={register("descricao")}
              w="250"
              label="Descrição"
              required
              value={natureza.descricao}
              maxLength={300}
              onChange={updateModel}
              error={!natureza.descricao?.length}
              errorMessage={errors.descricao?.message}
              name="descricao"
              datatesteid="descricaoID"
            />
            <div className="naturezaContainer__formulario">
              <div className="naturezaContainer__column">
                <Input
                  label="Grau"
                  disabled
                  type="number"
                  maxLength={1}
                  value={natureza.grau}
                  onChange={updateModel}
                  name="grau"
                  datatesteid="grauID"
                />
                <Input
                  type="number"
                  keyfilter="pint"
                  label="Código Identificador Único da Natureza"
                  value={natureza.codigoExterno}
                  onChange={updateModel}
                  name="codigoExterno"
                  maxLength={100}
                  datatesteid="codigoExternoID"
                  required
                  onKeyPress={handleKeyPress}
                  errorMessage={errors.codigoExterno?.message}
                />
                <div>
                  <label>Analítica</label>
                  <abbr className="form__asterisk">*</abbr>
                </div>
                <SelectButton
                  {...register("analitica")}
                  className="filter-select"
                  options={optionsAnalitica}
                  value={natureza.analitica}
                  style={{ width: "100%" }}
                  onChange={(e) => {
                    setNatureza({ ...natureza, analitica: e.value });
                    setValue("analitica", e.value);
                  }}
                  optionLabel="name"
                  optionValue="value"
                />
                <small className="form__errorMsg">{errors.analitica?.message}</small>

                <div className="naturezaContainer__column--margin">
                  <div className="containerMetas__inputs containerMetas__inputs--row">
                    <Input
                      register={register("ano")}
                      type="text"
                      label="Ano"
                      name="ano"
                      value={ano}
                      disabled={true}
                      maxLength={4}
                      min={0}
                      onChange={(e) => {
                        if (e.target.value <= 9999) {
                          setAlteracoesFeitas(true);
                          setAno(e.target.value);
                        }
                      }}
                      errorMessage={errors.ano?.message}
                    />
                    <Input
                      register={register("percentual")}
                      type="number"
                      label="Meta"
                      name="percentual"
                      value={natureza?.metas?.percentual}
                      maxLength={3}
                      min={0}
                      prefix="%"
                      onChange={(e) => {
                        if (e.target.value <= 100) {
                          setAlteracoesFeitas(true);
                          setPercentual(parseInt(e.target.value, 10));
                        }
                      }}
                      errorMessage={errors.percentual?.message}
                    />
                  </div>
                </div>
              </div>

              <div className="naturezaContainer__column">
                <div className="naturezaContainer__column--margin">
                  <div>
                    <label>Tipo Natureza</label>
                    <abbr className="form__asterisk">*</abbr>
                  </div>
                  <SelectButton
                    {...register("tipoNatureza")}
                    className="filter-select"
                    options={tiposNatureza}
                    value={tipoNatureza}
                    onChange={(e) => {
                      if (e.value && e.value !== tipoNatureza) {
                        handleAlterarTipoNatureza(e);
                        setValue("tipoNatureza", e.value);
                      }
                    }}
                    optionLabel="name"
                  />
                </div>

                {tipoNatureza?.withFather && (
                  <div className="naturezaContainer__column--margin">
                    <label htmlFor="banco">
                      Natureza Pai
                      <abbr className="form__asterisk">*</abbr>
                    </label>
                    <DropdownComponent
                      value={naturezaPai?.paiId}
                      setState={(e) => {
                        const itemSelecionado = listaPaiFiltrada.find(item => item.paiId === e);

                        setNaturezaPai(itemSelecionado);
                    
                        if (itemSelecionado) {
                          setValue("naturezaPrincipal", itemSelecionado.paiId);
                          setNatureza({ 
                            ...natureza, 
                            naturezaPai: { 
                              paiId: itemSelecionado.paiId, 
                              descricao: itemSelecionado.descricao 
                            } 
                          });
                        }
                      }}
                      options={listaPaiFiltrada}
                      optionLabel="descricao"
                      optionValue="paiId"
                      filter
                      showClear
                      errorMessage={errorNaturezaPai}
                      style={{ width: "355px" }}
                    />
                  </div>
                )}

                <div className="naturezaContainer__column--margin containerStatus">
                  <div>
                    <label>Status</label>
                    <abbr className="form__asterisk">*</abbr>
                  </div>
                  <SelectButton
                    {...register("ativa")}
                    className="filter-select"
                    options={optionsAtiva}
                    value={natureza.ativa}
                    onChange={(e) => {
                      setNatureza({ ...natureza, ativa: e.value });
                      setValue("ativa", e.value);
                    }}
                    optionLabel="name"
                    optionValue="value"
                  />
                  <small className="form__errorMsg">{errors.ativa?.message}</small>
                </div>

                <div className="naturezaContainer__column--margin">
                  <div>
                    <label>Tipo de Lançamento</label>
                    <abbr className="form__asterisk">*</abbr>
                  </div>
                  <SelectButton
                    {...register("tipoLancamento")}
                    className="filter-select"
                    options={optionsTipoLancamento}
                    value={natureza.tipoDeLancamento}
                    onChange={(e) => {
                      setNatureza({ ...natureza, tipoDeLancamento: e.value });
                      setValue("tipoLancamento", e.value);
                    }}
                    optionLabel="name"
                    optionValue="value"
                  />
                  <small className="form__errorMsg">{errors.tipoLancamento?.message}</small>
                </div>
              </div>
            </div>
            <div className="naturezaContainer__button">
              <ButtonComponent type="confirmForm" datatesteid="confirmarButton" />
            </div>
          </div>
        </form>
      </div>
    </LoadingOverlay>
  );
}
