const INITIAL_STATE = {
    cnpj: null,
    emailNotaFiscal: null,
    grupoEconomicoNomeFantasia: null,
    id: null,
    inscEstadual: null,
    inscMunicipal: null,
    nomeFantasia: null,
    razaoSocial: null,
    status: "TODOS",
};

export const filtroEmpresa = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_FILTRO_EMPRESA":
            return { ...action.payload };
        case "SET_PAGINA":
            return { ...state, pagina: action.payload };
        case "SET_QTD_REGISTRO":
            return { ...state, qtdRegistros: action.payload };
        default:
            return state;
    }
};
