import axios from "axios";
export * from "./Anexos";
export * from "./CentroDeCusto";
export * from "./ContaCorrente";
export * from "./Emails";
export * from "./Empresa";
export * from "./GestaoDeContrato";
export * from "./GrupoEconomico";
export * from "./Metas";
export * from "./Movimentacao";
export * from "./Natureza";
export * from "./ParametrosSistema";
export * from "./Parceiro";
export * from "./Parcela";
export * from "./ProdutoServico";
export * from "./Projeto";
export * from "./Rateio";
export * from "./Usuario";
export * from "./Municipio";

const url = process.env.REACT_APP_API_URL;
const urlGcp = process.env.REACT_APP_API_GCP;

axios.defaults.baseURL = url;

export default axios;

export const apiGCP = axios.create({
  baseURL: `${urlGcp}`,
});

export const getApiViaCep = (cep) =>
  fetch(`http://viacep.com.br/ws/${cep}/json/`).then((res) => res.json());

export const getIbgeCities = (ufPrincipal) =>
  axios
    .get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${ufPrincipal}/municipios`
    )
    .then((response) => response.data);
