import { Dropdown } from "primereact/dropdown";
import React from "react";
import "./styles.css";
import { ProgressSpinner } from 'primereact/progressspinner'; 
export const DropdownComponent = ({
  editable,
  label,
  options,
  value,
  setState,
  placeholder,
  optionLabel,
  optionValue,
  required,
  disabled,
  isEmpty,
  validacao,
  errorMessage,
  datatesteid,
  style,
  onShow  ,
  loading,
  setAlteracoes = () => { }
}) => {
  return (
    <div style={style} key={label} className={label && "select__form"}>
      {label && <div>
        <label>{label}</label>
        {required && (
          <abbr data-testid="abbr-asteristic" className="form__asterisk">
            *
          </abbr>
        )}
      </div>}
      <Dropdown
        validacao
        filter
        disabled={disabled}
        value={value}
        options={options}
        placeholder={placeholder}
        onChange={(e) => {
          setState(e.value[optionValue] || e.value);
          setAlteracoes(true)
        }}
        optionLabel={optionLabel}
        optionValue={optionValue}
        loading 
        className="select__dropdown w-full md:w-14rem"
        panelStyle={{ width: "0px" }}
        editable={editable}
        data-testid={datatesteid}
        onShow={() => {
          if (onShow) onShow(); 
        }}
        emptyMessage={loading ? <ProgressSpinner style={{ width: '20px', height: '20px' }} /> : "Nenhum resultado encontrado"} 
      />
      <small
        className="form__errorMsg"
      // hidden={!isEmpty}
      >
        {errorMessage}
      </small>
    </div>
  );
};
