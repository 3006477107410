import { yupResolver } from "@hookform/resolvers/yup";
import { InputText } from "primereact/inputtext";
import { SelectButton } from "primereact/selectbutton";
import { Toast } from "primereact/toast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  exibirCCSalva,
  setFiltroCC
} from "../../actions";
import {
  getEmpresaResumo,
  saveContaCorrente,
  updateContaCorrente,
  getAllParceiros,
  getAllNameBank,
} from "../../api";
import ReduxContext from "../../context/ReduxContext";
import {
  messageRequired,
  messageRequiredMinimumCharacters,
} from "../../default/messages";
import { ButtonComponent } from "../ButtonComponent";
import { DropdownComponent } from "../FormInputs/DropdownComponent";
import { Header } from "../Header";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import { SaldoConta } from "../SaldoConta";
import "./styles.css";
import { InputTextarea } from "primereact/inputtextarea";
export const EditarContaCorrente = ({ editConta, page }) => {
  const [nomeBancoInput, setNomeBancoInput] = useState("");
  let history = useHistory();
  const toast = useRef(null);
  const dispatch = useDispatch();
  const reduxStateInicial = useContext(ReduxContext);
  const [agenciaInput, setAgenciaInput] = useState("");
  const [contaCorrenteInput, setContaCorrenteInput] = useState("");
  const [descricaoInput, setDescricaoInput] = useState("");
  const [classeInput, setClasseInput] = useState("");
  const [contaPrincipalInput, setContaPrincipalInput] = useState("");
  const [typeConta, setTypeConta] = useState(null);
  const [ativaInput, setAtivaInput] = useState("");
  const [empresaInput, setEmpresaInput] = useState(null);
  const [parceiroInput, setParceiroInput] = useState(null);
  const [allEmpresas, setAllEmpresas] = useState([]);
  const [allParceiros, setAllParceiros] = useState([]);
  const [abrirConta, setAbrirConta] = useState(false);
  const [allBanco, setAllBanco] = useState([]);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState(false);
  const [alteracoes, setAlteracoes] = useState(false);
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);

  const validationPost = yup
    .object({
      nomeBanco:
        classeInput !== "A" && yup.string().required(messageRequired).trim(),
      agencia:
        classeInput !== "A" && yup.string().required(messageRequired).trim(),
      contaCorrente:
        classeInput !== "A" && yup.string().required(messageRequired).trim(),
      descricao: yup
        .string()
        .trim()
        .min(3, messageRequiredMinimumCharacters("A Descrição da Conta", 2)),
      principal: yup.string().required(messageRequired),
      classeInput: yup.string().required(messageRequired),
      ativaInput: yup.string().required(messageRequired),
      typeConta: yup.string().required(messageRequired),
      idEmpresa: yup
        .string()
        .typeError("")
        .test("is-optional", messageRequired, function () {
          return (
            this.parent.idParceiro !== undefined ||
            this.parent.idEmpresa !== undefined
          );
        }),
      idParceiro: yup
        .string()
        .typeError("")
        .test("is-optional", messageRequired, function () {
          return (
            this.parent.idEmpresa !== undefined ||
            this.parent.idParceiro !== undefined
          );
        }),
    })
    .required("");

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  useEffect(() => {
    setValue(
      "nomeBanco",
      editConta?.banco?.idBanco || nomeBancoInput?.codBanco
    );
    setValue("agencia", agenciaInput);
    setValue("contaCorrente", contaCorrenteInput);
    setValue("ativaInput", ativaInput);
    setValue("classeInput", classeInput);
    setValue("descricao", descricaoInput);
    setValue("principal", contaPrincipalInput);
    setValue("typeConta", typeConta);
    setValue("idEmpresa", editConta?.idEmpresa || empresaInput?.id);
    setValue("idParceiro", editConta?.idParceiro || parceiroInput?.id);
  }, [
    nomeBancoInput,
    agenciaInput,
    contaPrincipalInput,
    contaCorrenteInput,
    descricaoInput,
    ativaInput,
    classeInput,
    typeConta,
    empresaInput,
    parceiroInput,
    setValue,
    editConta,
  ]);

  useEffect(() => {
    setValue("idEmpresa", editConta?.idEmpresa || empresaInput?.id);
    setValue("idParceiro", editConta?.idParceiro || parceiroInput?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editConta?.idEmpresa, editConta?.idParceiro]);

  const optionsClasse = [
    { name: "Conta Corrente", value: "C" },
    { name: "Investimento", value: "I" },
    { name: "Poupança", value: "P" },
    { name: "Carteira", value: "A" },
  ];

  const optionsConta = [
    { name: "Sim", value: "S" },
    { name: "Não", value: "N" },
  ];

  const optionsTypeConta = [{ type: "Empresa" }, { type: "Parceiro" }];

  const optionsStatus = [
    { name: "Ativa", value: "S" },
    { name: "Inativa", value: "N" },
  ];

  useEffect(() => {
    if (editConta) {
      const idBanco = editConta?.banco?.idBanco;
      setTypeConta((prevTypeConta) => {
        if (!prevTypeConta) {
          return editConta?.empresa?.id ? "Empresa" : "Parceiro";
        }
        return prevTypeConta; // Mantém o tipo atual caso já tenha sido definido manualmente
      });
      setNomeBancoInput(idBanco);
      setAgenciaInput(editConta.agencia);
      setContaCorrenteInput(editConta.contaCorrenteNumero);
      setDescricaoInput(editConta.descricao);
      setClasseInput(editConta.classe);
      setContaPrincipalInput(editConta.principal);
      setAtivaInput(editConta.ativa);
    }
  }, [editConta, empresaInput, parceiroInput]);

  const conta = {
    agencia: agenciaInput,
    ativa: ativaInput,
    classe: classeInput,
    contaCorrente: contaCorrenteInput,
    descricao: descricaoInput,
    idEmpresa: empresaInput?.id ? empresaInput?.id : null,
    idParceiro: parceiroInput?.id ? parceiroInput?.id : null,
    idBanco: nomeBancoInput ? nomeBancoInput : null,
    principal: contaPrincipalInput,
  };

  async function salvar() {
    setCarregandoRequisicao(true);
    try {
      if (editConta) {
        await updateContaCorrente(editConta.id, conta);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Conta Corrente alterada com sucesso!",
        });
        dispatch(exibirCCSalva());
      } else {
        await saveContaCorrente(conta);
        await toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Conta Corrente salva com sucesso!",
        });
        dispatch(exibirCCSalva());
      }
      dispatch(setFiltroCC(reduxStateInicial.filtroCC));
      setTimeout(() => {
        history.push("/contas-correntes");
      }, 1500);
    } catch (e) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${e?.response?.data?.userMessage}`,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }



  useEffect(() => {
    async function fetchEmpresas() {
      const empresas = await getEmpresaResumo();
      setAllEmpresas(empresas);

      if (editConta && editConta.empresa) {
        const empresaSelecionada = empresas.find(
          (empresa) => empresa.id === editConta.empresa.id
        );
        setEmpresaInput(empresaSelecionada);
      }
    }

    fetchEmpresas();
  }, [editConta]);

  useEffect(() => {
    async function fetchParceiros() {

      const parceiros = await getAllParceiros();
      setAllParceiros(parceiros);
      if (editConta && editConta.parceiro) {
        const parceiroSelecionado = parceiros.find(
          (parceiro) => parceiro.id === editConta.parceiro.id
        );
        setParceiroInput(parceiroSelecionado);
      }

    }

    fetchParceiros();
  }, [editConta]);


  async function validateGoBack() {
    if (alteracoes === true) {
      setAlteracoesFeitas(true);
    } else {
      history.push("/contas-correntes", { page: page });
    }
  }

  const modalWarning = [{ label: "Deseja descartar as alterações?" }];

  function abrirSaldo() {
    setAbrirConta(true);
  }

  useEffect(() => {
    getAllBanksNames();
  }, []);

  async function getAllBanksNames() {
    setCarregandoRequisicao(true);
    try {
      const data = await getAllNameBank();
      setAllBanco(data);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${error?.response?.data?.userMessage}`
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  useEffect(() => {
    if (typeConta === "Empresa") {
      setParceiroInput(null);
    }
    if (typeConta === "Parceiro") {
      setEmpresaInput(null);
    }
  }, [typeConta]);

  return (
    <LoadingOverlayComponents
      active={carregandoRequisicao}
      spinner
      text="Carregando..."
    >
      <div className="conta__wrapper">
        <Toast ref={toast} />
        <Header title="Conta Corrente" onClick={editConta ? "edit" : "add"} />
        <div className="conta__container">
          <ModalComponents
            visible={alteracoesFeitas}
            onHide={() => setAlteracoesFeitas(false)}
            onClickCancelar={() => setAlteracoesFeitas(false)}
            onClickConfirmar={() => {
              history.push("/contas-correntes", { page: page });
            }}
            onClick="warning"
            descricao={modalWarning}
          />
          <form className="contaForm">
            <div className="contaForm__section">
              <div className="contaForm__field">
                <label>
                  Conta de <abbr className="form__asterisk">*</abbr>
                </label>
                <SelectButton
                  className="filter-select"
                  options={optionsTypeConta}
                  value={typeConta}
                  onChange={(e) => {
                    setTypeConta(e.value);
                  }}
                  optionLabel="type"
                  optionValue="type"
                />
                <small className="form__errorMsg">
                  {errors.typeConta?.message}
                </small>
              </div>
              {typeConta === "Empresa" && (
                <div className="contaForm__field">
                  <label>
                    Empresa
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <span
                    onClick={(e) => {
                      setValue(
                        "idEmpresa",
                        editConta?.idEmpresa || empresaInput?.id
                      );
                    }}
                  >
                    <DropdownComponent
                      value={empresaInput}
                      setState={setEmpresaInput}
                      options={allEmpresas}
                      optionLabel="nomeFantasia"
                      filter
                      showClear
                      errorMessage={errors.idEmpresa?.message}
                      data-testid="empresaID"
                      setAlteracoes={setAlteracoes}
                      required={true}
                    />


                  </span>
                </div>
              )}
              {typeConta === "Parceiro" && (
                <div className="contaForm__field">
                  <label>
                    Parceiro
                    <abbr className="form__asterisk">*</abbr>
                  </label>
                  <span
                    onClick={(e) => {
                      setValue(
                        "idParceiro",
                        editConta?.idParceiro || parceiroInput?.id
                      );
                    }}
                  >
                    <DropdownComponent
                      value={parceiroInput}
                      setState={setParceiroInput}
                      options={allParceiros}
                      optionLabel="nomeTratamento"
                      filter
                      showClear
                      errorMessage={errors.idParceiro?.message}
                      data-testid="parceiroID"
                    />

                  </span>
                </div>
              )}
              <div className="contaForm__field">
                <label>
                  Tipo <abbr className="form__asterisk">*</abbr>{" "}
                </label>
                <DropdownComponent
                  value={classeInput}
                  setState={setClasseInput}
                  options={optionsClasse}
                  setAlteracoes={setAlteracoes}
                  optionLabel="name"
                  optionValue="value"
                  data-testid="classeID"
                  required={true}
                  validacao={register("classeInput")}
                />
                <small className="form__errorMsg">
                  {errors.classeInput?.message}
                </small>
              </div>

            </div>
            <div className="contaForm__section">
              <div className="contaForm__field">
                <label htmlFor="banco">
                  Banco{" "}
                  {classeInput !== "A" && (
                    <abbr className="form__asterisk">*</abbr>
                  )}
                </label>
                <DropdownComponent
                 value={nomeBancoInput}
                 setState={setNomeBancoInput}
                 options={allBanco}
                 optionLabel="nomeBanco"
                 optionValue="codBanco"
                 filter
                 showClear
                 errorMessage={errors.nomeBanco?.message}
                 setAlteracoes={setAlteracoes}
                />
              </div>
              <div className="contaForm__field">
                <label htmlFor="agencia">
                  Agência{" "}
                  {classeInput !== "A" && (
                    <abbr className="form__asterisk">*</abbr>
                  )}
                </label>
                <InputText
                  {...register("agencia")}
                  field="agencia"
                  value={agenciaInput}
                  onChange={(e) => {
                    setAgenciaInput(e.target.value);
                    setAlteracoes(true);
                  }}
                  maxlength="10"
                  data-testid="agenciaID"
                />
                <small className="form__errorMsg">
                  {errors.agencia?.message}
                </small>
              </div>
              <div className="contaForm__field">
                <label htmlFor="conta-corrente">
                  Número da Conta{" "}
                  {classeInput !== "A" && (
                    <abbr className="form__asterisk">*</abbr>
                  )}
                </label>
                <InputText
                  {...register("contaCorrente")}
                  field="conta-corrente"
                  value={contaCorrenteInput}
                  onChange={(e) => {
                    setContaCorrenteInput(e.target.value);
                    setAlteracoes(true);
                  }}
                  maxlength="40"
                  data-testid="contaCorrenteID"
                />
                <small className="form__errorMsg">
                  {errors.contaCorrente?.message}
                </small>
              </div>
            </div>
            <div className="contaForm__section">
              <div className="contaForm__field">
                <label>
                  Conta Principal <abbr className="form__asterisk">*</abbr>
                </label>
                <SelectButton
                  className="filter-select"
                  options={optionsConta}
                  value={contaPrincipalInput}
                  onChange={(e) => {
                    setContaPrincipalInput(e.value);
                    setAlteracoes(true);
                  }}
                  optionLabel="name"
                  optionValue="value"
                  data-testid="contaPrincipalID"
                />
                <small className="form__errorMsg">
                  {errors.principal?.message}
                </small>
              </div>

              <div className="contaForm__field">
                <label>
                  Status <abbr className="form__asterisk">*</abbr>
                </label>
                <SelectButton
                  className="filter-select"
                  options={optionsStatus}
                  value={ativaInput}
                  onChange={(e) => {
                    setAtivaInput(e.value);
                    setAlteracoes(true);
                  }}
                  optionLabel="name"
                  optionValue="value"
                  data-testid="ativaID"
                />
                <small className="form__errorMsg">
                  {errors.ativaInput?.message}
                </small>
              </div>
              <div className="contaForm__field">
                <label htmlFor="descricao">
                  Descrição <abbr className="form__asterisk">*</abbr>
                </label>
                <InputTextarea
                    {...register("descricao")}
                    rows={4}
                    cols={102}
                    value={descricaoInput}
                    onChange={(e) => {
                      setDescricaoInput(e.target.value);
                      setAlteracoes(true);
                    }}
                    field="descricao"
                    id="descricaoID"
                    maxLength="255"
                  />
                <small className="form__errorMsg">
                  {errors.descricao?.message}
                </small>
              </div>
            </div>
          </form>
          <div className="contaForm__containerButtons">
            {editConta ? (
              <ButtonComponent
                type="saldo"
                onClick={() => abrirSaldo()}
                datatesteid="buttonRateio"
              />
            ) : null}
            <ButtonComponent
              datatesteid="cancelButtonID"
              type="cancel"
              onClick={() => validateGoBack()}
            />
            <ButtonComponent
              datatesteid="confirmButtonID"
              type="confirm"
              onClick={handleSubmit(salvar)}
            />
          </div>
        </div>
        <SaldoConta
          setAbrirSaldoConta={setAbrirConta}
          abrirSaldoConta={abrirConta}
          contaCorrente={editConta}
          setLoadingComponent={setCarregandoRequisicao}
        />
      </div>
    </LoadingOverlayComponents>
  );
};
