import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import React from "react";
import { FormInputs } from "../FormInputs";
import { ModalAdicao } from "../ModalAdicao";
import "./styles.css";

export const Filtro = ({
  filterFields,
  setIsFiltroVisible,
  isFiltroVisible,
  clearFilter,
  handleConfirmFilter,
  salvarFiltro,
  setNomeFiltro,
  saveFilterDialog,
  setSaveFilterDialog,
  errorNomeFiltro
}) => {
  return (
    <Sidebar
      onKeyDown={(e) => e.key === "Enter" && handleConfirmFilter()}
      className="filter"
      onHide={() => {
        setIsFiltroVisible(false);
      }}
      visible={isFiltroVisible}
      position="right"
      data-testid="sidebarId"
    >
      <div className="filter__container">
        <h1 className="filter__title" id="filter__title">
          FILTRO
        </h1>

        <div className="filter__container-inputs">
          <FormInputs arrayInputs={filterFields} />
        </div>

        <div className="filter__buttons-container">
          <Button
            icon="pi pi-times-circle"
            className="filter__button filter__button--primary"
            label="Limpar"
            data-testid="buttonLimpar"
            onClick={clearFilter}
          />

          {salvarFiltro && (
            <Button
              className="filter__button filter__button--success"
              label="Salvar"
              onClick={() => {
                setSaveFilterDialog(true);
              }}
              icon="pi pi-save"
            />
          )}

          <Button
            className="filter__button filter__button--primary"
            icon="pi pi-search"
            label="Filtrar"
            onClick={handleConfirmFilter}
            id="buttonFilter"
            data-testid="filterButtonId"
          />
        </div>
      </div>
      <ModalAdicao
        title="Salvar Filtro"
        visible={saveFilterDialog}
        onHide={() => {
          setSaveFilterDialog(false)
          setNomeFiltro('')
        }}
        setVisible={setSaveFilterDialog}
        cancel={() => {
          setSaveFilterDialog(false)
          setNomeFiltro('')
        }}
        confirm={() => {
          salvarFiltro();
          setSaveFilterDialog(false); // Feche o modal após salvar
        }}
        message={errorNomeFiltro ? "Por favor, preencha ao menos 3 caracteres no Nome do Filtro" : ''}
        fields={[
          {
            label: "Para salvar por favor coloque um nome:",
            setState: setNomeFiltro,
          },
        ]}
      />
    </Sidebar>
  );
};
