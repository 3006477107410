import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import React, { useEffect, useRef, useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as yup from "yup";
import { deleteMeta, getMetaByNaturezaId, putPorcentagemMeta, saveMeta } from "../../api/index.js";
import { Input } from "../../components/Input/index.js";
import { ModalAdicao } from "../../components/ModalAdicao";
import { ModalComponents } from "../../components/ModalComponents";
import { messageRequired } from "../../default/messages.js";

export default function Metas({ setAlteracoesFeitas, setCarregandoRequisicao, carregandoRequisicao }) {
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [alteracoesMeta, setAlteracoesMeta] = useState(false);
  const [percentualAnterior, setPercentualAnterior] = useState(0);
  const [listaMetas, setListaMetas] = useState([]);
  const [percentual, setPercentual] = useState();
  const [idAtual, setIdAtual] = useState(0);
  const [anoEdicao, setAnoEdicao] = useState(0);
  const [percentualEdicao, setPercentualEdicao] = useState(0);
  const [meta, setMeta] = useState(0);
  const [metaPerc, setMetaPerc] = useState(0);
  const [ano, setAno] = useState();
  const { idNatureza } = useParams();
  // const pagina = 0;
  const toast = useRef(null);
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    pageCount: 1,
  });
  function retornaColumnAno(rowData) {
    return rowData.ano;
  }

  function retornaColumnMeta(rowData) {
    return rowData.percentual;
  }

  const validationPost = yup
    .object({
      ano: yup.string().required(messageRequired),
      percentual: yup.string().required(messageRequired),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });

  useEffect(() => {
    setValue("ano", ano);
    setValue("percentual", percentual);
  }, [ano, percentual, setValue]);

  const validationPut = yup
    .object({
      percentualEdicao: yup.string().required(messageRequired),
    })
    .required();

  const {
    register: registerPut,
    handleSubmit: handleSubmitPut,
    setValue: setValuePut,
    formState: { errors: error },
  } = useForm({
    resolver: yupResolver(validationPut),
  });

  function retornaButtons(rowData) {
    return (
      <>
        <Button
          className="opcoes-lupa p-button-secondary"
          icon="pi pi-pencil"
          style={{ color: "black" }}
          onClick={() => {
            setIdAtual(rowData.id);
            setAnoEdicao(rowData.ano);
            setPercentualEdicao(rowData.percentual);
            setPercentualAnterior(rowData.percentual);
            setShowModalUpdate(true);
          }}
          tooltip="Editar Natureza"
          tooltipOptions={{ position: "left" }}
        />
        <Button
          className="opcoes-lupa p-button-secondary"
          style={{ color: "red" }}
          icon="pi pi-times"
          onClick={() => {
            setIdAtual(rowData.id);
            setMeta(rowData.ano);
            setMetaPerc(rowData.percentual);
            setShowModalDelete(true);
          }}
          tooltip="Excluir"
          tooltipOptions={{ position: "left" }}
        />
      </>
    );
  }

  const modalExcluirMeta = [
    { label: "Ano:", value: meta },
    { label: "Meta:", value: metaPerc },
  ];

  async function handleDelete() {
    try {
      await deleteMeta(idAtual);
      getDataMetas();
      toast.current.show({
        severity: "success",
        summary: "Info",
        detail: "Meta excluída com sucesso!",
        life: 5000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao excluir meta.",
        life: 5000,
      });
    } finally {
      setShowModalDelete(false);
    }
  }

  async function handlePost() {
    setCarregandoRequisicao(true);
    try {
      const data = {
        ano,
        percentual,
        natureza: idNatureza,
      };
      let successMessage = "Meta criada com sucesso!";
      await saveMeta(data);
      toast.current.show({
        severity: "success",
        summary: "Info",
        detail: successMessage,
        life: 5000,
      });
      getDataMetas();
      setAno(0);
      setPercentual(0);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: error?.response?.data,
        life: 5000,
      });
    } finally {
      setCarregandoRequisicao(false);
    }
  }

  const verificarSeHaAlteracoes = useCallback(() => {
    if (percentualEdicao !== 0 && percentualEdicao !== percentualAnterior) {
      setAlteracoesMeta(true)
    } else {
      setShowModalUpdate(false)
    };
  }, [percentualEdicao, percentualAnterior]);

  const [message, setMessage] = useState(" ");

  const getDataMetas = useCallback(async () => {
    setCarregandoRequisicao(true);
    const requestParams = {
      paginationParams: lazyParams,
    };
    try {
      await getMetaByNaturezaId(idNatureza, requestParams).then((resultado) => {
        setListaMetas(resultado.content);
        setTotalRecords(resultado.totalElements);
        if (resultado.content.length === 0) {
          setMessage("Nenhum registro encontrado.");
        }
        console.log("resultadoresultadoresultado", resultado);
      });
    } catch (error) {
      console.log(error);
    } finally {
      setCarregandoRequisicao(false);
    }
  }, [idNatureza, lazyParams, setCarregandoRequisicao]);

  useEffect(() => {
    getDataMetas();
  }, [idNatureza, lazyParams, getDataMetas]);

  async function handlePut() {
    setCarregandoRequisicao(true);
    try {
      await putPorcentagemMeta(percentualEdicao, idAtual); // o 2 representa usuário ultAlt
      await getDataMetas();
      toast.current.show({
        severity: "success",
        summary: "Sucesso!",
        detail: "Meta alterada com sucesso!",
        life: 5000,
      });
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: "Erro ao editar meta.",
        life: 5000,
      });
    } finally {
      setCarregandoRequisicao(false);
      setShowModalUpdate(false);
    }
  }

  function handleSubmitForm() {
    handlePost();
  }

  useEffect(() => {
    setValuePut("percentualEdicao", percentualEdicao);
  }, [percentualEdicao, setValuePut]);

  const editFields = [
    { label: "Ano:", value: anoEdicao, setState: setAnoEdicao, disabled: true, style: { width: "80%" } },
    {
      label: "Meta (%):",
      type: "number",
      maxLength: 3,
      style: {
        width: "80%",
      },
      value: percentualEdicao,
      setState: setPercentualEdicao,
      required: true,
      validacao: { ...registerPut("percentualEdicao") },
      errorMessage: error.percentualEdicao?.message,
    },
  ];

  async function onPage(e) {
    await setLazyParams(e);
  }

  return (
    <div>
      <Toast ref={toast} />
      <ModalComponents
        title="a meta"
        visible={showModalDelete}
        onHide={() => setShowModalDelete(false)}
        onClick="delete"
        descricao={modalExcluirMeta}
        onClickCancelar={() => setShowModalDelete(false)}
        onClickConfirmar={() => handleDelete()}
      />

      <ModalAdicao
        title="Editar Meta"
        visible={showModalUpdate}
        setVisible={setShowModalUpdate}
        cancel={() => verificarSeHaAlteracoes()}
        fields={editFields}
        confirm={() => handlePut()}
        onSubmit={handleSubmitPut}
        onHide={() => setShowModalUpdate(false)}
        dialogStyle={{ width: "20vw" }}
        dialogStyleForm={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      />

      <ModalComponents
        visible={alteracoesMeta}
        onHide={() => {
          setAlteracoesMeta(false);
          setShowModalUpdate(false);
        }}
        onClickCancelar={() => {
          setAlteracoesMeta(false);
        }}
        onClickConfirmar={() => {
          setAlteracoesMeta(false);
          setShowModalUpdate(false);
        }}
        onClick="warning"
        descricao={[{ label: "Deseja descartar as alterações?" }]}
      />

      <form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className="containerMetas__inputs containerMetas__inputs--row">
          <Input
            register={register("ano")}
            type="number"
            label="Ano"
            name="ano"
            value={ano}
            placeholder="EX: 0000"
            maxLength={4}
            min={0}
            onChange={(e) => {
              if (e.target.value <= 9999) {
                setAlteracoesFeitas(true);
                setAno(e.target.value);
              }
            }}
            required
            errorMessage={errors.ano?.message}
          />
          <Input
            register={register("percentual")}
            type="number"
            label="Meta"
            name="percentual"
            value={percentual}
            placeholder="EX: 0%"
            maxLength={3}
            min={0}
            prefix="%"
            onChange={(e) => {
              if (e.target.value <= 100) {
                setAlteracoesFeitas(true);
                setPercentual(e.target.value);
              }
            }}
            required
            errorMessage={errors.percentual?.message}
          />
          <Button
            className="naturezaContainer__icon--create"
            icon="pi pi-plus"
            tooltip="Incluir meta"
            tooltipOptions={{ position: "left" }}
            type="submit"
          />
        </div>
      </form>
      <DataTable
        value={listaMetas}
        resizableColumns //para poder expandir a coluna
        columnResizeMode="expand" //expandir e não deformar as demais colunas.
        scrollable
        // loading={true}
        totalRecords={totalRecords}
        first={lazyParams.first}
        rows={lazyParams.rows}
        className="tableNatureza"
        paginator
        paginatorPosition="top"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        lazy
        onPage={onPage}
        emptyMessage={message}
      >
        <Column className="centralizar" header="Ano" body={retornaColumnAno} />
        <Column className="centralizar" header="Meta (%)" body={retornaColumnMeta} />
        <Column style={{ width: "130px" }} className="centralizar" header="Opções" body={retornaButtons} />
      </DataTable>
    </div>
  );
}
