import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { AiFillDislike, AiFillLike } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  addListaEmailsEnviados,
  esconderLoading,
  exibirLoading,
  setFiltroEmailsEnviados,
} from "../../actions";
import { getListaEmailsEnviadosFiltro } from "../../api";
import { FiltroEmailsEnviados } from "../../components/FiltroEmailsEnviados";
import { ReactHintComponents } from "../../components/ReactHint";
import { Table } from "../../components/Table";
import ReduxContext from "../../context/ReduxContext";
import { paginationDefault } from "../../default/pagination";
import { transformDateToEndOfDay, transformDateToStartOfDay } from "../../utils";
import { Navbar } from "../../components/Navbar";

export const EmailsEnviados = (props) => {
  const page = useSelector(state => state.filterPage?.page)
  console.log('pagepage', page)
  const dispatch = useDispatch();
  const toast = useRef(null);
  const listaEmailsEnviados = useSelector(
    (state) => state.grupoEconomico.listaEmailsEnviados
  );
  const [abrirFiltroEmailsEnviados, setAbrirFiltroEmailsEnviados] =
    useState(false);

  const dataInicio = useSelector(
    (state) => state.filtroEmailsEnviados.dataInicio
  );
  const reduxStateAtual = useSelector(state => state.filtroEmailsEnviados);
  const reduxStateInicial = useContext(ReduxContext);
  const [clear, setClear] = useState(false);
  const [visible, setVisible] = useState('');

  async function clearFilter() {
    setClear(true)
    dispatch(
      setFiltroEmailsEnviados({
        id: null,
        dataInicio: null,
        dataFim: null,
        mensagem: null,
        status: null
      })
    );
    setVisible(false)
  }

  const dataFim = useSelector((state) => state.filtroEmailsEnviados.dataFim);
  const id = useSelector((state) => state.filtroEmailsEnviados.id);
  const mensagem = useSelector((state) => state.filtroEmailsEnviados.mensagem);
  const status = useSelector((state) => state.filtroEmailsEnviados.status);
  const history = useHistory();
  const recarregando = useSelector(
    (state) => state.movimentacaoListaLancamento.loading
  );
  const [totalRecords, setTotalRecords] = useState();
  const [lazyParams, setLazyParams] = useState(page || paginationDefault);

  useEffect(() => {
    if (page) {
      setLazyParams(page)
    }
  }, [props, page])

  const selecionarDetalhesEmail = (rowData) => {
    history.push(`/emails-enviados/${rowData.id}/detalhes`, {
      id: rowData.id,
      type: "emailsEnviados",
      page: lazyParams
    });
  };

  const principal = (rowData) => {
    if (rowData.status === "OK" || rowData.status === "ok") {
      return (
        <Button
          className="formattedStyles"
          tooltip="Sucesso no Envio de E-mail"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillLike
            data-testid="like-button"
            className="formattedStyles--check"
            alt="Ícone de sucesso"
            size={15}
          />
        </Button>
      );
    } else {
      return (
        <Button
          data-testid="dislike-button"
          className="formattedStyles"
          tooltip="Erro no Envio de E-mail"
          tooltipOptions={{ position: "left" }}
        >
          <AiFillDislike
            className="formattedStyles--times"
            alt="Ícone de erro"
            size={15}
          />
        </Button>
      );
    }
  };

  const filtroListaEmailsEnviados = useCallback(() => {
    dispatch(exibirLoading());
    const filtroEmailsEnviados = {
      id,
      dataInicio: dataInicio && transformDateToStartOfDay(dataInicio),
      dataFim: dataFim && transformDateToEndOfDay(dataFim),
      mensagem,
      status,
    };

    const requestParams = {
      params: filtroEmailsEnviados,
      paginationParams: lazyParams,
    };
    console.log('lazyParamslazyParams', requestParams?.paginationParams?.page)

    getListaEmailsEnviadosFiltro(requestParams).then((resultado) => {
      if (resultado.length === 0) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "O filtro não obteve resultado",
        });
        dispatch(addListaEmailsEnviados([]));
      } else {
        dispatch(addListaEmailsEnviados(resultado));
      }
      dispatch(addListaEmailsEnviados(resultado?.content));
      setTotalRecords(resultado.totalElements);
    })
      .catch((error) => {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: "O filtro não obteve resultado",
        });
        dispatch(addListaEmailsEnviados([]));
      })
      .finally(() => {
        dispatch(esconderLoading());
      });
    setAbrirFiltroEmailsEnviados(false);
  }, [
    id,
    dataInicio,
    dataFim,
    mensagem,
    status,
    lazyParams,
    dispatch,
  ]);

  useEffect(() => {
    filtroListaEmailsEnviados();
  }, [lazyParams, filtroListaEmailsEnviados]);

  const columns = [
    {
      field: "id",
      header: "Código",
      style: { maxWidth: "8vw" }
    },
    {
      field: "dataHoraEvento",
      header: "Data / Hora",
      style: { maxWidth: "11vw" },
    },
    {
      field: "mensagem",
      header: "Mensagem",
      style: { maxWidth: "62vw" },
      type: "customEllipsis",
    },
    {
      field: "status",
      header: "Status",
      style: { maxWidth: "8vw" },
      type: "custom",
      customFunc: principal,
    },
  ];
  console.log('detalhesEmailsEnviadosdetalhesEmailsEnviados', listaEmailsEnviados)

  return (
    <>
      <Navbar />
      <Toast ref={toast} />
      <ReactHintComponents />
      <Table
        arrayData={listaEmailsEnviados}
        loading={recarregando}
        headerTitle="E-mails Enviados"
        columns={columns}
        onClickFiltrar={() => setAbrirFiltroEmailsEnviados(true)}
        onClickDetails={(rowData) => selecionarDetalhesEmail(rowData)}
        tooltipEdit={"do Email"}
        lazyParams={lazyParams}
        setLazyParams={setLazyParams}
        totalRecords={totalRecords}
        visible={visible}
        onClickClear={() => clearFilter()}
        details={"email"}
      />
      <FiltroEmailsEnviados
        clear={clear}
        setVisible={setVisible}
        setLazyParamsProp={setLazyParams}
        abrirFiltroEmailsEnviados={abrirFiltroEmailsEnviados}
        setAbrirFiltroEmailsEnviados={setAbrirFiltroEmailsEnviados}
        reduxStateAtual={reduxStateAtual}
        reduxStateInicial={reduxStateInicial?.filtroEmailsEnviados}
      />
    </>
  );
};
