import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import {
  deletarRateio,
  getByIdRateio,
  postRateio,
  getAllParceiros,
  getListaNaturezaHierarquica
} from "../../api";
import { messageRequired } from "../../default/messages";
import { CurrencyInput } from "../FormInputs/CurrencyInput";
import { LoadingOverlayComponents } from "../LoadingOverlayComponents";
import { ModalComponents } from "../ModalComponents";
import { ReactComponent as Icon } from "./iconBorracha.svg";
import "./styles.css";
import { Dropdown } from "primereact/dropdown";
import { Tooltip } from 'primereact/tooltip';

export const RateioTelaEditarAdicLista = ({
  abrirRateio,
  setAbrirRateio,
  movimentacao,
}) => {
  const toast = useRef(null);
  const [rateio, setRateio] = useState([]);
  const [valorDesdobramento, setValorDesdobramento] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [naturezaHierarquia, setNaturezaHierarquia] = useState("");
  const [descricao, setDescricao] = useState(null);
  const [nomeParceiro, setNomeParceiro] = useState("");
  //const [nomeProjeto, setNomeProjeto] = useState("");
  const [percentual, setPercentual] = useState(null);
  const [valorRateio, setValorRateio] = useState(null);
  const [excluirRateio, setExcluirRateio] = useState("");
  const [dialogExcluirRateio, setDialogExcluirRateio] = useState(false);
  const [dialogIdRateio, setDialogIdRateio] = useState("");
  const [dialogDescricaoRateio, setDialogDescricaoRateio] = useState("");
  const [movimentacaoAtualizada, setMovimentacaoAtualizada] = useState([]);
  const [carregandoRequisicao, setCarregandoRequisicao] = useState();
  const [alteracoesFeitas, setAlteracoesFeitas] = useState(false);
  const [buscandoInfo, setBuscandoInfo] = useState(true);

  //const [listaProjeto, setListaProjeto] = useState([]);
  const [listaParceiro, setListaParceiro] = useState([]);
  const [listaNatureza, setListaNatureza] = useState([]);

  const validationPost = yup.object({
    valorRateio: yup.string().required(messageRequired),
    percentual: yup.string().required(messageRequired),
    descricao: yup.string().required(messageRequired),
    nomeParceiro: yup.string().required(messageRequired),
    nomeNaturezaHierarquica: yup.string().required(messageRequired),
  }).required("");

  async function validateGoBack() {
    const valorARatear = aRatear();
    
    if (valorARatear !== 0) {
      setAlteracoesFeitas(true); // Exibe o popup de confirmação
    } else {
      setAbrirRateio(false); // Fecha a tela diretamente se os valores forem iguais
      await limparInput();
    }
  }

  
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationPost),
  });



  let history = useHistory();

  function excluirLinhaRateio(rowData) {
    return (
      <div>
        <Button
          className="table__opcoes--red"
          icon="pi pi-times"
          tooltip="Excluir"
          onClick={() => {
            setDialogIdRateio(rowData?.id);
            setDialogDescricaoRateio(rowData?.descricao);
            setDialogExcluirRateio(true);
            setExcluirRateio(rowData);
          }}
          tooltipOptions={{ position: "left" }}
        />
      </div>
    );
  }

  function percentualRateio(rateio) {
    if (rateio?.percentual >= 0 && rateio?.percentual !== null) {
      return (
        <>
          <CurrencyFormat
            value={rateio?.percentual.toFixed(2)}
            displayType={"text"}
            percent={"%"}
            suffix="%"
          />
        </>
      );
    }
  }

  function formatarValortabela(movimentacao) {
    if (
      movimentacao?.valorRateio &&
      (movimentacao?.valorRateio <= 0 || movimentacao?.valorRateio >= 0)
    ) {
      return (
        <>
          <CurrencyFormat
            value={movimentacao?.valorRateio}
            displayType={"text"}
            thousandSeparator="."
            decimalScale={2}
            decimalSeparator={","}
            prefix={"R$"}
          />
        </>
      );
    }
  }

  const accRef = useRef(0);

  const rateado = useCallback(() => {
    let rateios = rateio;
    if (null != rateios) {
      let acc = 0;
      for (let rateio of rateios) {
        acc += rateio.valorRateio;
      }
      accRef.current = acc;
      return accRef.current;
    }
  }, [rateio]);

  const aRatear = useCallback(() => {
    let aRatear = 0;
    let vlrDesdobramento = valorDesdobramento;

    if (null != aRatear) {
      aRatear = vlrDesdobramento - accRef.current;
    }

    return aRatear;
  }, [valorDesdobramento]);

  async function incluirRateio() {
    setCarregandoRequisicao(true);
    const rateioPost = {
      descricao: descricao,
      movimentacao: {
        id: movimentacao.id,
        tipoLancamento: -1,
        valorLiquido: movimentacao.valorLiquido,
      },
      natureza: {
        id: naturezaHierarquia.idNatureza,
      },
      // naturezaHierarquia: naturezaHierarquia.naturezaHierarquica,
      parceiro: {
        id: nomeParceiro.id,
        nomeTratamento: nomeParceiro.nomeTratamento,
      },
      percentual: parseFloat(percentual),
      valorRateio: parseFloat(valorRateio),
    };

    await postRateio(rateioPost)
      .then((resultado) => {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Rateio criado com sucesso!",
        });
        rateio.push(resultado);
        history.push(resultado);
        limparInput();
      })
      .catch((e) => {
        const errorResponse = e.response.data;
        const errorMessage = errorResponse.userMessage;
        toast.current.show({
          severity: "error",
          summary: "Erro",
          detail: errorMessage ?? "Não foi possível ratear a movimentação",
          life: 8000
        });
      })
      .finally(() => {
        setCarregandoRequisicao(false);
      });
  }

  const limparInput = () => {
    setValorRateio("");
    setPercentual("");
    setNomeParceiro(null);
    setDescricao("");
    setNaturezaHierarquia(null);
  };

  const deleteConta = async (rowData) => {
    try {
      const status = await deletarRateio(rowData.id);
      if (status === 204) {
        toast.current.show({
          severity: "success",
          summary: "Sucesso!",
          detail: "Rateio excluído com sucesso!",
        });
        let targetIndex = rateio.findIndex((each) => each.id === rowData.id);
        rateio.splice(targetIndex, 1);
        setMovimentacaoAtualizada(rateio);
        setDialogExcluirRateio(false);
      }
    } catch (e) {
      const mensagem = e.response.data.message;
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${mensagem}`,
        life: 8000,
      });
    }
  };

  function porcentagemDinamica(inputValorRateio) {
    let vlrDesdobramento = rateio ? valorDesdobramento : null;
    let Porcentagem = 0;

    if (inputValorRateio !== 0 && inputValorRateio !== null)
      Porcentagem = (inputValorRateio / vlrDesdobramento) * 100;
    setValue("percentual", Porcentagem.toFixed(2));
    return setPercentual(Porcentagem.toFixed(2));
  }

  useEffect(() => {
    rateado();
    aRatear();
  }, [aRatear, rateado]);

  const modalExcluir = [
    { label: "Id:", value: dialogIdRateio },
    { label: "Descrição:", value: dialogDescricaoRateio },
  ];

  function validadionTypeMovimentacao() {
    try {
      return rateio ? rateio : movimentacaoAtualizada?.listaRateio;
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (nomeParceiro?.nomeTratamento !== undefined) {
      setValue("nomeParceiro", nomeParceiro?.nomeTratamento);
    } else {
      setValue("nomeParceiro", undefined);
    }
  }, [nomeParceiro, setValue]);


  useEffect(() => {
    if (naturezaHierarquia?.naturezaHierarquica !== undefined) {
      setValue(
        "nomeNaturezaHierarquica",
        naturezaHierarquia?.naturezaHierarquica
      );
    } else {
      setValue("nomeNaturezaHierarquica", undefined);
    }
  }, [naturezaHierarquia, setValue]);

  const getRateio = useCallback(async () => {
    setCarregandoRequisicao(true);
    await getByIdRateio(movimentacao?.id).then((resultado) => {
      setValorDesdobramento(resultado.desdobramento);
      setRateio(resultado.rateios);
      setCarregandoRequisicao(false);
    });
    setBuscandoInfo(false);
  }, [movimentacao]);

  useEffect(() => {
    if (movimentacao?.id !== undefined) {
      setBuscandoInfo(true);
      getRateio();
    }
  }, [movimentacao, getRateio]);

  function handleYupValidationOnChange({ setState, value, yupName }) {
    setState(value);
    setValue(yupName, value);
  }

  useEffect(() => {
    async function fetchParceiros() {
      try {
        const parceiros = await getAllParceiros();
        setListaParceiro(parceiros);

        if (nomeParceiro) {
          const parceiroSelecionado = parceiros.find(
            (parceiro) => parceiro.id === nomeParceiro.id
          );
          if (parceiroSelecionado && parceiroSelecionado.id !== nomeParceiro.id) {
            setNomeParceiro(parceiroSelecionado);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar parceiros:", error);
      }
    }

    fetchParceiros();
  }, [nomeParceiro]);

  useEffect(() => {
    async function fetchNaturezas() {
      try {
        const naturezas = await getListaNaturezaHierarquica();
        setListaNatureza(naturezas);

        if (naturezaHierarquia) {
          const naturezaSelecionada = naturezas.find(
            (natureza) => natureza.idNatureza === naturezaHierarquia.idNatureza
          );
          if (naturezaSelecionada && naturezaSelecionada.idNatureza !== naturezaHierarquia.idNatureza) {
            setNaturezaHierarquia(naturezaSelecionada);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar naturezas:", error);
      }
    }

    fetchNaturezas();
  }, [naturezaHierarquia]);

  const modalWarning = [{ label: "Você ainda não concluiu o rateio. Deseja sair e deixar pendência?" }];

  return (
    <>
      <Toast ref={toast} />
      <Dialog
        className="modalRateio"
        header="Ratear"
        modal={true}
        visible={abrirRateio}
        onHide={() => validateGoBack()}
        data-testid="modalRateio_component"
      >
        <ModalComponents
          visible={alteracoesFeitas}
          onHide={() => setAlteracoesFeitas(false)}
          onClickCancelar={() => setAlteracoesFeitas(false)}
          onClickConfirmar={() => {
            setAbrirRateio(false);
            setAlteracoesFeitas(false);
            limparInput();
          }}
          onClick="warning"
          descricao={modalWarning}
        />
        {/* <form onSubmit={handleSubmit(incluirRateio)}> */}
          <div className="modalRateio__header">
            <Button
              className="modalRateio__header__buttons modalRateio__header__buttons--trash"
              icon={<Icon />}
              tooltip="Limpar campos"
              type="button"
              onClick={() => limparInput()}
              data-testid="limparCamposID"
            />
          </div>
          <InputText
            className="modalRateio__filter"
            type="search"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Pesquisar"
            data-testid="pesquisarID"
          />
          <div className="modalRateio__fields">
            <div className="modalRateio__fields__flex">
              <div className="modalRateio__fields__flex__section">
                <label>
                  Valor<span className="form__asterisk">*</span>
                </label>
                <CurrencyInput
                  datatestid="valorId"
                  value={valorRateio}
                  setValue={(value) => {
                    handleYupValidationOnChange({
                      setState: setValorRateio,
                      value: value,
                      yupName: "valorRateio",
                    });
                    porcentagemDinamica(value);
                  }}
                />
                <small className="form__errorMsg">
                  {errors.valorRateio?.message}
                </small>
              </div>
              <div className="modalRateio__fields__flex__section">
                <label>
                  Porcentagem<span className="form__asterisk">*</span>
                </label>
                <InputNumber
                  {...register("percentual")}
                  value={percentual}
                  disabled
                  suffix="%"
                  placeholder="0.00%"
                  data-testid="porcentagemID"
                />
                <small className="form__errorMsg">
                  {errors.percentual?.message}
                </small>
              </div>
            </div>
            <div className="modalRateio__fields__flex__section">
              <label>
                Histórico<span className="form__asterisk">*</span>
              </label>
              <InputTextarea
                value={descricao}
                onChange={(e) => {
                  handleYupValidationOnChange({
                    setState: setDescricao,
                    value: e.target.value,
                    yupName: "descricao",
                  });
                }}
                field="descricao"
                data-testid="historicoID"
              />
              <small className="form__errorMsg">
                {errors.descricao?.message}
              </small>
            </div>
            <div>
              <div className="modalRateio__fields__flex__section">
                <label>
                  Parceiro<span className="form__asterisk">*</span>
                </label>
                <Dropdown
                  value={nomeParceiro}
                  onChange={(e) => {
                    setNomeParceiro(e.value);
                  }}
                  options={listaParceiro}
                  optionLabel="nomeTratamento"
                  filter={true}
                  className="p-dropdown"
                  showClear
                />

                <small className="form__errorMsg">
                  {errors.nomeParceiro?.message}
                </small>
              </div>
              <div className="modalRateio__fields__flex__section">
                <label>
                  Natureza<span className="form__asterisk">*</span>
                </label>
                <Dropdown
                  value={naturezaHierarquia}
                  onChange={(e) => {
                    setNaturezaHierarquia(e.value);
                  }}
                  options={listaNatureza}
                  optionLabel="naturezaHierarquica"
                  filter={true}
                  className="p-dropdown"
                  showClear
                />
                <small className="form__errorMsg">
                  {errors.nomeNaturezaHierarquica?.message}
                </small>
              </div>
            </div>
          </div>
       
        <LoadingOverlayComponents
          active={carregandoRequisicao}
          spinner
          text="Carregando..."
        >
          <div className="modalRateio__table">
            <DataTable
              value={validadionTypeMovimentacao()}
              resizableColumns
              columnResizeMode="expand"
              scrollable
              responsive
              className="modalRateio__dataTable"
              globalFilter={globalFilter}
              data-testid="tableID"
            >
              <Column
                style={{ width: "10vw" }}
                header="Valor"
                className="centralizar"
                field="valorRateio"
                body={formatarValortabela}
                data-testid="valorTableID"
              />

              <Column
                style={{ width: "10vw" }}
                header="Porcentagem"
                className="centralizar"
                field="percentual"
                body={percentualRateio}
                data-testid="porcentagemTableID"
              />
              <Column
                style={{ width: "15vw" }}
                header="Histórico"
                className="centralizar"
                field="descricao"
                body={(rowData) => (
                  <div style={{ maxWidth: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    <span id={`descricao-${rowData.id}`}>
                      {rowData.descricao ? rowData.descricao : 'Sem descrição'}
                    </span>
                    <Tooltip target={`#descricao-${rowData.id}`} content={rowData.descricao ? rowData.descricao : 'Sem descrição'} />
                  </div>
                )}
                data-testid="historicoTableID"
              />

              <Column
                style={{ width: "15vw" }}
                header="Natureza"
                className="centralizar"
                field="naturezaHierarquia"
                
                body={(rowData) => (
                  <div style={{ maxWidth: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>
                    <span id={`natureza-${rowData.id}`}>{rowData.naturezaHierarquia}</span>
                    <Tooltip target={`#natureza-${rowData.id}`} content={rowData.naturezaHierarquia} />
                  </div>
                )}
                data-testid="naturezaTableID"
              />
              <Column
                style={{ width: "15vw" }}
                header="Parceiro"
                className="centralizar"
                field="parceiro.nomeTratamento"
                data-testid="parceiroTableID"
                body={(rowData) => (
                  <div>
                    <span id={`parceiro-${rowData.id}`}>{rowData.parceiro.nomeTratamento}</span>
                    <Tooltip target={`#parceiro-${rowData.id}`} content={rowData.parceiro.nomeTratamento} />
                  </div>
                )}
              />

              <Column
                style={{ width: "7.5vw" }}
                header="Excluir"
                className="centralizar"
                body={excluirLinhaRateio}
                data-testid="excluirTableID"
              />
            </DataTable>
          </div>
        </LoadingOverlayComponents>
        <hr></hr>
        <div className="modalRateio__footer ">
        <div className="modalRateio__values">
          {buscandoInfo ? null :
            <span>
              Rateado:
              <CurrencyFormat
                value={rateado()}
                displayType={"text"}
                thousandSeparator="."
                decimalScale={2}
                decimalSeparator={","}
                prefix="R$"
                className="modalRateio__values--spacing"
                data-testid="rateadoID"
              />
            </span>
          }
          {buscandoInfo ?
            <p>Buscando Informações</p>
            :
            <span>
              A Ratear:
              <CurrencyFormat
                value={aRatear()}
                displayType={"text"}
                thousandSeparator="."
                decimalScale={2}
                decimalSeparator={","}
                prefix="R$"
                className="modalRateio__values--spacing"
                data-testid="aRatearID"
              />
            </span>
          }
          {buscandoInfo ? null :
            <span>
              Valor Total:
              {valorDesdobramento && (
                <CurrencyFormat
                  value={valorDesdobramento}
                  displayType={"text"}
                  thousandSeparator="."
                  decimalScale={2}
                  decimalSeparator={","}
                  prefix="R$"
                  className="modalRateio__values--spacing"
                  data-testid="valorTotalID"
                />
              )}
            </span>
          }
       
        </div>
        <div className="modalRateio__actions">
          <Button
            className="modalRateio__header__buttons modalRateio__header__buttons--plus"
            icon="pi pi-plus"
            label="Incluir"
            // tooltip="Incluir Rateio"
            data-testid="incluirRateioID"
            onClick={handleSubmit(incluirRateio)}
            
          />
          <Button
            className="modalRateio__button--return"
            icon="pi pi-arrow-left"
            label="Voltar"
            data-testid="validateGoBackID"
            onClick={() => validateGoBack()}
          />
        </div>
        </div>
        {/* </form> */}

      </Dialog>
      <ModalComponents
        title="Rateio"
        onClick="delete"
        descricao={modalExcluir}
        visible={dialogExcluirRateio}
        onHide={() => setDialogExcluirRateio(false)}
        onClickCancelar={() => setDialogExcluirRateio(false)}
        onClickConfirmar={() => deleteConta(excluirRateio)}
        datatesteidconfirm="excluirRateio Modal"
      />
    </>
  );
};
