import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});


export const listaMunicipioPorUf = (id) =>
  axios.get(`${url}/municipio/uf/${id}`).then((response) => response.data);


export const getAllUf = () =>
  axios.get(`${url}/uf`).then((response) => response.data);

  export const listaPorIdPais = (id) =>
  axios.get(`${url}/pais/${id}`).then((response) => response.data);


export const getAllPais = () =>
  axios.get(`${url}/pais`).then((response) => response.data);
