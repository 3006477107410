import axios from "axios";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`
});

export const deleteNatureza = (idNatureza) =>
  axios.delete(`${url}/naturezas/${idNatureza}`).then((response) => response.data);

export const getNaturezaByNomeNaturezaHierarquica = (nomeNaturezaHierarquica) =>
  axios
    .get(`${url}/naturezas/natureza-hierarquica/${nomeNaturezaHierarquica}`)
    .then((response) => response.data);

export const getNaturezaByNomeNaturezaHierarquicaEAtiva = (nomeNaturezaHierarquica) =>
  axios
    .get(`${url}/naturezas/natureza-hierarquica-ativo/${nomeNaturezaHierarquica}`)
    .then((response) => response.data);

export const getNaturezaByNome = (nomeNatureza) =>
  axios.get(`${url}/naturezas/natureza/${nomeNatureza}`).then((response) => response.data);

export const getNaturezaByDescricao = (descricao) =>
  axios.get(`${url}/naturezas/filtro-descricao/${descricao}`).then((response) => response.data);

export const getNaturezaByDescricaoEAtivo = (descricao) =>
  axios
    .get(`${url}/naturezas/filtro-descricao-ativo/${descricao}`)
    .then((response) => response.data);

export const getAllFatherNature = () =>
  axios.get(`${url}/naturezas/pai`).then((response) => response.data);

export const getNaturezaByIdNaturezaHierarquica = (idNatureza) =>
  axios
    .get(`${url}/naturezas/natureza-hierarquica-Id/${idNatureza}`)
    .then((response) => response.data);

export const getAllNatureza = () => axios.get(`${url}/naturezas`).then((response) => response.data);

export const getNaturezaById = (idNatureza) =>
  axios.get(`${url}/naturezas/${idNatureza}`).then((response) => response.data);

export const saveNatureza = (natureza) =>
  axios.post(`${url}/naturezas`, natureza).then((response) => response.data);

export const updateNatureza = (natureza, idNatureza) =>
  axios.put(`${url}/naturezas/${idNatureza}`, natureza).then((response) => response.data);

export const updateNaturezaPai = (id, idPai) =>
  axios.put(`${url}/naturezas/${id}/pai/${idPai}`).then((response) => response.data);

export const getListaNaturezaHierarquica = () =>
  axios.get(`${url}/naturezas/hierarquica`).then((response) => response.data);
