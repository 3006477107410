import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Truncate from "react-truncate";
import { setPage } from "../../actions";
import { ButtonComponent } from "../ButtonComponent";
import "./style.css";

export const Table = ({
  arrayData,
  loading,
  columns,
  headerTitle,
  onClickAdicionar,
  onClickFiltrar,
  onClickDetails,
  onClickEdit,
  onClickDelete,
  disabledDelete,
  selectionMode,
  selection,
  metaKeySelection,
  dataKey,
  setId,
  handleCheck,
  headerComponent,
  rowClassName,
  tooltipEdit,
  tooltipDetails,
  onClickPortions,
  onClickPortions2,
  onClickDownload,
  onClickEditFile,
  onClickVoltar,
  onClickContrato,
  onClickProvParcela,
  visualizarContrato,
  onClickRateioIdemVlrDesd,
  onClickRateioDifVlrDesdIdemRat,
  onClickRateioIdemZero,
  onClickSaldo,
  onClickMovimentacao,
  data,
  onClickSendEmail,
  onClickHeaderInfo,
  headerInfoHoverMessage,
  lazyParams,
  setLazyParams,
  totalRecords,
  onClickClear,
  visible,
  messageButton,
  onClickVoltarCustom,
  path,
  page,
  details
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const checkTable = (rowData, field) => {
    if (rowData[field] === "S") {
      return (
        <Button
          tooltip="ATIVA"
          className="formattedStyles formattedStyles--check"
          icon="pi pi-check"
        />
      );
    } else {
      return (
        <Button
          tooltip="INATIVA"
          className="formattedStyles formattedStyles--times"
          icon="pi pi-times"
        />
      );
    }
  };

  const renderColumns = columns.map((col) => {
    const ellipsis = (rowData, field) => {
      const nestedFields = field.split(".");
      let value = rowData;

      for (let nestedField of nestedFields) {
        if (value && typeof value === "object" && nestedField in value) {
          value = value[nestedField];
        } else {
          value = null;
          break;
        }
      }

      return (
        <>
          {rowData && field !== null ? (
            <Truncate data-rh-at="right" lines={1} ellipsis={<span>...</span>} className="ellipsis">
              {value}
            </Truncate>
          ) : null}
        </>
      );
    };

    switch (col?.type) {
      case "check":
        return (
          <Column
            className="centralizar"
            bodyClassName="column-body"
            body={(rowData) => checkTable(rowData, col.field)}
            field={col.field}
            header={col.header}
            style={col.style}
            data-testid="checkTest"
          />
        );
      case "checkbox":
        return (
          <Column
            className="centralizar"
            bodyClassName="column-body"
            body={(rowData) => checkTable(rowData, col.field)}
            field={col.field}
            header={col.header}
            style={col.style}
            selectionMode="multiple"
          />
        );
      case "custom":
        const func = col?.customFunc;
        return (
          <Column
            className="centralizar"
            bodyClassName="column-body"
            body={(rowData) => func(rowData, col?.field)}
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "customRight":
        const funcCustomRight = col?.customFunc;
        return (
          <Column
            bodyClassName="column-body"
            className="alignLeft"
            body={(rowData) => funcCustomRight(rowData, col?.field)}
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "right":
        return (
          <Column
            bodyClassName="column-body"
            className="alignLeft"
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "customLeft":
        const funcLeft = col?.customFunc;
        return (
          <Column
            bodyClassName="column-body"
            body={(rowData) => funcLeft(rowData, col?.field)}
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "customEllipsis":
        return (
          <Column
            className="centralizar"
            bodyClassName="column-body"
            body={(rowData) => ellipsis(rowData, col.field)}
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "customEllipsisLeft":
        return (
          <Column
            bodyClassName="column-body"
            body={(rowData) => ellipsis(rowData, col.field)}
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "customEllipsisLeftWithTooltip":
        return (
          <Column
            bodyClassName="column-body"
            body={(rowData) => (
              <Button className="cellWithTooltip" tooltip={getFieldValue(rowData, col.field)}>
                {ellipsis(rowData, col.field)}
              </Button>
            )}
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      case "left":
        return (
          <Column
            bodyClassName="column-body"
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
      default:
        return (
          <Column
            className="centralizar"
            bodyClassName="column-body"
            field={col.field}
            header={col.header}
            style={col.style}
          />
        );
    }
  });

  function opcoesListaBotoes(rowData) {
    let ValorDesdobramentoo = rowData?.desdobramento;
    let rateadoo = rowData?.valorTotalRateios;

    if (rateadoo === ValorDesdobramentoo) {
      return "Verde";
    } else if (rateadoo !== ValorDesdobramentoo && rateadoo !== 0) {
      return "Vermelho";
    } else if (rateadoo === 0) {
      return "Preto";
    }
  }

  const opcoesBody = (rowData) => {
    
    return (
      <div className="table__opcoes">
        {onClickContrato?.map((e) =>
          e.id === rowData.id ? (
            rowData?.parcelaContrato?.contrato?.idContrato ? (
              <Button
                className="table__opcoes--contrato"
                tooltip="Visualizar contrato vinculado a essa Movimentação."
                tooltipOptions={{ position: "left" }}
                onClick={() => {
                  visualizarContrato(rowData);
                }}
              >
                C
              </Button>
            ) : (
              <Button
                className="table__opcoes--contratoNull"
                tooltip="Movimentação sem contrato vinculado."
                tooltipOptions={{ position: "left" }}
              >
                C
              </Button>
            )
          ) : null
        )}
        {onClickDetails &&
          (tooltipEdit === "Parcela" ? (
            <>
              <Button
                style={{
                  background: "transparent",
                  border: "none",
                  marginRight: "-17px",
                  margin: "-15px"
                }}
                tooltip={
                  rowData.statusParcela === "EM ABERTO" || rowData.statusParcela === "ABERTO"
                    ? tooltipDetails
                    : ""
                }
                tooltipOptions={{ position: "left" }}
              >
                <Button
                  className="table__opcoes--black"
                  onClick={() => {
                    onClickDetails(rowData);
                  }}
                  style={{ background: "transparent", border: "none" }}
                  icon="pi pi-search"
                  tooltip={
                    rowData.statusParcela === "EM ABERTO" || rowData.statusParcela === "ABERTO"
                      ? tooltipDetails
                      : "Detalhes " + (messageButton ? messageButton : tooltipEdit)
                  }
                  tooltipOptions={{ position: "left" }}
                  data-testid="detailsTest"
                  disabled={
                    rowData.statusParcela === "EM ABERTO" || rowData.statusParcela === "ABERTO"
                  }
                />
              </Button>
            </>
          ) : (
            <Button
              className="table__opcoes--black"
              onClick={() => onClickDetails(rowData)}
              icon="pi pi-search"
              tooltip={"Detalhes " + tooltipEdit}
              tooltipOptions={{ position: "left" }}
            />
          ))}
        {onClickEdit && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-pencil"
            tooltip={"Editar " + tooltipEdit}
            tooltipOptions={{ position: "left" }}
            onClick={() => onClickEdit(rowData)}
            data-testid="editTest"
          />
        )}
        {onClickRateioIdemVlrDesd && opcoesListaBotoes(rowData) === "Verde" && (
          <Button
            className="table__opcoes--green"
            icon="pi pi-dollar"
            onClick={() => onClickRateioIdemVlrDesd(rowData)}
            tooltip="Rateio"
            tooltipOptions={{ position: "left" }}
            data-testid="rateioIdemVlrDesd"
          />
        )}
        {onClickRateioDifVlrDesdIdemRat && opcoesListaBotoes(rowData) === "Vermelho" && (
          <Button
            className="table__opcoes--rateio"
            icon="pi pi-dollar"
            onClick={() => onClickRateioDifVlrDesdIdemRat(rowData)}
            tooltip="Rateio"
            tooltipOptions={{ position: "left" }}
            data-testid="rateioDifVlrDesdIdemRat"
          />
        )}
        {onClickRateioIdemZero && opcoesListaBotoes(rowData) === "Preto" && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-dollar"
            onClick={() => onClickRateioIdemZero(rowData)}
            tooltip="Rateio"
            tooltipOptions={{ position: "left" }}
            data-testid="rateioIdemZero"
          />
        )}
        {onClickPortions && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-paperclip"
            tooltip="Anexos"
            onClick={() => onClickPortions(rowData)}
            tooltipOptions={{ position: "left" }}
            data-testid="portionsTest"
          />
        )}
        {onClickMovimentacao && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-paperclip"
            tooltip="Anexos"
            onClick={() => onClickMovimentacao(rowData)}
            tooltipOptions={{ position: "left" }}
            data-testid="movimentacaoTest"
          />
        )}
        {onClickPortions2 && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-credit-card"
            tooltip="Parcelas"
            onClick={() => onClickPortions2(rowData)}
            data-testid="portions2Test"
          />
        )}
        {onClickDownload && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-download"
            tooltip="Download"
            onClick={() => onClickDownload(rowData)}
            data-testid="downloadTest"
          />
        )}
        {onClickEditFile && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-pencil"
            tooltip="Editar Anexo"
            onClick={() => onClickEditFile(rowData)}
            data-testid="editFileTest"
          />
        )}
        {onClickSaldo && (
          <Button
            className={`table__opcoes--black ${rowData.temSaldo ? 'button-with-saldo' : ''}`}
         
            icon="pi pi-money-bill"
            tooltip="Cadastro de Saldo"
            onClick={() => onClickSaldo(rowData)}
            data-testid="editFileTest"
          />
        )}
        {onClickProvParcela && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-briefcase"
            tooltipOptions={{ position: "left" }}
            tooltip={rowData?.idMovimentacao ? "Editar Movimentação" : "Prov. Parcela"}
            onClick={() => onClickProvParcela(rowData)}
            data-testid="editFileTest"
          />
        )}

        {onClickDelete && (
          <Button
            className="table__opcoes--red"
            icon="pi pi-times"
            tooltip="Excluir"
            disabled={disabledDelete}
            onClick={() => {
              onClickDelete(rowData);
            }}
            tooltipOptions={{ position: "left" }}
            data-testid="deleteTest"
          />
        )}
        {onClickSendEmail && (
          <Button
            className="table__opcoes--black"
            icon="pi pi-envelope"
            tooltip="Enviar E-mail de Cobrança"
            onClick={() => onClickSendEmail(rowData)}
            data-testid="sendEmailTest"
            tooltipOptions={{ position: "left" }}
          />
        )}
      </div>
    );
  };

  function validationType() {
    const message = "Voltar para a página de ";
    if (data?.type === "parcela") {
      return message + "Parcelas";
    } else if (data?.type === "emailsEnviados") {
      return message + "E-mails Enviados";
    } else if (data?.type === "contrato") {
      return message + "Contratos";
    } else if (data?.type === "movimentacao") {
      return message + "Movimentações";
    } else {
      return "";
    }
  }

  const header = (
    <>
      <div className="table__header">
        <label className="table__header--title">{headerTitle}</label>
        <div className="table__header--buttons">
          {onClickHeaderInfo && (
            <ButtonComponent
              datatesteid={"headerInfo"}
              onClick={() => onClickHeaderInfo()}
              type={"info"}
              tooltip={headerInfoHoverMessage}
              tooltipOptions={{ position: "left" }}
            />
          )}
          {onClickVoltar && (
            <Button
              className="table__header--return table__header--border"
              label="Voltar"
              icon="pi pi-arrow-left"
              tooltip={validationType()}
              tooltipOptions={{
                className: "tooltip--width",
                position: "bottom"
              }}
              onClick={() => {
                dispatch(
                  setPage({
                    page: page
                  })
                );
                history.goBack();
              }}
              data-testid="voltarTest"
            />
          )}

          {onClickVoltarCustom && (
            <Button
              className="table__header--return table__header--border"
              label="Voltar"
              icon="pi pi-arrow-left"
              tooltip={validationType()}
              tooltipOptions={{
                className: "tooltip--width",
                position: "bottom"
              }}
              onClick={() => {
                dispatch(
                  setPage({
                    page: page
                  })
                );
                history.push(path);
              }}
              data-testid="voltarTest"
            />
          )}
          {onClickFiltrar && (
            <>
              <Button
                className="table__header--filter table__header--border"
                label="Filtrar"
                icon="pi pi-filter"
                onClick={() => onClickFiltrar()}
                data-testid="filtrarTest"
              />
              {onClickClear && visible === true && (
                <>
                  <Button
                    icon="pi pi-filter-slash"
                    onClick={() => onClickClear()}
                    className={
                      headerTitle === "Cobrança Inadimplente" || headerTitle === "E-mails Enviados"
                        ? "table__header--clearFilter-cobInadimplente table__header--border"
                        : "table__header--clearFilter table__header--border"
                    }
                  >
                    Limpar
                  </Button>
                </>
              )}
            </>
          )}
          {onClickAdicionar && (
            <Button
              className="table__header--add table__header--border "
              label="Adicionar"
              icon="pi pi-plus"
              onClick={() => onClickAdicionar()}
              data-testid="adicionarTest"
            />
          )}
        </div>
      </div>
      <Button
        className="table__header--totalRecords"
        tooltip="Total de registros que satisfazem ao filtro"
      >
        {/* {totalRecords ? `(${totalRecords})` : `(${0})`} */}
        {/* {totalRecords && totalRecords + {totalRecords === 1 ? 'registro' : ' registros'}}
         */}
        {totalRecords && `${totalRecords} ${totalRecords === 1 ? "registro" : "registros"}`}
      </Button>
      {onClickClear && visible === true && <br></br>}
      <hr></hr>
    </>
  );

  async function onPage(e) {
    await setLazyParams(e);
  }

  function getFieldValue(rowData, field) {
    const nestedFields = field.split(".");
    let value = rowData;

    for (let nestedField of nestedFields) {
      if (value && typeof value === "object" && nestedField in value) {
        value = value[nestedField];
      } else {
        value = null;
        break;
      }
    }

    return value;
  }

  return (
    <div className="table">
      <DataTable
        value={arrayData}
        header={headerComponent ? headerComponent : header}
        selectionMode={selectionMode}
        selection={selection}
        metaKeySelection={metaKeySelection}
        onSelectionChange={(e) => {
          setId(e.value);
          handleCheck(e.value);
        }}
        dataKey={dataKey}
        scrollable={true}
        responsive
        loading={loading}
        columnResizeMode="expand"
        resizableColumns
        scrollHeight="flex"
        className="table__dataTable"
        emptyMessage="Nenhum registro encontrado."
        rowClassName={rowClassName}
        ///////////////////////////////////
        paginator={headerTitle === "Detalhes E-mails Enviados" ? false : true}
        paginatorPosition="top"
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        lazy
        rows={lazyParams?.rows}
        first={lazyParams?.first}
        onPage={onPage}
        totalRecords={totalRecords}
      >
        {renderColumns}
        <Column
          className="centralizar"
          headerClassName="column-header"
          header="Opções"
          body={opcoesBody}
          style={{ minWidth: 120, maxWidth: details === "email" ? "10vw" : "17vw" }}
        />
      </DataTable>
    </div>
  );
};
