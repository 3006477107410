import axios from "axios";
import { handlePaginationUri } from "../../utils";

const url = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: `${url}`,
});

export const getEmpresaByNomeFantasia = (nomeFantasia) =>
  axios
    .get(`${url}/empresas/nome-fantasia/${nomeFantasia}`)
    .then((response) => response.data);

export const getEmpresaByNomeFantasiaEAtivo = (nomeFantasia) =>
  axios
    .get(`${url}/empresas/nome-fantasia-ativo/${nomeFantasia}`)
    .then((response) => response.data);

export const getEmpresaResumo = () =>
  axios
    .get(`${url}/empresas/resumo`)
    .then((response) => response.data);

export const getEmpresaById = (id) =>
  axios.get(`${url}/empresas/${id}`).then((response) => response.data);

export const getAllFilterEmpresas = (requestParams) =>
  axios
    .post(
      `${url}/empresas/filtro${handlePaginationUri(requestParams)}`,
      requestParams.params
    )
    .then((response) => response.data);

export const deleteEmpresa = (id) =>
  axios.delete(`${url}/empresas/${id}`).then((response) => response.data);

export const saveEmpresa = (empresa) =>
  axios.post(`${url}/empresas`, empresa).then((response) => response.data);

export const getAllEmpresas = () =>
  axios.get(`${url}/empresas`).then((response) => response.data);
