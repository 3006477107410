const INITIAL_STATE = {
  cadastroInicio: null,
  cadastroFim: null,
  conciliacaoInicio: null,
  conciliacaoFim: null,
  contaCorrente: {
    descricao: null
  },
  dataBaixaInicio: null,
  dataBaixaFim: null,
  desconciliadoInicio: null,
  desconciliadoFim: null,
  historico: null,
  idMovimentacao: null,
  tipoBaixa: null,
  // tipoConciliacao: "PENDENTE",
  // tipoLancamento: "TODOS",
  tipoConciliacao: null,
  tipoLancamento: null,
  ultimaAlteracaoInicio: null,
  ultimaAlteracaoFim: null,
  valorLiquido: null,
  vencimentoModificadoInicio: null,
  // vencimentoModificadoFim: new Date(new Date().setDate(new Date().getDate() - 1)).toISOString().slice(0, 10)
  vencimentoModificadoFim: null,
};

export const filtroConci = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "SET_FILTRO_CONCI":
      return { ...action.payload };
    case "SET_ULTIMA_ALTERACAO_INICIO":
      return { ...state, ultimaAlteracaoInicio: action.payload };
    case "SET_ULTIMA_ALTERACAO_FIM":
      return { ...state, ultimaAlteracaoFim: action.payload };
    case "SET_CONCILIACAO_INICIO":
      return { ...state, conciliacaoInicio: action.payload };
    case "SET_CONCOLIACAO_FIM":
      return { ...state, conciliacaoFim: action.payload };
    case "SET_DESCONCILIADO_INICIO":
      return { ...state, desconciliadoInicio: action.payload };
    case "SET_DESCONCOLIADO_FIM":
      return { ...state, desconciliadoFim: action.payload };
    case "SET_CONTA":
      return { ...state, conta: action.payload };
    case "SET_PAGINA":
      return { ...state, pagina: action.payload };
    case "SET_QTD_REGISTRO":
      return { ...state, qtdRegistros: action.payload };
    case "SET_TIPO_LANCAMENTO_CONC":
      return { ...state, tipoLancamento: action.payload };
    case "SET_TIPO_CONCILIACAO":
      return { ...state, tipoConciliacao: action.payload };
    case "SET_HISTORICO":
      return { ...state, historico: action.payload };
    case "SET_CADASTRO_INICIO":
      return { ...state, cadastroInicio: action.payload };
    case "SET_CADASTRO_FIM":
      return { ...state, cadastroFim: action.payload };

    case "SET_SALDO_INICIO":
      return { ...state, saldoInicio: action.payload };
    case "SET_SALDO_FIM":
      return { ...state, saldoFim: action.payload };
    case "SET_NUMERO_UNICO":
      return { ...state, idMovimentacao: action.payload };
    default:
      return state;
  }
};
