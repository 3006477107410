const INITIAL_STATE = {
    reload: false
};

//verificar se a parte de baixo esta faltando algum objeto.
export const updateNatureza = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_RELOAD":
            return { ...state, descricao: action.payload };
        default:
            return state;
    }
};
