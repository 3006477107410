import { addLocale } from "primereact/api";
import { Toast } from "primereact/toast";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFiltroMov } from "../../actions";
import {
  deleteFiltroSalvo,
  getContaCorrenteByIdEmpresa,
  saveUsuarioFiltro,
  getAllEmpresas,
  getListaNaturezaHierarquica,
  getAllParceiros,
  buscarTodos

} from "../../api";
import { paginationDefault } from "../../default/pagination";
import { brazilianCalendarParams, convertToISO8601, convertToLocalOffset } from "../../utils";
import { Filtro } from "../Filtro";
import { ModalComponents } from "../ModalComponents";

export const FiltroMovimentacaoPagarReceber = ({
  filtroVisivelLista,
  setFiltroVisivelLista,
  clear,
  setVisible,
  setLazyParamsProp,
  clearInputs,
  setClearInputs
}) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [parceiro, setParceiro] = useState();
  const [listaParceiro, setListaParceiro] = useState([]);
  const [creditoDebitoConciliado, setCreditoDebitoConciliado] = useState(
    useSelector((state) => state.filtroMov.creditoDebitoConciliado)
  );

  const [rateado, setRateado] = useState(useSelector((state) => state.filtroMov.rateado));

  const [baixado, setBaixado] = useState(null);

  const [contaCorrente, setContaCorrente] = useState(null);
  const [listaContaCorrente, setListaContaCorrente] = useState([]);

  const [historico, setHistorico] = useState(useSelector((state) => state.filtroMov.historico));
  const [vencimentoOriginalInicio, setVencimentoOriginalInicio] = useState(
    useSelector((state) => state.filtroMov.vencimentoOriginalInicio)
  );
  const [vencimentoOriginalFim, setVencimentoOriginalFim] = useState(
    useSelector((state) => state.filtroMov.vencimentoOriginalFim)
  );

  const [vencimentoModificadoInicio, setVencimentoModificadoInicio] = useState(
    useSelector((state) => state.filtroMov.vencimentoModificadoInicio)
  );
  const [vencimentoModificadoFim, setVencimentoModificadoFim] = useState(
    useSelector((state) => state.filtroMov.vencimentoModificadoFim)
  );
  const [baixaInicio, setBaixaInicio] = useState(
    useSelector((state) => state.filtroMov.baixaInicio)
  );
  const [baixaFim, setBaixaFim] = useState(useSelector((state) => state.filtroMov.baixaFim));
  const [emissaoInicio, setEmissaoInicio] = useState(
    useSelector((state) => state.filtroMov.emissaoInicio)
  );
  const [emissaoFim, setEmissaoFim] = useState(useSelector((state) => state.filtroMov.emissaoFim));
  const [cadastroInicio, setCadastroInicio] = useState(
    useSelector((state) => state.filtroMov.cadastroInicio)
  );
  const [cadastroFim, setCadastroFim] = useState(
    useSelector((state) => state.filtroMov.cadastroFim)
  );
  const [ultimaAlteracaoInicio, setUltimaAlteracaoInicio] = useState(
    useSelector((state) => state.filtroMov.ultimaAlteracaoInicio)
  );
  const [ultimaAlteracaoFim, setUltimaAlteracaoFim] = useState(
    useSelector((state) => state.filtroMov.ultimaAlteracaoFim)
  );
  const [conciliacaoInicio, setConciliacaoInicio] = useState(
    useSelector((state) => state.filtroMov.conciliacaoInicio)
  );
  const [conciliacaoFim, setConciliacaoFim] = useState(
    useSelector((state) => state.filtroMov.conciliacaoFim)
  );
  const [faturamentoPrevistoInicio, setFaturamentoPrevistoInicio] = useState(
    useSelector((state) => state.filtroMov.faturamentoPrevistoInicio)
  );
  const [faturamentoPrevistoFim, setFaturamentoPrevistoFim] = useState(
    useSelector((state) => state.filtroMov.faturamentoPrevistoFim)
  );

  const [empresa, setEmpresa] = useState(null);

  const [naturezaDescricao, setNaturezaDescricao] = useState(
    useSelector((state) => state.filtroMov.natureza)
  );


  const [listaEmpresa, setListaEmpresa] = useState([]);

  const [listaNatureza, setListaNatureza] = useState([]);


  const [vencidoSemBaixa, setVencidoSemBaixa] = useState(
    useSelector((state) => state.filtroMov.vencidoSemBaixa)
  );
  const [competenciaInicio, setCompetenciaInicio] = useState(
    useSelector((state) => state.filtroMov.competenciaInicio)
  );
  const [competenciaFim, setCompetenciaFim] = useState(
    useSelector((state) => state.filtroMov.competenciaFim)
  );
  const [idMov, setIdMov] = useState(useSelector((state) => state.filtroMov.idMovimentacao));
  const [numeroUnicoLancamento, setNumeroUnicoLancamento] = useState(
    useSelector((state) => state.filtroMov.numeroUnicoLancamento)
  );
  const [tipoLancamento, setTipoLancamento] = useState(
    useSelector((state) => state.filtroMov.tipoLancamento)
  );

  const [errorNomeFiltro, setErrorNomeFiltro] = useState(false);


  const [saveFilterDialog, setSavedFilterDialog] = useState(false);
  const [nomeFiltro, setNomeFiltro] = useState();
  const [filtro, setFiltro] = useState();
  const [listaFiltro, setListaFiltro] = useState([]);

  addLocale("pt-br", brazilianCalendarParams);

  const selectTipoLancamento = [
    { name: "Ambos", value: null },
    { name: "Receita", value: "RECEITA" },
    { name: "Despesa", value: "DESPESA" }
  ];

  const selectCreditoDebido = [
    { name: "Ambos", value: null },
    { name: "Sim", value: "SIM" },
    { name: "Não", value: "NAO" }
  ];

  const selectBaixados = [
    { name: "Ambos", value: "TODOS" },
    { name: "Sim", value: "SIM" },
    { name: "Não", value: null }
  ];

  const selectRateado = [
    { name: "Ambos", value: null },
    { name: "Sim", value: "SIM" },
    { name: "Não", value: "NAO" }
  ];

  const bodySalvarFiltro = {
    baixaFim: baixaFim,
    baixaInicio: baixaInicio,
    baixado,
    cadastroFim: cadastroFim,
    cadastroInicio: cadastroInicio,
    competenciaFim: competenciaFim,
    competenciaInicio: competenciaInicio,
    conciliacaoFim: conciliacaoFim,
    conciliacaoInicio: conciliacaoInicio,
    ...(contaCorrente ? { contaCorrente: { id: contaCorrente } } : {}),
    creditoDebitoConciliado:
      creditoDebitoConciliado === null ? "TODOS" : creditoDebitoConciliado,
    dataCadastroFiltro: null,
    emissaoFim: emissaoFim,
    emissaoInicio: emissaoInicio,
    ...(empresa ? { empresa: empresa } : {}),

    faturamentoPrevistoFim: faturamentoPrevistoFim,
    faturamentoPrevistoInicio: faturamentoPrevistoInicio,
    historico: historico !== "" ? historico : null,
    nomeFiltro,

    numeroUnicoLancamento:
      numeroUnicoLancamento !== "" ? numeroUnicoLancamento : null,
    ...(parceiro ? { parceiro: parceiro } : {}),

    idMovimentacao: idMov,
    rateado: rateado === null ? "TODOS" : rateado,
    tipoLancamento: tipoLancamento === null ? "TODOS" : tipoLancamento,
    ultimaAlteracaoFim: ultimaAlteracaoFim,
    ultimaAlteracaoInicio: ultimaAlteracaoInicio,
    vencidoSemBaixa,
    vencimentoModificadoFim: vencimentoModificadoFim,
    vencimentoModificadoInicio: vencimentoModificadoInicio,
    vencimentoOriginalFim: vencimentoOriginalFim,
    vencimentoOriginalInicio: vencimentoOriginalInicio,
    ...(naturezaDescricao ? { natureza: naturezaDescricao } : {})
  };

  const filtrar = () => {
    setLazyParamsProp(() => paginationDefault);
    dispatch(
      setFiltroMov({
        vencimentoOriginalInicio: convertToISO8601(vencimentoOriginalInicio),
        vencimentoOriginalFim: !vencimentoOriginalFim
          ? null
          : convertToISO8601(vencimentoOriginalFim),
        vencimentoModificadoInicio: convertToISO8601(vencimentoModificadoInicio),
        vencimentoModificadoFim: convertToISO8601(vencimentoModificadoFim),
        baixaFim: convertToISO8601(baixaFim),
        baixaInicio: convertToISO8601(baixaInicio),
        emissaoInicio: convertToISO8601(emissaoInicio),
        emissaoFim: convertToISO8601(emissaoFim),
        cadastroInicio: convertToLocalOffset(cadastroInicio),
        cadastroFim: convertToLocalOffset(cadastroFim),
        ultimaAlteracaoInicio: convertToLocalOffset(ultimaAlteracaoInicio),
        ultimaAlteracaoFim: convertToLocalOffset(ultimaAlteracaoFim),
        conciliacaoInicio: convertToISO8601(conciliacaoInicio),
        conciliacaoFim: convertToISO8601(conciliacaoFim),
        numeroUnicoLancamento: numeroUnicoLancamento !== "" ? numeroUnicoLancamento : null,
        faturamentoPrevistoInicio: convertToISO8601(faturamentoPrevistoInicio),
        faturamentoPrevistoFim: convertToISO8601(faturamentoPrevistoFim),
        competenciaInicio: convertToISO8601(competenciaInicio),
        competenciaFim: convertToISO8601(competenciaFim),
        idMovimentacao: idMov !== "" ? idMov : null,
        natureza: naturezaDescricao,
        vencidoSemBaixa,
        empresa: empresa,
        parceiro: parceiro,
        creditoDebitoConciliado: creditoDebitoConciliado,
        naturezaAnalitica: null,
        baixado: baixado,
        rateado: rateado,
        contaCorrente: {
          descricao: contaCorrente?.descricao ? contaCorrente?.descricao : contaCorrente
        },
        historico: historico !== "" ? historico : null,
        tipoLancamento: tipoLancamento
      })
    );
  };

  async function salvarFiltro(e) {
    if (bodySalvarFiltro?.nomeFiltro?.length < 3 || bodySalvarFiltro.nomeFiltro === undefined) {
      return setErrorNomeFiltro(true);
    }
    try {
      await saveUsuarioFiltro(bodySalvarFiltro);
      toast.current.show({
        severity: "success",
        summary: "Sucesso",
        detail: "Filtro salvo com sucesso!",
        life: 5000,
      });

      setSavedFilterDialog(false);
      setErrorNomeFiltro(false);
    } catch (error) {
      const mensagem = error?.response?.data?.detail || "Ocorreu um erro ao salvar o filtro.";
      toast.current.show({
        severity: "error",
        summary: "Erro",
        detail: `${mensagem}`,
        life: 5000
      });
    } finally {
      setNomeFiltro("");
    }
  }


  const limpar = useCallback(() => {
    setParceiro(null);
    setCreditoDebitoConciliado(null);
    setBaixado("TODOS");
    setRateado(null);
    setContaCorrente(null);
    setHistorico("");
    setVencimentoOriginalInicio(null);
    setVencimentoOriginalFim("");
    setVencimentoModificadoInicio(null);
    setVencimentoModificadoFim(null);
    setBaixaInicio(null);
    setBaixaFim(null);
    setEmissaoInicio(null);
    setEmissaoFim(null);
    setCadastroInicio(null);
    setCadastroFim(null);
    setUltimaAlteracaoInicio(null);
    setNaturezaDescricao(null);
    setUltimaAlteracaoFim(null);
    setConciliacaoInicio(null);
    setConciliacaoFim(null);
    setFaturamentoPrevistoInicio(null);
    setFaturamentoPrevistoFim(null);
    setCompetenciaInicio(null);
    setCompetenciaFim(null);
    setContaCorrente(null);
    setEmpresa(null);
    setVencidoSemBaixa(null);
    setIdMov("");
    setFiltro(null);
    setNumeroUnicoLancamento("");
    setTipoLancamento(null);
    setVisible(() => false)
    setClearInputs(false)
  }, [setClearInputs, setVisible]);

  useEffect(() => {
    if (clear === true) {
      limpar();
    }
  }, [clear, limpar])

  useEffect(() => {
    if (clearInputs === true) {
      limpar();
    }
  }, [clearInputs, limpar]);


  async function handleFillFilter(savedFilterId) {
    setFiltro(savedFilterId);

    const idEmpresa = savedFilterId?.empresa?.id;
    const idNatureza = savedFilterId?.natureza?.id;


    const handleDate = (dateString) => {
      if (!dateString) {
        return null;
      }
      return new Date(dateString);
    };

    setEmpresa(idEmpresa);
    setParceiro(savedFilterId?.parceiro?.id);
    setNaturezaDescricao(idNatureza)
    setContaCorrente(savedFilterId?.contaCorrente?.id);
    setIdMov(savedFilterId?.idMovimentacao);
    setTipoLancamento(savedFilterId?.tipoLancamento);
    setCreditoDebitoConciliado(savedFilterId?.creditoDebitoConciliado);
    setBaixado(savedFilterId?.baixado);
    setRateado(savedFilterId?.rateado);
    setHistorico(savedFilterId?.historico);
    setVencimentoOriginalInicio(handleDate(savedFilterId?.vencimentoOriginalInicio));
    setVencimentoOriginalFim(handleDate(savedFilterId?.vencimentoOriginalFim));
    setVencimentoModificadoInicio(handleDate(savedFilterId?.vencimentoModificadoInicio));
    setVencimentoModificadoFim(handleDate(savedFilterId?.vencimentoModificadoFim));
    setBaixaInicio(handleDate(savedFilterId?.baixaInicio));
    setBaixaFim(handleDate(savedFilterId?.baixaFim));
    setEmissaoInicio(handleDate(savedFilterId?.emissaoInicio));
    setEmissaoFim(handleDate(savedFilterId?.emissaoFim));
    setCadastroInicio(handleDate(savedFilterId?.cadastroInicio));
    setCadastroFim(handleDate(savedFilterId?.cadastroFim));
    setUltimaAlteracaoInicio(handleDate(savedFilterId?.ultimaAlteracaoInicio));
    setUltimaAlteracaoFim(handleDate(savedFilterId?.ultimaAlteracaoFim));
    setConciliacaoInicio(handleDate(savedFilterId?.conciliacaoInicio));
    setConciliacaoFim(handleDate(savedFilterId?.conciliacaoFim));
    setFaturamentoPrevistoInicio(handleDate(savedFilterId?.faturamentoPrevistoInicio));
    setFaturamentoPrevistoFim(handleDate(savedFilterId?.faturamentoPrevistoFim));
    setCompetenciaInicio(handleDate(savedFilterId?.competenciaInicio));
    setCompetenciaFim(handleDate(savedFilterId?.competenciaFim));
    setVencidoSemBaixa(savedFilterId?.vencidoSemBaixa);
    setNumeroUnicoLancamento(savedFilterId?.numeroUnicoLancamento);

  };

  const [dialogExcluir, setDialogExcluir] = useState(false);

  const getListWith = useCallback(({
    endpoint,
    searchBy,
    setState,
    notFoundMessage,
    errorMessageFrom,
  }) => {
    endpoint(searchBy)
      .then((response) => {
        setState(response);
        if (response.length === 0) {
          searchNotFound(notFoundMessage);
        }
      })
      .catch(() => searchHandlerError(errorMessageFrom));
  }, [])


  const [loading, setLoading] = useState(false);

  const fetchFiltros = async () => {
    setLoading(true);
    try {
      const response = await buscarTodos();
      setListaFiltro(response);
    } catch (error) {
      console.error("Erro ao buscar filtros:", error);
    } finally {
      setLoading(false);
    }
  };



  useEffect(() => {
    const fetchEmpresas = async () => {
      const response = await getAllEmpresas();
      setListaEmpresa(response.content);
    };

    fetchEmpresas();
  }, []);

  useEffect(() => {
    const fetchNaturezaHierarquica = async () => {
      const response = await getListaNaturezaHierarquica();
      setListaNatureza(response);

    };

    fetchNaturezaHierarquica();
  }, []);

  useEffect(() => {
    const fetchListaParceiro = async () => {
      const response = await getAllParceiros();
      setListaParceiro(response);

    };

    fetchListaParceiro();
  }, []);

  const getListaContaCorrente = useCallback(() => {
    getListWith({
      endpoint: getContaCorrenteByIdEmpresa,
      searchBy: empresa,
      setState: setListaContaCorrente,
      notFoundMessage: "Conta corrente não encontrada",
      errorMessageFrom: "conta corrente"
    });

  }, [empresa, getListWith])

  useEffect(() => {
    if (empresa) {
      getListaContaCorrente();
    }
  }, [empresa, getListaContaCorrente]);

  useEffect(() => {
    if (!empresa && contaCorrente?.id) {
      setContaCorrente('')
    }
  }, [empresa, contaCorrente]);

  function searchNotFound(detail) {
    toast.current.show({
      severity: "info",
      summary: "Info",
      detail: detail
    });
  }

  function searchHandlerError(name) {
    toast.current.show({
      severity: "error",
      summary: `Erro ao buscar por ${name}`,
      detail: `Houve um problema inesperado e a busca falhou, tente novamente mais tarde`
    });
  }

  const modalExcluir = [
    { label: "Código:", value: filtro?.id },
    { label: "Nome Filtro:", value: filtro?.nomeFiltro }
  ];

  const filterFields = [
    {
      label: "Filtros Salvos:",
      value: filtro,
      setState: handleFillFilter,
      type: "dropdown",
      options: listaFiltro,
      optionLabel: "nomeFiltro",
      filter: true,
      filterBy: "nomeFiltro",
      datatesteid: "filtrosSalvosID",
      deleteDisable: !filtro?.id,
      onShow: fetchFiltros,
      loading: loading

    },
    {
      name: "numeroUnicoLancamento",
      label: "Número Único Lançamento",
      value: numeroUnicoLancamento,
      setState: setNumeroUnicoLancamento,
      dataType: "pint",
      datatesteid: "numeroUnicoLancamentoId",
      maxLength: 50
    },
    {
      label: "Intervalo de Datas",
      type: "setOfDates",
      datatestidfield: "intervaloDatasIdTwo",
      datatesteid: "intervaloDatasID",
      dates: [
        {
          label: "Vencimento Original:",
          type: "date",
          rangeOfDate: true,
          value: vencimentoOriginalInicio,
          datatesteid: "vencimentoID",
          datatesteidrange: "vencimentoRangeID",
          setState: setVencimentoOriginalInicio,
          endValue: vencimentoOriginalFim,
          setEndState: setVencimentoOriginalFim
        },
        {
          label: "Vencimento Modificado:",
          type: "date",
          rangeOfDate: true,
          value: vencimentoModificadoInicio,
          setState: setVencimentoModificadoInicio,
          endValue: vencimentoModificadoFim,
          setEndState: setVencimentoModificadoFim,
          datatesteid: "modificadaID",
          datatesteidrange: "modificadaRangeID"
        },
        {
          label: "Baixa:",
          type: "date",
          rangeOfDate: true,
          value: baixaInicio,
          setState: setBaixaInicio,
          endValue: baixaFim,
          setEndState: setBaixaFim,
          datatesteid: "baixaID",
          datatesteidrange: "baixaRangeID"
        },
        {
          label: "Emissão:",
          type: "date",
          rangeOfDate: true,
          value: emissaoInicio,
          setState: setEmissaoInicio,
          endValue: emissaoFim,
          setEndState: setEmissaoFim,
          datatesteid: "emissaoID",
          datatesteidrange: "emissaoRangeID"
        },
        {
          label: "Cadastro:",
          type: "date",
          rangeOfDate: true,
          value: cadastroInicio,
          setState: setCadastroInicio,
          endValue: cadastroFim,
          setEndState: setCadastroFim,
          datatesteid: "cadastroID",
          datatesteidrange: "cadastroRangeID"
        },
        {
          label: "Ult. Alteração:",
          type: "date",
          rangeOfDate: true,
          value: ultimaAlteracaoInicio,
          setState: setUltimaAlteracaoInicio,
          endValue: ultimaAlteracaoFim,
          setEndState: setUltimaAlteracaoFim,
          datatesteid: "ultimaAlteracaoID",
          datatesteidrange: "ultimaAlteracaoRangeID"
        },
        {
          label: "Conciliação:",
          type: "date",
          rangeOfDate: true,
          value: conciliacaoInicio,
          setState: setConciliacaoInicio,
          endValue: conciliacaoFim,
          setEndState: setConciliacaoFim,
          datatesteid: "conciliacaoID",
          datatesteidrange: "conciliacaoRangeID"
        },
        {
          label: "Competência:",
          type: "date",
          rangeOfDate: true,
          value: competenciaInicio,
          setState: setCompetenciaInicio,
          endValue: competenciaFim,
          setEndState: setCompetenciaFim,
          datatesteid: "competenciaID",
          datatesteidrange: "competenciaRangeID"
        },
        {
          label: "Faturamento Previsto:",
          type: "date",
          rangeOfDate: true,
          value: faturamentoPrevistoInicio,
          setState: setFaturamentoPrevistoInicio,
          endValue: faturamentoPrevistoFim,
          setEndState: setFaturamentoPrevistoFim,
          datatesteid: "faturamentePrevistoID",
          datatesteidrange: "faturamentoPrevistoRangeID"
        }
      ]
    },

    {
      name: "vencidosSemBaixa",
      label: "Vencidos Sem Baixa:",
      value: vencidoSemBaixa,
      setState: setVencidoSemBaixa,
      type: "checkbox",
      datatesteid: "vencidosSemBaixaID"
    },
    {
      label: "Empresa:",
      value: empresa,
      setState: setEmpresa,
      type: "dropdown",
      options: listaEmpresa,
      optionLabel: "nomeFantasia",
      optionValue: "id",
      filter: true,
      filterBy: "nomeFantasia",
      datatesteid: "nomeFantasia"

    },
    {
      label: "Natureza:",
      value: naturezaDescricao,
      setState: setNaturezaDescricao,
      type: "dropdown",
      options: listaNatureza,
      optionLabel: "naturezaHierarquica",
      optionValue: "idNatureza",
      filter: true,
      filterBy: "naturezaHierarquica",
      datatesteid: "naturezaHierarquica"

    },
    {
      name: "id",
      label: "Código:",
      value: idMov,
      setState: setIdMov,
      dataType: "pint",
      datatesteid: "idID"
    },
    {
      label: "Parceiro:",
      value: parceiro,
      setState: setParceiro,
      type: "dropdown",
      options: listaParceiro,
      optionLabel: "nomeTratamento",
      optionValue: "id",
      filter: true,
      filterBy: "nomeTratamento",
      datatesteid: "nomeTratamento"

    },
    {
      label: "Conta Corrente:",
      value: contaCorrente,
      setState: setContaCorrente,
      type: "dropdown",
      disabled: !empresa,
      options: listaContaCorrente,
      optionLabel: "descricao",
      optionValue: "id",
      filter: true,
      filterBy: "descricao",
      datatesteid: "contaCorrenteID"
    },
    {
      name: "tipoLancamento",
      label: "Tipo Lançamento:",
      value: tipoLancamento,
      setState: setTipoLancamento,
      type: "selectButton",
      options: selectTipoLancamento,
      optionLabel: "name",
      datatesteid: "tipoLancamentoID"
    },
    {
      name: "creditoDebito",
      label: "Conciliado:",
      value: creditoDebitoConciliado,
      setState: setCreditoDebitoConciliado,
      type: "selectButton",
      options: selectCreditoDebido,
      optionLabel: "name",
      datatesteid: "creditoDebitoID"
    },
    {
      name: "baixado",
      label: "Baixado:",
      value: baixado,
      setState: setBaixado,
      type: "selectButton",
      options: selectBaixados,
      optionLabel: "name",
      datatesteid: "baixadoID"
    },
    {
      name: "rateado",
      label: "Rateado:",
      value: rateado,
      setState: setRateado,
      type: "selectButton",
      options: selectRateado,
      optionLabel: "name",
      datatesteid: "rateadoID"
    },
    {
      name: "historico",
      label: "Histórico:",
      value: historico,
      setState: setHistorico,
      datatesteid: "historicoID",
      maxLength: 100
    }
  ];

  const deleteFiltroSalvoFunction = async (id) => {
    try {
      await deleteFiltroSalvo(id);
      window.location.reload();
      setDialogExcluir(false);
    } catch (error) {
      toast.current.show({
        severity: "error",
        summary: "Info",
        life: 8000,
        detail: "Não foi possível deletar"
      });
    }
  };

  return (
    <div>
      <Toast ref={toast} />
      <Filtro
        filterFields={filterFields}
        setIsFiltroVisible={setFiltroVisivelLista}
        isFiltroVisible={filtroVisivelLista}
        clearFilter={limpar}
        handleConfirmFilter={filtrar}
        setSavedFilterDialog={setSavedFilterDialog}
        setNomeFiltro={setNomeFiltro}
        salvarFiltro={salvarFiltro}
        saveFilterDialog={saveFilterDialog}
        errorNomeFiltro={errorNomeFiltro}
        setSaveFilterDialog={setSavedFilterDialog}
      />
      <ModalComponents
        title="Filtro Salvo"
        visible={dialogExcluir}
        onHide={() => setDialogExcluir(false)}
        descricao={modalExcluir}
        onClickConfirmar={() => deleteFiltroSalvoFunction(filtro?.id)}
        onClickCancelar={() => setDialogExcluir(false)}
        onClick="delete"
      />
    </div>
  );
};
